import { Analytics } from '../../services/analytics'

export const trackConnectError = () => {
  setTimeout(() => {
    const pageName = 'Error page'
    Analytics.setTrackingData({
      [Analytics.keys.pageName()]: pageName,
      [Analytics.keys.viewChange()]: pageName,
      [Analytics.keys.eventType(0)]: 'pageView',
      [Analytics.keys.eventAction(0)]: 'Success',
      [Analytics.keys.errorCode()]: 'Error',
      [Analytics.keys.errorMessage()]: 'Technical Error',
      [Analytics.keys.errorCausingURL()]: window.location.href,
    })
    Analytics.trackPage()
  }, 1000)
}
