export const SET_TLS_VALID = 'custom//SET_TLS_VALID'
export const SET_ACTUAL_PAGE = 'custom//SET_ACTUAL_PAGE'
export const SET_ERROR_DATA = 'custom//SET_ERROR_DATA'
export const SET_DISCOUNT = 'custom//SET_DISCOUNT'
export const SET_IS_LOGGED = 'custom//SET_IS_LOGGED'
export const SET_CONTINUE_JORUNEY = 'custom//SET_CONTINUE_JOURNEY'

interface SetCustomAction {
  type: typeof SET_TLS_VALID
  payload: any
}

interface SetIsLogged {
  type: typeof SET_IS_LOGGED
  payload: any
}

interface SetActualPageAction {
  type: typeof SET_ACTUAL_PAGE
  payload: any
}

interface SetErrorDataAction {
  type: typeof SET_ERROR_DATA
  payload: any
}

interface SetDiscountAction {
  type: typeof SET_DISCOUNT
  payload: any
}

interface SetContinueJourney {
  type: typeof SET_CONTINUE_JORUNEY
  payload: any
}

export type CustomActions =
  | SetCustomAction
  | SetActualPageAction
  | SetErrorDataAction
  | SetDiscountAction
  | SetIsLogged
  | SetContinueJourney
