export const phoneCountryCodes = [
  {
    value: '+49',
    label: 'Deutschland (+49)',
  },
  {
    value: '+43',
    label: 'Österreich (+43)',
  },
  {
    value: '+41',
    label: 'Schweiz (+41)',
  },
  {
    value: '+93',
    label: 'Afghanistan (+93)',
  },
  {
    value: '+20',
    label: 'Ägypten (+20)',
  },
  {
    value: '+35818',
    label: 'Åland (+35818)',
  },
  {
    value: '+355',
    label: 'Albanien (+355)',
  },
  {
    value: '+213',
    label: 'Algerien (+213)',
  },
  {
    value: '+1684',
    label: 'Amerikanisch-Samoa (+1684)',
  },
  {
    value: '+1340',
    label: 'Amerikanische Jungferninseln (+1340)',
  },
  {
    value: '+376',
    label: 'Andorra (+376)',
  },
  {
    value: '+244',
    label: 'Angola (+244)',
  },
  {
    value: '+1264',
    label: 'Anguilla (+1264)',
  },
  {
    value: '+672',
    label: 'Antarktika (+672)',
  },
  {
    value: '+1268',
    label: 'Antigua und Barbuda (+1268)',
  },
  {
    value: '+240',
    label: 'Äquatorialguinea (+240)',
  },
  {
    value: '+54',
    label: 'Argentinien (+54)',
  },
  {
    value: '+374',
    label: 'Armenien (+374)',
  },
  {
    value: '+297',
    label: 'Aruba (+297)',
  },
  {
    value: '+994',
    label: 'Aserbaidschan (+994)',
  },
  {
    value: '+251',
    label: 'Äthiopien (+251)',
  },
  {
    value: '+61',
    label: 'Australien (+61)',
  },
  {
    value: '+1',
    label: 'Bahamas (+1)',
  },
  {
    value: '+973',
    label: 'Bahrain (+973)',
  },
  {
    value: '+880',
    label: 'Bangladesch (+880)',
  },
  {
    value: '+1',
    label: 'Barbados (+1)',
  },
  {
    value: '+375',
    label: 'Belarus (+375)',
  },
  {
    value: '+32',
    label: 'Belgien (+32)',
  },
  {
    value: '+501',
    label: 'Belize (+501)',
  },
  {
    value: '+229',
    label: 'Benin (+229)',
  },
  {
    value: '+1',
    label: 'Bermuda (+1)',
  },
  {
    value: '+975',
    label: 'Bhutan (+975)',
  },
  {
    value: '+591',
    label: 'Bolivien (+591)',
  },
  {
    value: '+5993',
    label: 'Bonaire, Sint Eustatius und Saba (+5993)',
  },
  {
    value: '+387',
    label: 'Bosnien und Herzegowina (+387)',
  },
  {
    value: '+267',
    label: 'Botswana (+267)',
  },
  // NOTE: Commented out, since there was no any code for this region provided and it was causing unexpected bugs
  // {
  //   value: '+',
  //   label: 'Bouvetinsel (+)',
  // },
  {
    value: '+55',
    label: 'Brasilien (+55)',
  },
  {
    value: '+1284',
    label: 'Britische Jungferninseln (+1284)',
  },
  {
    value: '+246',
    label: 'Britisches Territorium im Indischen Ozean (+246)',
  },
  {
    value: '+673',
    label: 'Brunei Darussalam (+673)',
  },
  {
    value: '+359',
    label: 'Bulgarien (+359)',
  },
  {
    value: '+226',
    label: 'Burkina Faso (+226)',
  },
  {
    value: '+257',
    label: 'Burundi (+257)',
  },
  {
    value: '+56',
    label: 'Chile (+56)',
  },
  {
    value: '+86',
    label: 'China, Volksrepublik (+86)',
  },
  {
    value: '+682',
    label: 'Cookinseln (+682)',
  },
  {
    value: '+506',
    label: 'Costa Rica (+506)',
  },
  {
    value: '+225',
    label: 'Côte d’Ivoire (Elfenbeinküste) (+225)',
  },
  {
    value: '+599',
    label: 'Curaçao (+599)',
  },
  {
    value: '+45',
    label: 'Dänemark (+45)',
  },
  {
    value: '+1',
    label: 'Dominica (+1)',
  },
  {
    value: '+1',
    label: 'Dominikanische Republik (+1)',
  },
  {
    value: '+253',
    label: 'Dschibuti (+253)',
  },
  {
    value: '+593',
    label: 'Ecuador (+593)',
  },
  {
    value: '+503',
    label: 'El Salvador (+503)',
  },
  {
    value: '+291',
    label: 'Eritrea (+291)',
  },
  {
    value: '+372',
    label: 'Estland (+372)',
  },
  {
    value: '+500',
    label: 'Falklandinseln (+500)',
  },
  {
    value: '+298',
    label: 'Färöer (+298)',
  },
  {
    value: '+679',
    label: 'Fidschi (+679)',
  },
  {
    value: '+33',
    label: 'Frankreich (+33)',
  },
  {
    value: '+594',
    label: 'Französisch-Guayana (+594)',
  },
  {
    value: '+689',
    label: 'Französisch-Polynesien (+689)',
  },
  {
    value: '+262',
    label: 'Französische Süd- und Antarktisgebiete (+262)',
  },
  {
    value: '+241',
    label: 'Gabun (+241)',
  },
  {
    value: '+220',
    label: 'Gambia (+220)',
  },
  {
    value: '+995',
    label: 'Georgien (+995)',
  },
  {
    value: '+233',
    label: 'Ghana (+233)',
  },
  {
    value: '+350',
    label: 'Gibraltar (+350)',
  },
  {
    value: '+1473',
    label: 'Grenada (+1473)',
  },
  {
    value: '+30',
    label: 'Griechenland (+30)',
  },
  {
    value: '+299',
    label: 'Grönland (+299)',
  },
  {
    value: '+590',
    label: 'Guadeloupe (+590)',
  },
  {
    value: '+1671',
    label: 'Guam (+1671)',
  },
  {
    value: '+502',
    label: 'Guatemala (+502)',
  },
  {
    value: '+441481',
    label: 'Guernsey (Kanalinsel) (+441481)',
  },
  {
    value: '+224',
    label: 'Guinea (+224)',
  },
  {
    value: '+245',
    label: 'Guinea-Bissau (+245)',
  },
  {
    value: '+592',
    label: 'Guyana (+592)',
  },
  {
    value: '+509',
    label: 'Haiti (+509)',
  },
  {
    value: '+672',
    label: 'Heard und McDonaldinseln (+672)',
  },
  {
    value: '+504',
    label: 'Honduras (+504)',
  },
  {
    value: '+852',
    label: 'Hongkong (+852)',
  },
  {
    value: '+91',
    label: 'Indien (+91)',
  },
  {
    value: '+62',
    label: 'Indonesien (+62)',
  },
  {
    value: '+44',
    label: 'Insel Man (+44)',
  },
  {
    value: '+964',
    label: 'Irak (+964)',
  },
  {
    value: '+98',
    label: 'Iran, Islamische Republik (+98)',
  },
  {
    value: '+353',
    label: 'Irland (+353)',
  },
  {
    value: '+354',
    label: 'Island (+354)',
  },
  {
    value: '+972',
    label: 'Israel (+972)',
  },
  {
    value: '+39',
    label: 'Italien (+39)',
  },
  {
    value: '+1',
    label: 'Jamaika (+1)',
  },
  {
    value: '+81',
    label: 'Japan (+81)',
  },
  {
    value: '+967',
    label: 'Jemen (+967)',
  },
  {
    value: '+441534',
    label: 'Jersey (Kanalinsel) (+441534)',
  },
  {
    value: '+962',
    label: 'Jordanien (+962)',
  },
  {
    value: '+344',
    label: 'Kaimaninseln (+344)',
  },
  {
    value: '+855',
    label: 'Kambodscha (+855)',
  },
  {
    value: '+237',
    label: 'Kamerun (+237)',
  },
  {
    value: '+1',
    label: 'Kanada (+1)',
  },
  {
    value: '+238',
    label: 'Kap Verde (+238)',
  },
  {
    value: '+7',
    label: 'Kasachstan (+7)',
  },
  {
    value: '+974',
    label: 'Katar (+974)',
  },
  {
    value: '+254',
    label: 'Kenia (+254)',
  },
  {
    value: '+996',
    label: 'Kirgisistan (+996)',
  },
  {
    value: '+686',
    label: 'Kiribati (+686)',
  },
  {
    value: '+61',
    label: 'Kokosinseln (+61)',
  },
  {
    value: '+57',
    label: 'Kolumbien (+57)',
  },
  {
    value: '+269',
    label: 'Komoren (+269)',
  },
  {
    value: '+243',
    label: 'Kongo, Demokratische Republik (+243)',
  },
  {
    value: '+242',
    label: 'Kongo, Republik (+242)',
  },
  {
    value: '+850',
    label: 'Korea, Demokratische Volksrepublik (Nordkorea) (+850)',
  },
  {
    value: '+82',
    label: 'Korea, Republik (Südkorea) (+82)',
  },
  {
    value: '+381',
    label: 'Kosovo (+381)',
  },
  {
    value: '+385',
    label: 'Kroatien (+385)',
  },
  {
    value: '+53',
    label: 'Kuba (+53)',
  },
  {
    value: '+965',
    label: 'Kuwait (+965)',
  },
  {
    value: '+856',
    label: 'Laos, Demokratische Volksrepublik (+856)',
  },
  {
    value: '+266',
    label: 'Lesotho (+266)',
  },
  {
    value: '+371',
    label: 'Lettland (+371)',
  },
  {
    value: '+961',
    label: 'Libanon (+961)',
  },
  {
    value: '+231',
    label: 'Liberia (+231)',
  },
  {
    value: '+218',
    label: 'Libyen (+218)',
  },
  {
    value: '+423',
    label: 'Liechtenstein (+423)',
  },
  {
    value: '+370',
    label: 'Litauen (+370)',
  },
  {
    value: '+352',
    label: 'Luxemburg (+352)',
  },
  {
    value: '+853',
    label: 'Macau (+853)',
  },
  {
    value: '+261',
    label: 'Madagaskar (+261)',
  },
  {
    value: '+265',
    label: 'Malawi (+265)',
  },
  {
    value: '+60',
    label: 'Malaysia (+60)',
  },
  {
    value: '+960',
    label: 'Malediven (+960)',
  },
  {
    value: '+223',
    label: 'Mali (+223)',
  },
  {
    value: '+356',
    label: 'Malta (+356)',
  },
  {
    value: '+212',
    label: 'Marokko (+212)',
  },
  {
    value: '+692',
    label: 'Marshallinseln (+692)',
  },
  {
    value: '+596',
    label: 'Martinique (+596)',
  },
  {
    value: '+222',
    label: 'Mauretanien (+222)',
  },
  {
    value: '+230',
    label: 'Mauritius (+230)',
  },
  {
    value: '+262',
    label: 'Mayotte (+262)',
  },
  {
    value: '+389',
    label: 'Mazedonien (+389)',
  },
  {
    value: '+52',
    label: 'Mexiko (+52)',
  },
  {
    value: '+691',
    label: 'Mikronesien (+691)',
  },
  {
    value: '+373',
    label: 'Moldawien (+373)',
  },
  {
    value: '+377',
    label: 'Monaco (+377)',
  },
  {
    value: '+976',
    label: 'Mongolei (+976)',
  },
  {
    value: '+382',
    label: 'Montenegro (+382)',
  },
  {
    value: '+1664',
    label: 'Montserrat (+1664)',
  },
  {
    value: '+258',
    label: 'Mosambik (+258)',
  },
  {
    value: '+95',
    label: 'Myanmar (Burma) (+95)',
  },
  {
    value: '+264',
    label: 'Namibia (+264)',
  },
  {
    value: '+674',
    label: 'Nauru (+674)',
  },
  {
    value: '+977',
    label: 'Nepal (+977)',
  },
  {
    value: '+687',
    label: 'Neukaledonien (+687)',
  },
  {
    value: '+64',
    label: 'Neuseeland (+64)',
  },
  {
    value: '+505',
    label: 'Nicaragua (+505)',
  },
  {
    value: '+31',
    label: 'Niederlande (+31)',
  },
  {
    value: '+227',
    label: 'Niger (+227)',
  },
  {
    value: '+234',
    label: 'Nigeria (+234)',
  },
  {
    value: '+683',
    label: 'Niue (+683)',
  },
  {
    value: '+1670',
    label: 'Nördliche Marianen (+1670)',
  },
  {
    value: '+672',
    label: 'Norfolkinsel (+672)',
  },
  {
    value: '+47',
    label: 'Norwegen (+47)',
  },
  {
    value: '+968',
    label: 'Oman (+968)',
  },
  {
    value: '+92',
    label: 'Pakistan (+92)',
  },
  {
    value: '+970',
    label: 'Staat Palästina (+970)',
  },
  {
    value: '+680',
    label: 'Palau (+680)',
  },
  {
    value: '+507',
    label: 'Panama (+507)',
  },
  {
    value: '+675',
    label: 'Papua-Neuguinea (+675)',
  },
  {
    value: '+595',
    label: 'Paraguay (+595)',
  },
  {
    value: '+51',
    label: 'Peru (+51)',
  },
  {
    value: '+63',
    label: 'Philippinen (+63)',
  },
  {
    value: '+870',
    label: 'Pitcairninseln (+870)',
  },
  {
    value: '+48',
    label: 'Polen (+48)',
  },
  {
    value: '+351',
    label: 'Portugal (+351)',
  },
  {
    value: '+1',
    label: 'Puerto Rico (+1)',
  },
  {
    value: '+262',
    label: 'Réunion (+262)',
  },
  {
    value: '+250',
    label: 'Ruanda (+250)',
  },
  {
    value: '+40',
    label: 'Rumänien (+40)',
  },
  {
    value: '+7',
    label: 'Russische Föderation (+7)',
  },
  {
    value: '+677',
    label: 'Salomonen (+677)',
  },
  {
    value: '+590',
    label: 'Saint-Barthélemy (+590)',
  },
  {
    value: '+1599',
    label: 'Saint-Martin (franz. Teil) (+1599)',
  },
  {
    value: '+260',
    label: 'Sambia (+260)',
  },
  {
    value: '+685',
    label: 'Samoa (+685)',
  },
  {
    value: '+378',
    label: 'San Marino (+378)',
  },
  {
    value: '+239',
    label: 'São Tomé und Príncipe (+239)',
  },
  {
    value: '+966',
    label: 'Saudi-Arabien (+966)',
  },
  {
    value: '+46',
    label: 'Schweden (+46)',
  },
  {
    value: '+221',
    label: 'Senegal (+221)',
  },
  {
    value: '+381',
    label: 'Serbien (+381)',
  },
  {
    value: '+248',
    label: 'Seychellen (+248)',
  },
  {
    value: '+232',
    label: 'Sierra Leone (+232)',
  },
  {
    value: '+263',
    label: 'Simbabwe (+263)',
  },
  {
    value: '+65',
    label: 'Singapur (+65)',
  },
  {
    value: '+1',
    label: 'Sint Maarten (niederl. Teil) (+1)',
  },
  {
    value: '+421',
    label: 'Slowakei (+421)',
  },
  {
    value: '+386',
    label: 'Slowenien (+386)',
  },
  {
    value: '+252',
    label: 'Somalia (+252)',
  },
  {
    value: '+34',
    label: 'Spanien (+34)',
  },
  {
    value: '+94',
    label: 'Sri Lanka (+94)',
  },
  {
    value: '+290',
    label: 'St. Helena (+290)',
  },
  {
    value: '+1',
    label: 'St. Kitts und Nevis (+1)',
  },
  {
    value: '+1',
    label: 'St. Lucia (+1)',
  },
  {
    value: '+508',
    label: 'Saint-Pierre und Miquelon (+508)',
  },
  {
    value: '+1',
    label: 'St. Vincent und die Grenadinen (+1)',
  },
  {
    value: '+27',
    label: 'Südafrika (+27)',
  },
  {
    value: '+249',
    label: 'Sudan (+249)',
  },
  {
    value: '+500',
    label: 'Südgeorgien und die Südlichen Sandwichinseln (+500)',
  },
  {
    value: '+211',
    label: 'Südsudan (+211)',
  },
  {
    value: '+597',
    label: 'Suriname (+597)',
  },
  {
    value: '+47',
    label: 'Svalbard und Jan Mayen (+47)',
  },
  {
    value: '+268',
    label: 'Swasiland (+268)',
  },
  {
    value: '+963',
    label: 'Syrien, Arabische Republik (+963)',
  },
  {
    value: '+992',
    label: 'Tadschikistan (+992)',
  },
  {
    value: '+886',
    label: 'Republik China (Taiwan) (+886)',
  },
  {
    value: '+255',
    label: 'Tansania, Vereinigte Republik (+255)',
  },
  {
    value: '+66',
    label: 'Thailand (+66)',
  },
  {
    value: '+670',
    label: 'Timor-Leste (+670)',
  },
  {
    value: '+228',
    label: 'Togo (+228)',
  },
  {
    value: '+690',
    label: 'Tokelau (+690)',
  },
  {
    value: '+676',
    label: 'Tonga (+676)',
  },
  {
    value: '+1',
    label: 'Trinidad und Tobago (+1)',
  },
  {
    value: '+235',
    label: 'Tschad (+235)',
  },
  {
    value: '+420',
    label: 'Tschechien (+420)',
  },
  {
    value: '+216',
    label: 'Tunesien (+216)',
  },
  {
    value: '+90',
    label: 'Türkei (+90)',
  },
  {
    value: '+993',
    label: 'Turkmenistan (+993)',
  },
  {
    value: '+1',
    label: 'Turks- und Caicosinseln (+1)',
  },
  {
    value: '+688',
    label: 'Tuvalu (+688)',
  },
  {
    value: '+256',
    label: 'Uganda (+256)',
  },
  {
    value: '+380',
    label: 'Ukraine (+380)',
  },
  {
    value: '+36',
    label: 'Ungarn (+36)',
  },
  {
    value: '+1',
    label: 'United States Minor Outlying Islands (+1)',
  },
  {
    value: '+598',
    label: 'Uruguay (+598)',
  },
  {
    value: '+998',
    label: 'Usbekistan (+998)',
  },
  {
    value: '+678',
    label: 'Vanuatu (+678)',
  },
  {
    value: '+39',
    label: 'Vatikanstadt (+39)',
  },
  {
    value: '+58',
    label: 'Venezuela (+58)',
  },
  {
    value: '+971',
    label: 'Vereinigte Arabische Emirate (+971)',
  },
  {
    value: '+1',
    label: 'Vereinigte Staaten von Amerika (+1)',
  },
  {
    value: '+44',
    label: 'Vereinigtes Königreich Großbritannien und Nordirland (+44)',
  },
  {
    value: '+84',
    label: 'Vietnam (+84)',
  },
  {
    value: '+681',
    label: 'Wallis und Futuna (+681)',
  },
  {
    value: '+61',
    label: 'Weihnachtsinsel (+61)',
  },
  {
    value: '+212',
    label: 'Westsahara (+212)',
  },
  {
    value: '+236',
    label: 'Zentralafrikanische Republik (+236)',
  },
  {
    value: '+357',
    label: 'Zypern (+357)',
  },
]
