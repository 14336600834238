import { formatDate, returnAvailableValues } from '../utils'

const getInitialValues = (formData, storefrontData, numOfAdditionalDrivers) => {
  const additionalDrivers: any = []
  for (let i = 0; i < numOfAdditionalDrivers; i += 1) {
    additionalDrivers.push({
      firstName:
        formData?.additionalDrivers?.[i]?.firstName ||
        storefrontData.financialProduct?.additionalDrivers?.drivers?.[i]
          ?.firstName,
      lastName:
        formData?.additionalDrivers?.[i]?.lastName ||
        storefrontData.financialProduct?.additionalDrivers?.drivers?.[i]
          ?.lastName,
      dateOfBirth: returnAvailableValues({
        paths: {
          formData: formData?.additionalDrivers?.[i]?.dateOfBirth,
          storefrontData:
            storefrontData.financialProduct?.additionalDrivers?.drivers?.[i]
              ?.dateOfBirth,
        },
        formater: (field) => formatDate(field),
      }),
    })
  }
  return { additionalDrivers }
}

export default getInitialValues
