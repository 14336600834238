import React, { FunctionComponent } from 'react'

import {
  FormField as BRFormField,
  ErrorMessage,
  Fieldset,
  InfoIcon,
} from '@vwfs-bronson/bronson-react'

import { Field } from 'formik'
import get from 'lodash/get'

interface Props {
  render: Function
  name: string
  labelElement?: string
  labelText?: string
  hint?: string
  infoIcon?: string
  notion?: boolean
  type?: string
  className?: string
  testId?: string
  handleBlur?: Function
}

export const FormField: FunctionComponent<Props> = ({
  render,
  name,
  labelElement = 'label',
  labelText,
  hint,
  infoIcon,
  notion,
  type,
  className,
  testId,
  handleBlur,
}: any) => {
  return (
    <Fieldset.Row>
      <Field
        name={name}
        render={({ field, form }) => {
          return (
            <BRFormField
              onBlur={e => handleBlur && handleBlur(e)}
              testId={testId}
              className={className}
              labelElement="div"
              noFloatingLabel
              labelText={
                ((
                  <>
                    {labelText}
                    {notion && '*'}
                    {infoIcon && (
                      <InfoIcon icon="semantic-info">{infoIcon}</InfoIcon>
                    )}
                  </>
                ) as unknown as string) || ''
              }
              type={type}
              errorMessage={
                get(form.errors, name) && get(form.touched, name) ? (
                  <ErrorMessage>
                    {get(form.touched, name) && get(form.errors, name)}
                  </ErrorMessage>
                ) : (
                  ''
                )
              }
              hint={hint}
            >
              {render({
                name,
                error: !!(get(form.touched, name)
                  ? get(form.errors, name)
                  : undefined),
                ...field,
              })}
            </BRFormField>
          )
        }}
      />
    </Fieldset.Row>
  )
}
