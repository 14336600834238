import React, { useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  Button,
  Fieldset,
  Form,
  Spinner,
  Layout,
  Input,
  ErrorMessage,
} from '@vwfs-bronson/bronson-react'
import { FormField } from '../../../components/FormField'
import getInitialValues from './initial-values'
import {
  scrollToElement,
  isValidationDisabled,
  scrollToActiveFormSection,
} from '../../../services/common/form'
import { getDataModels } from '../../../services/redux/features/data-models.redux'
import {
  getFormData,
  saveFormData,
} from '../../../services/redux/features/form.redux'
import {
  resetOpenSectionState,
  FormSectionTitle as Title,
} from '../../../components/FormSection'
import { storeAdditionalDriversData } from '../../../services/api/forms/additionalDrivers/additionalDrivers'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { setActualPage } from '../../../services/redux/features/custom.redux'
import { InputDate } from '../../../components/InputDate/InputDate'
import getValidationSchema from './validation-schema'
import { isAudi, scrollElWithDelay } from '../../../services/common/utils'
import { Analytics } from '../../../services/analytics'

const AdditionalDrivers = (props, ref) => {
  const { updateValidSections } = props
  const { t } = useTranslation()

  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getData: () => {
      return formState.current.values
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      props.onValid(formState.current.isValid)
      return formState.current.isValid
    },
  }))

  const { dataModels, formData, storefrontData } = props
  const formState = React.useRef({} as any)
  const [initialValid, setInitialValid] = useState<boolean | null>(null)
  const [isloading, setisloading] = useState(false)
  const [errors, setErrors] = useState(undefined as any)
  const [touched, setTouched] = useState(undefined as any)

  const isInitialValid = formikProps => {
    if (initialValid !== null) {
      return initialValid
    }
    if (storefrontData) {
      const isValid = getValidationSchema(storefrontData)?.isValidSync(
        formikProps.initialValues
      )
      setInitialValid(isValid)
      return isValid
    }
    return false
  }

  React.useEffect(() => {
    if (
      errors !== undefined &&
      Object.entries(errors).length !== 0 &&
      errors.constructor === Object
    ) {
      scrollToElement('.is-error', 'start')
    }
  }, [errors, touched])

  const renderAllAdditionalDrivers = () => {
    const drivers: any = []
    for (
      let i = 0;
      i < storefrontData.financialProduct.additionalDrivers.amount;
      i += 1
    ) {
      drivers.push(additionalDriver(i))
    }
    return drivers
  }

  const additionalDriver = index => {
    return (
      index < 5 && (
        <Fieldset key={index}>
          <Fieldset.Row>
            <Title>
              {index + 1}. {t('additional-drivers:itemTitle')}
            </Title>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  testId={`firstName${index}`}
                  type="input"
                  name={`additionalDrivers[${index}].firstName`}
                  labelText={t('additional-drivers:firstName')}
                  render={fieldProps => <Input {...fieldProps} />}
                />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  testId={`lastName${index}`}
                  type="input"
                  name={`additionalDrivers[${index}].lastName`}
                  labelText={t('additional-drivers:lastName')}
                  render={fieldProps => <Input {...fieldProps} />}
                />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  testId={`dateOfBirth${index}`}
                  type="other"
                  name={`additionalDrivers[${index}].dateOfBirth`}
                  labelText={t('additional-drivers:dateOfBirth')}
                  render={fieldProps => <InputDate {...fieldProps} />}
                />
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </Fieldset>
      )
    )
  }

  const onSubmit = (values: any) => {
    setisloading(true)
    Analytics.addToDatalayer({
      'product.productAddons': `${storefrontData.financialProduct.additionalDrivers.amount} Zusatzfahrer`,
    })
    storeAdditionalDriversData(values)
      .then((e: any) => {
        setisloading(false)
        resetOpenSectionState()
        updateValidSections('additionalDrivers', true)
        props.saveFormData({
          ...formData,
          additionalDrivers: values.additionalDrivers,
        })
        scrollElWithDelay(scrollToActiveFormSection)
      })
      .catch(() => {
        setisloading(false)
        props.onErrorFetch()
      })
  }
  const onChange = ev => {
    updateValidSections('additionalDrivers', false)
    if (!isValidationDisabled(ev.target)) {
      props.onValid(false)
    }
  }

  return (
    <>
      {Object.keys(dataModels).length ? (
        <Formik
          isInitialValid={isInitialValid}
          validationSchema={getValidationSchema(storefrontData)}
          initialValues={getInitialValues(
            formData,
            storefrontData,
            storefrontData.financialProduct?.additionalDrivers?.amount
          )}
          validateOnBlur={false}
          onSubmit={values => onSubmit(values)}
          render={formProps => {
            const { values, handleSubmit } = formProps
            setErrors(formProps.errors)
            formState.current = {
              values,
              isValid: formProps.isValid,
              dirty: formProps.dirty,
            }
            submitFunction = formProps.submitForm
            setTouched(formProps.touched)
            const handleSubmitOwn = e => {
              handleSubmit(e)
            }

            return (
              <Form onChange={onChange} onSubmit={handleSubmitOwn} floatingLabel>
                {renderAllAdditionalDrivers()}

                {formProps.errors?.additionalDrivers?.[0] &&
                  typeof formProps.errors?.additionalDrivers?.[0] ===
                    'string' && (
                    <ErrorMessage>
                      {formProps.errors?.additionalDrivers?.[0]}
                    </ErrorMessage>
                  )}
                <Fieldset>
                  <Fieldset.Row className="u-text-right u-mt-small">
                    <Button
                      id="ibanDetailsButton"
                      testId="ibanDetailsButton"
                      type="submit"
                    >
                      {isloading ? (
                        <Spinner section center small>
                          {isAudi() ? <span>&nbsp;</span> : ''}
                        </Spinner>
                      ) : (
                        t('additional-drivers:buttonNext')
                      )}
                    </Button>
                  </Fieldset.Row>
                </Fieldset>
              </Form>
            )
          }}
        />
      ) : (
        <div className="u-p-xlarge">
          <Spinner section />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    dataModels: getDataModels(state),
    formData: getFormData(state),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps, { saveFormData, setActualPage }, null, {
  forwardRef: true,
})(React.forwardRef(AdditionalDrivers))
