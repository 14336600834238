import { Analytics } from '../../../services/analytics'

export const trackPage = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: 'Payment data',
    [Analytics.keys.viewChange()]: 'Payment data',
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  })
  Analytics.trackPage()
}
