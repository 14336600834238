import React, { FunctionComponent } from 'react'
import {
  Footer as BrFooter,
  BackToTop,
  Breadcrumb,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Page } from '../../models/page'
import navColumns from '../../resources/base/pages/navColumns.json'
import { isAudi } from '../../services/common/utils'
import { trackFAQ } from './tracking'

export type FooterProps = {
  pages: Page[]
}

const Footer: FunctionComponent<FooterProps> = ({ pages }) => {
  const { t } = useTranslation()

  const renderColumns = () => {
    const columns = navColumns
    return (
      <>
        <BrFooter.ModuleNavColumns>
          {columns.map(column => (
            <BrFooter.NavColumn key={column.title} title={column.title}>
              {column.items?.map(item => (
                <BrFooter.NavColumn.Item
                  key={item.url || null}
                  href={item.url || undefined}
                  testId={item.testId}
                  normalLink
                >
                  {item.name}
                </BrFooter.NavColumn.Item>
              ))}
            </BrFooter.NavColumn>
          ))}
        </BrFooter.ModuleNavColumns>
      </>
    )
  }

  const renderItems = () => {
    const items = t('app:footer:links', { returnObjects: true })
    return (
      <>
        {items.length &&
          items.map(currentPage => (
            <BrFooter.ModuleMetaNav.Item
              key={currentPage.url || null}
              href={currentPage.url || null}
              normalLink
              onClick={
                currentPage.name === 'FAQ'
                  ? () => trackFAQ(currentPage?.name)
                  : undefined
              }
            >
              {currentPage.name}
            </BrFooter.ModuleMetaNav.Item>
          ))}
      </>
    )
  }

  return (
    <>
      <BackToTop>{t('app:footer:backToTopLabel')}</BackToTop>
      <BrFooter>
        <BrFooter.ModuleBreadcrumbs>
          <Breadcrumb aria-label="Breadcrumb">
            <Breadcrumb.Item normalLink href="https://autovermietung.vwfs.de">
              Home
            </Breadcrumb.Item>
          </Breadcrumb>
        </BrFooter.ModuleBreadcrumbs>

        {!isAudi() && renderColumns()}

        <BrFooter.ModuleMetaNav
          copyrightSign="© "
          copyrightText={`${t(
            'app:footer:copyright'
          )} ${new Date().getFullYear()}`}
          socialItems={t('app:footer:social', { returnObjects: true })}
        >
          {renderItems()}
        </BrFooter.ModuleMetaNav>

        <BrFooter.ModuleFootnotes>
          {t('app:footer:text')}
        </BrFooter.ModuleFootnotes>
      </BrFooter>
    </>
  )
}

export default Footer
