import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Form, Layout, Spinner, Modal, ContentSection } from '@vwfs-bronson/bronson-react'
import getInitialValues from './initial-values'
import DataProtectionFieldset from './DataProtectionFieldset'
import ValidationSchema from './validation-schema'
import { scrollToElement } from '../../../services/common/form'
import { getFormData } from '../../../services/redux/features/form.redux'
import {
  completeJourney,
  checkStatus,
} from '../../../services/api/submitData/complete-journey'
import { history } from '../../../services/routing'
import routes from '../../../routes'
import { getTlsValid } from '../../../services/redux/features/custom.redux'

const DataProtection = (props, ref) => {
  const { tlsValid, trackSubmit } = props
  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getData: () => {
      return formState.current.formProps.values
    },
    validate: () => {
      return formState.current.formProps.isValid
    },
  }))
  const formRef = React.useRef()

  const { formData } = props
  const [formValues, setFormValues] = React.useState(getInitialValues(formData))
  const formState = React.useRef({})
  const { t } = useTranslation()
  const [isloading, setisloading] = React.useState(false)
  const handleSubmit = e => {
    const {
      dataPrivacyConsentAcceptance,
      allowProfilesCreation,
      allowPhoneAds,
      allowMailAds,
    } = formState.current.formProps.values
    const data = {
      dataPrivacyStatement: {
        dataPrivacyConsentAcceptance: tlsValid
          ? true
          : dataPrivacyConsentAcceptance,
        allowPhoneAds,
        allowMailAds,
        ...(dataPrivacyConsentAcceptance && {
          insecureEmailCommunicationAcceptance: true,
        }),
        allowProfilesCreation,
      },
    }
    if (trackSubmit) {
      trackSubmit(data.dataPrivacyStatement)
    }
    setisloading(true)
    completeJourney(data)
      .then(response => {
        if (response.status === 200) {
          const TIMEOUT_AFTER = 120000
          const INTERVAL = 3000
          const getIdentStatus = async () => {
            try {
              const finishStatusResult = await checkStatus()
              switch (finishStatusResult.status) {
                case 'IN_PROGRESS':
                  break
                case 'SUCCESS':
                  clearInterval(timer)
                  clearTimeout(timeoutTimer)
                  setisloading(false)
                  history.push(routes.confirmationPage)
                  break
                default:
                  clearInterval(timer)
                  clearTimeout(timeoutTimer)
                  setisloading(false)
                  history.pushPreservePath(routes.apiErrorPage)
                  break
              }
            } catch (error) {
              setisloading(false)
              clearInterval(timer)
              clearTimeout(timeoutTimer)
              history.pushPreservePath(routes.apiErrorPage)
            }
          }

          const timeoutTimer = setTimeout(() => {
            setisloading(false)
            clearInterval(timer)
            clearTimeout(timeoutTimer)
            history.pushPreservePath(routes.apiErrorPage)
          }, TIMEOUT_AFTER)
          const timer = setInterval(getIdentStatus, INTERVAL)
        } else {
          history.pushPreservePath(routes.apiErrorPage)
        }
      })
      .catch(() => {
        history.pushPreservePath(routes.apiErrorPage)
      })
  }

  const onChange = async (e, formProps) => {
    const { target } = e
    const { name } = target
    if (name) {
      const value = target.type === 'checkbox' ? target.checked : target.value
      await formProps.setFieldValue(name, value)
      await formProps.setFieldTouched(name)
    }
  }

  const checkToScroll = () => {
    if (formRef?.current?.errors?.termsAgreeCheckAgree) {
      scrollToElement('#GeneralConditionsTitle', 'start')
    }
  }

  const floatingButtonOnClick = () => {
    formRef?.current?.handleSubmit()
    setTimeout(() => {
      checkToScroll()
    })
  }

  React.useEffect(() => {
    checkToScroll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef?.current?.errors])

  const renderSubmitButton = () => (
    <div className="o-page-wrap" style={{position: 'sticky', bottom: '3%'}}>
      <Layout right>
        <Layout.Item default="1/1">
          <div className="c-action-buttons-container">
            <Button
              action
              type="submit"
              icon={t('summary:floatingSubmitButton:icon')}
              className="u-float-right"
              onClick={floatingButtonOnClick}
            >
              {t('summary:floatingSubmitButton:text')}
            </Button>
          </div>
        </Layout.Item>
      </Layout>
    </div>
  )

  return (
    <>
      <Modal
        title={t('summary:modalSendTitle')}
        shown={isloading}
        hideCloseButton
      >
        <Spinner center />
        <div className="u-mt">
          {t('summary:modalSendBody1')}
          <br />
          {t('summary:modalSendBody2')}
        </div>
      </Modal>
      <ContentSection pageWrap>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(formData)}
          validationSchema={ValidationSchema(tlsValid, formValues.dataPrivacyConsentAcceptance)}
          onSubmit={handleSubmit}
        >
          {formProps => {
            formState.current = { formProps }
            setFormValues(formProps.values)

            return (
              <Form
                id="data-protection-form"
                onChange={e => onChange(e, formProps)}
                floatingLabel
              >
                <DataProtectionFieldset t={t} formProps={formProps} />
              </Form>
            )
          }}
        </Formik>
      </ContentSection>
      {renderSubmitButton()}
    </>
  )
}

const mapStateToProps = state => {
  return {
    formData: getFormData(state),
    tlsValid: getTlsValid(state),
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  React.forwardRef(DataProtection)
)
