import React from 'react'
import {
  Button,
  Checkbox,
  Fieldset,
  Heading,
  Layout,
} from '@vwfs-bronson/bronson-react'
import { FieldArray } from 'formik'
import { AddressType } from '../../../models/AppPage/Contact'
import { initalAdressFields } from './initial-values'
import {
  Street,
  HouseNumber,
  ZipCode,
  City,
  CountryCode,
  Email,
  MobilePhoneNumber,
  MobilePhoneCountryCode,
  CompanyName,
  StateUSACanada,
} from './fields'
import { ZipCodeAutocomplete } from '../../../components/AddressAutocomplete/ZipCodeAutocomplete'
import { StreetAutocomplete } from '../../../components/AddressAutocomplete/StreetAutocomplete'

const RenderAddress = ({ t, formProps, index }) => {
  const { setFieldValue, values } = formProps
  return (
    <>
      <Layout.Item default="1/1" s="1/1">
        <Heading level={5} className="u-mb-none">
          {t('contact-details:addressDataTitle')}
        </Heading>
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <CountryCode t={t} index={index} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <Button
          className="u-mt"
          link
          disabled={values?.addresses?.[index]?.isManual}
          onClick={(e: any) => {
            e.preventDefault()
            setFieldValue(`addresses[${index}].isManual`, true)
          }}
        >
          {t('contact-details:manualMode')}
        </Button>
      </Layout.Item>
      {(values?.addresses?.[0]?.countryCode === 'CA' ||
        values?.addresses?.[0]?.countryCode === 'US') && (
        <>
          <Layout.Item default="1/2" s="1/1">
            <StateUSACanada t={t} index={index} />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1" />
        </>
      )}
      <Layout.Item default="1/2" s="1/1">
        {values?.addresses?.[0]?.isManual ? (
          <ZipCode t={t} index={index} />
        ) : (
          <ZipCodeAutocomplete
            prefix={`addresses[${index}].`}
            label={t('contact-details:zipCode')}
          />
        )}
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <City t={t} index={index} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        {values?.addresses?.[0]?.isManual ? (
          <Street t={t} index={index} />
        ) : (
          <StreetAutocomplete
            prefix={`addresses[${index}].`}
            label={t('contact-details:street')}
          />
        )}
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <HouseNumber t={t} index={index} />
      </Layout.Item>
    </>
  )
}

const RenderOtherAddresses = ({ t, formProps, index, address }) => {
  const { setFieldValue, values } = formProps
  return (
    <>
      <Layout.Item default="1/1" s="1/1">
        <Heading level={5} className="u-mt u-mb-none">
          {t(`contact-details:otherAddressesTitle:${address.addressType}`)}
        </Heading>
      </Layout.Item>

      {address.addressType === AddressType.BUSINESS && (
        <Layout.Item default="1/1" s="1/1">
          <CompanyName t={t} index={index} />
        </Layout.Item>
      )}

      <Layout.Item default="1/2" s="1/1">
        <CountryCode t={t} index={index} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <Button
          className="u-mt"
          link
          disabled={values?.addresses?.[index]?.isManual}
          onClick={(e: any) => {
            e.preventDefault()
            setFieldValue(`addresses[${index}].isManual`, true)
          }}
        >
          {t('contact-details:manualMode')}
        </Button>
      </Layout.Item>
      {(values?.addresses?.[1]?.countryCode === 'CA' ||
        values?.addresses?.[1]?.countryCode === 'US') && (
        <>
          <Layout.Item default="1/2" s="1/1">
            <StateUSACanada t={t} index={index} />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1" />
        </>
      )}
      <Layout.Item default="1/2" s="1/1">
        {values?.addresses?.[1]?.isManual ? (
          <ZipCode t={t} index={index} />
        ) : (
          <ZipCodeAutocomplete
            prefix={`addresses[${index}].`}
            label={t('contact-details:zipCode')}
          />
        )}
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <City t={t} index={index} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        {values?.addresses?.[1]?.isManual ? (
          <Street t={t} index={index} />
        ) : (
          <StreetAutocomplete
            prefix={`addresses[${index}].`}
            label={t('contact-details:street')}
          />
        )}
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <HouseNumber t={t} index={index} />
      </Layout.Item>
    </>
  )
}

const RenderContactData = ({ t }) => {
  return (
    <>
      <Layout.Item default="1/1" s="1/1">
        <Heading level={5} className="u-mt u-mb-none">
          {t('contact-details:contactDataTitle')}
        </Heading>
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <Email t={t} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1" />
      <Layout.Item default="1/2" s="1/1">
        <MobilePhoneCountryCode t={t} />
      </Layout.Item>
      <Layout.Item default="1/2" s="1/1">
        <MobilePhoneNumber t={t} />
      </Layout.Item>
    </>
  )
}

const renderFieldset = (t, dataModels, formData, storefrontData, formProps) => {
  const { values, setFieldValue } = formProps

  const addAddressToArray = (
    e,
    fieldName,
    addressType,
    hasCompanyName,
    remove,
    push
  ) => {
    if (e.target.checked) {
      push({ ...initalAdressFields, addressType, hasCompanyName })
      setFieldValue(fieldName, true)
    } else {
      const addressIndex = values.addresses.findIndex(
        (el) => el.addressType === addressType
      )
      remove(addressIndex)
      setFieldValue(fieldName, false)
    }
  }

  const otherAddresses = values.addresses.slice(1)

  return (
    <Fieldset>
      <Fieldset.Row>
        <Layout>
          <FieldArray name="addresses">
            {({ remove, push }) => (
              <>
                <RenderAddress t={t} formProps={formProps} index={0} />
                <br />
                <>
                  <Layout.Item default="1/1" s="1/1">
                    <Checkbox
                      name="showBusinessAddress"
                      id="showBusinessAddress"
                      defaultChecked={values.showBusinessAddress}
                      onChange={(e) =>
                        addAddressToArray(
                          e,
                          'showBusinessAddress',
                          'Business',
                          true,
                          remove,
                          push
                        )
                      }
                    >
                      {t('contact-details:businessAddressToggle')}
                    </Checkbox>
                  </Layout.Item>

                  {otherAddresses.map((address, index) => (
                    <RenderOtherAddresses
                      t={t}
                      formProps={formProps}
                      address={address}
                      index={index + 1}
                      key={address.addressType}
                    />
                  ))}
                </>
                <br />
                <RenderContactData t={t} />
              </>
            )}
          </FieldArray>
        </Layout>
      </Fieldset.Row>
    </Fieldset>
  )
}

export default renderFieldset
