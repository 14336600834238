import axios from 'axios'

const { REACT_APP_API_GATEWAY } = process.env

export function autocomplete(
  body: any,
  abortController?: AbortController
): Promise<{ label: string; countryCode?: string }[]> {
  const url = `${REACT_APP_API_GATEWAY}/address/autocomplete`

  return axios
    .post(url, body, { signal: abortController?.signal })
    .then(response => {
      return response.data
    })
}
