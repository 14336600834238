import React, { FunctionComponent } from 'react'
import { Main } from '@vwfs-bronson/bronson-react'
import Header from './Header'
import Footer from './Footer'
import { Page } from '../../models/page'

export type DefaultLayoutProps = {
  pages: Page[]
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({
  pages,
  children,
}) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer pages={pages} />
    </>
  )
}

export { DefaultLayout }
