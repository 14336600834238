/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import {
  Modal,
  ContentSection,
  Paragraph,
  Button,
} from '@vwfs-bronson/bronson-react'
import routes from '../../routes'
import { StorefrontShoppingCart } from '../../components/ShoppingCart'
import AdditionalDrivers from './AdditionalDrivers'
import {
  FormSectionGroup as Group,
  FormSectionHeader as Header,
  FormSectionContent as Content,
  FormSectionSection as Section,
  FormSectionTitle as Title,
  FormSectionSubtitle as Subtitle,
} from '../../components/FormSection'
import { Analytics } from '../../services/analytics'
import { HtmlContent } from '../../components/HtmlContent'
import { history, qs } from '../../services/routing'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'

import {
  saveFormData,
  getFormData,
} from '../../services/redux/features/form.redux'

import {
  setKeycloak,
  getKeycloakTokens,
} from '../../services/redux/features/keycloak.redux'
import {
  hasAdditionalDrivers,
  isVWFS,
  isAudi,
} from '../../services/common/utils'
import { scrollToElement } from '../../services/common/form'
import PersonalDetails from './PersonalDetails'
import ContactDetails from './ContactDetails'
import { Forms } from '../../models/AppPage/Forms'
import IdentityBroker from '../../components/IdentityBroker/Login'
import {
  getActualPage,
  setIsLoggedIn as setIsLoggedInAction,
  getIsLoggedIn,
  setErrorData as setErrorDataAction,
  getContinueJourney,
  setContinueJourney as setContinueJourneyAction,
} from '../../services/redux/features/custom.redux'
import PaymentDetails from './PaymentDetails'
import { trackConnectError } from './tracking'
import { LastFormSectionCompleted } from '../../models/enums'

const AppForm = (props) => {
  const {
    editPage,
    location,
    formData,
    storefrontData,
    continueJourney,
    setContinueJourney,
  } = props

  const { t } = useTranslation()

  const personalDetailsRef = React.useRef(undefined as any)
  const contactDetailsRef = React.useRef(undefined as any)
  const paymentDetailsRef = React.useRef(undefined as any)
  const additionalDriversRef = React.useRef(undefined as any)
  const entryPointUrl = storefrontData?.salesChannel?.entryPointUrl
  const merchantKey = storefrontData?.companyData?.paymentData?.merchantKey
  const transactionId = storefrontData?.transaction?.id
  const NUMBER_OF_BUCKETS = () => {
    // isAudi check is temporary fix that will be removed after payment integration is fixed
    if ((isVWFS() && !merchantKey) || (isAudi() && !merchantKey)) {
      return hasAdditionalDrivers(storefrontData) ? 3 : 2
    }
    return hasAdditionalDrivers(storefrontData) ? 4 : 3
  }
  const [validSections, setValidSections] = React.useState(
    formData.formValidationStatus?.validSections || {}
  )
  const [openFormSection, setOpenFormSection] = React.useState(undefined as any)
  const [showErrorOnConnect, setshowErrorOnConnect] = React.useState(false)

  React.useEffect(() => {
    goSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSections])

  React.useEffect(() => {
    if (editPage === true) {
      const ids = ['personalDetails', 'contactDetails', 'paymentDetails']
      if (hasAdditionalDrivers(storefrontData)) {
        ids.splice(2, 0, 'additionalDrivers')
      }
      const sectionNumber = Number(qs(location.search, 'section'))
      getValidationStateFromSummary(ids[sectionNumber])
      setOpenFormSection(ids[sectionNumber])
    } else if (continueJourney) {
      getValidSections()
      doPrefill()
    }

    if (
      props.storefrontId ||
      get(props, 'history.location.state.storefrontId')
    ) {
      document.title = t('form:tabTitle')
    } else {
      history.pushPreservePath(routes.errorPage)
    }
    // we don't need props and t as dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (openFormSection) {
      if (!document.querySelector('.is-error')) {
        scrollToElement('.c-form-section__header.is-active')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFormSection])

  React.useEffect(() => {
    Analytics.addToDatalayer({
      'core.attributes.transactionID': transactionId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doPrefill = async () => {
    await validatePrefilledForms(
      storefrontData?.custom?.lastFormSectionCompleted
    )
    setContinueJourney('')
  }
  const validatePrefilledForms = async (lastFormSectionCompleted: string) => {
    const validationResult: Forms = {
      personalDetails:
        lastFormSectionCompleted === LastFormSectionCompleted.PERSONAL ||
        lastFormSectionCompleted === LastFormSectionCompleted.CONTACT ||
        lastFormSectionCompleted === LastFormSectionCompleted.DRIVERS ||
        lastFormSectionCompleted === LastFormSectionCompleted.PAYMENT,
      contactDetails:
        lastFormSectionCompleted === LastFormSectionCompleted.CONTACT ||
        lastFormSectionCompleted === LastFormSectionCompleted.DRIVERS ||
        lastFormSectionCompleted === LastFormSectionCompleted.PAYMENT,
      paymentDetails:
        lastFormSectionCompleted === LastFormSectionCompleted.PAYMENT,
      additionalDrivers: hasAdditionalDrivers(storefrontData)
        ? lastFormSectionCompleted === LastFormSectionCompleted.DRIVERS ||
          lastFormSectionCompleted === LastFormSectionCompleted.PAYMENT
        : undefined,
    }
    await setValidSections(validationResult)
    return validationResult
  }

  const getValidationStateFromSummary = (section) => {
    const reducedSections =
      (isVWFS() && !merchantKey) || (isAudi() && !merchantKey)
    const validationResult: Forms = {
      personalDetails: section !== 'personalDetails',
      contactDetails: section !== 'contactDetails',
      additionalDrivers: hasAdditionalDrivers(storefrontData)
        ? section !== 'additionalDrivers' || false
        : undefined,
      paymentDetails: reducedSections
        ? undefined
        : section !== 'paymentDetails' || false,
    }
    setValidSections(validationResult)
  }

  const areAllFormsValid = (validation) =>
    Object.values(validation).filter((e) => e === true).length ===
    NUMBER_OF_BUCKETS()

  const goSummary = () => {
    if (areAllFormsValid(validSections)) {
      history.push('/summary')
      scrollToElement('.c-header')
      setOpenFormSection(undefined)
    }
  }

  const getValidSections = async () => {
    const reducedSections =
      (isVWFS() && !merchantKey) || (isAudi() && !merchantKey)
    const validationResult: Forms = {
      personalDetails: validSections?.personalDetails || false,
      contactDetails: validSections?.contact || false,
      additionalDrivers: hasAdditionalDrivers(storefrontData)
        ? validSections?.additionalDrivers || false
        : undefined,
      paymentDetails: reducedSections
        ? undefined
        : validSections?.paymentDetails || false,
    }
    await setValidSections(validationResult)
    return validationResult
  }

  const getFormSectionsValidSections = () => {
    const reducedSections =
      (isVWFS() && !merchantKey) || (isAudi() && !merchantKey)
    return validSections
      ? hasAdditionalDrivers(storefrontData)
        ? reducedSections
          ? [
              validSections?.personalDetails || false,
              validSections?.contactDetails || false,
              validSections?.additionalDrivers || false,
            ]
          : [
              validSections?.personalDetails || false,
              validSections?.contactDetails || false,
              validSections?.additionalDrivers || false,
              validSections?.paymentDetails || false,
            ]
        : reducedSections
        ? [
            validSections?.personalDetails || false,
            validSections?.contactDetails || false,
          ]
        : [
            validSections?.personalDetails || false,
            validSections?.contactDetails || false,
            validSections?.paymentDetails || false,
          ]
      : new Array(
          hasAdditionalDrivers(storefrontData)
            ? reducedSections
              ? 3
              : 4
            : reducedSections
            ? 2
            : 3
        ).fill(validSections)
  }

  const updateValidSections = (section, value) => {
    const updatedSections = { ...validSections }
    updatedSections[section] = value
    setValidSections(updatedSections)
    return validSections
  }

  const onErrorFetch = () => {
    trackConnectError()
    setshowErrorOnConnect(true)
  }
  const validProps = {
    updateValidSections,
    goSummary,
    onErrorFetch,
  }

  const sections = [
    {
      id: 'personalDetails',
      alwaysOpen: false,
      trackingName: 'form:personalDetails:analyticsName',
      title: 'personal-details:title',
      subtitle: 'personal-details:subtitle',
      child: (
        <PersonalDetails
          {...validProps}
          ref={personalDetailsRef}
          editPage={editPage}
        />
      ),
    },
    {
      id: 'contactDetails',
      alwaysOpen: false,
      trackingName: 'form:contactDetails:analyticsName',
      title: 'contact-details:title',
      subtitle: 'contact-details:subtitle',
      child: <ContactDetails {...validProps} ref={contactDetailsRef} />,
    },
    {
      id: 'paymentDetails',
      alwaysOpen: false,
      title: 'payment-details:title',
      subtitle: 'payment-details:subtitle',
      child: <PaymentDetails {...validProps} ref={paymentDetailsRef} />,
    },
  ]

  // removing paymentDetails from array if no merchantKey provided
  // isAudi check is temporary fix that will be removed after payment integration is fixed
  if ((isVWFS() && !merchantKey) || (isAudi() && !merchantKey)) {
    sections.pop()
  }

  if (hasAdditionalDrivers(storefrontData)) {
    sections.splice(2, 0, {
      id: 'additionalDrivers',
      alwaysOpen: false,
      trackingName: 'form:additionalDrivers:analyticsName',
      title: 'additional-drivers:title',
      subtitle: 'additional-drivers:headerText',
      child: (
        <AdditionalDrivers
          {...validProps}
          ref={additionalDriversRef}
          editPage={editPage}
        />
      ),
    })
  }

  const showSection = (section) => {
    return section.id === openFormSection
  }

  const formSections = sections.map((section) => {
    return (
      <Section key={section.id} open={showSection(section)} id={section.id}>
        <Header>
          <Title>{t(section.title)}</Title>
          {section.subtitle && (
            <Subtitle>
              <HtmlContent>{t(section.subtitle)}</HtmlContent>
            </Subtitle>
          )}
        </Header>
        <Content>{section.child}</Content>
      </Section>
    )
  })

  const onClose = () => {
    setshowErrorOnConnect(false)
  }

  return (
    <>
      <Modal
        onClose={onClose}
        onClickOutside={onClose}
        title={t('error:heading')}
        shown={showErrorOnConnect}
      >
        <p> {t('error:text1')}</p>
        <Button testId="contactDetailsButton" onClick={onClose}>
          {t('form:btnContinue')}
        </Button>
      </Modal>
      <ContentSection>
        <ContentSection.ComponentWrapper>
          <StorefrontShoppingCart foldout floatingBar />
        </ContentSection.ComponentWrapper>
      </ContentSection>
      <IdentityBroker entryPointUrl={entryPointUrl} />
      <ContentSection pageWrap>
        <Paragraph testId="text">
          <strong>{t('form:text')}</strong>
        </Paragraph>
        <ContentSection.ComponentWrapper>
          {!editPage || (editPage && openFormSection) ? (
            <Group
              validSections={getFormSectionsValidSections()}
              alwaysOpen={sections
                .filter((item) => item.alwaysOpen)
                .map((item) => item.id)}
            >
              {formSections}
            </Group>
          ) : (
            ''
          )}{' '}
        </ContentSection.ComponentWrapper>
      </ContentSection>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formData: getFormData(state),
    actualPage: getActualPage(state),
    getTokens: getKeycloakTokens(state),
    isLoggedIn: getIsLoggedIn(state),
    storefrontData: getStorefrontData(state),
    continueJourney: getContinueJourney(state),
  }
}

export default connect(mapStateToProps, {
  saveFormData,
  setKeycloak,
  setIsLoggedIn: setIsLoggedInAction,
  setErrorData: setErrorDataAction,
  setContinueJourney: setContinueJourneyAction,
})(AppForm)
