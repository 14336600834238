import React from 'react'
import { Input } from '@vwfs-bronson/bronson-react'
import { useFormikContext } from 'formik'
import { Select } from '../../../components/Select'
import { FormField } from '../../../components/FormField'
import { phoneCountryCodes, countries } from '../../../resources/base/models'

export const Street = ({ t, index }) => {
  const { values } = useFormikContext()
  const isDisabled = () => {
    return (
      !(values as any)?.addresses?.[index]?.isManual &&
      !(values as any)?.addresses?.[index]?.city
    )
  }
  return (
    <FormField
      testId="street"
      type="input"
      name={`addresses[${index}].street`}
      labelText={t('contact-details:street')}
      render={(fieldProps) => (
        <Input {...fieldProps} disabled={isDisabled()} autocomplete="nope" />
      )}
    />
  )
}

export const HouseNumber = ({ t, index }) => {
  const { values } = useFormikContext()
  const isDisabled = () => {
    return (
      !(values as any)?.addresses?.[index]?.isManual &&
      !(values as any)?.addresses?.[index]?.streetSelected
    )
  }
  return (
    <FormField
      testId="houseNumber"
      type="input"
      name={`addresses[${index}].houseNumber`}
      labelText={t('contact-details:houseNumber')}
      render={(fieldProps) => (
        <Input {...fieldProps} disabled={isDisabled()} autocomplete="nope" />
      )}
    />
  )
}

export const ZipCode = ({ t, index }) => (
  <FormField
    testId="zipCode"
    type="input"
    name={`addresses[${index}].zipCode`}
    labelText={t('contact-details:zipCode')}
    render={(fieldProps) => <Input {...fieldProps} autocomplete="nope" />}
  />
)

export const City = ({ t, index }) => {
  const { values } = useFormikContext()
  const isManual = (values as any)?.addresses?.[index]?.isManual
  return (
    <FormField
      testId="city"
      type="input"
      name={`addresses[${index}].city`}
      labelText={t('contact-details:city')}
      infoIcon={t('contact-details:city_tooltip')}
      render={(fieldProps) => (
        <Input {...fieldProps} disabled={!isManual} autocomplete="nope" />
      )}
    />
  )
}
export const CountryCode = ({ t, index }) => (
  <FormField
    testId="countryCode"
    type="select"
    name={`addresses[${index}].countryCode`}
    labelText={t('contact-details:countryCode')}
    render={(fieldProps) => (
      <Select
        defaultOption
        values={countries}
        {...fieldProps}
        autocomplete="nope"
      >
        {countries?.map(({ value, label }) => (
          <Select.Item key={`${value}${label}`} value={value}>
            {label}
          </Select.Item>
        ))}
      </Select>
    )}
  />
)

export const StateUSACanada = ({ t, index }) => (
  <FormField
    testId="state"
    type="input"
    name={`addresses[${index}].state`}
    labelText={t('contact-details:state')}
    infoIcon={t('contact-details:state_tooltip')}
    render={(fieldProps) => <Input {...fieldProps} maxlength={2} />}
  />
)

export const Email = ({ t }) => (
  <FormField
    testId="email"
    type="input"
    name="contactData.email"
    labelText={t('contact-details:email')}
    infoIcon={t('contact-details:email_tooltip')}
    render={(fieldProps) => <Input {...fieldProps} />}
  />
)
export const MobilePhoneNumber = ({ t }) => {
  const { setFieldValue } = useFormikContext()
  return (
    <FormField
      testId="mobilePhoneNumber"
      type="input"
      name="contactData.mobilePhoneNumber"
      labelText={t('contact-details:mobilePhoneNumber')}
      infoIcon={t('contact-details:mobilePhoneNumber_tooltip')}
      handleBlur={(e) => {
        if (e?.target?.value) {
          setFieldValue(
            'contactData.mobilePhoneNumber',
            e.target.value?.replace(/\s/g, '')?.replace(/^0+/, '')
          )
        }
      }}
      render={(fieldProps) => <Input {...fieldProps} />}
    />
  )
}

export const MobilePhoneCountryCode = ({ t }) => (
  <FormField
    testId="mobilePhoneCountryCode"
    type="select"
    name="contactData.mobilePhoneCountryCode"
    labelText={t('contact-details:mobilePhoneCountryCode')}
    render={(fieldProps) => (
      <Select values={phoneCountryCodes} {...fieldProps}>
        {phoneCountryCodes.map(({ value, label }) => (
          <Select.Item key={`${value}${label}`} value={value}>
            {label}
          </Select.Item>
        ))}
      </Select>
    )}
  />
)

export const CompanyName = ({ t, index }) => (
  <FormField
    testId="companyName"
    type="input"
    name={`addresses[${index}].companyName`}
    labelText={t('contact-details:companyName')}
    render={(fieldProps) => <Input {...fieldProps} />}
  />
)
