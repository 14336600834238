import axios from 'axios'
import * as actions from '../../redux/features/storefront.redux'
import * as customActions from '../../redux/features/custom.redux'

import { store } from '../../redux'

const { REACT_APP_BACKEND_BRAND, REACT_APP_API_GATEWAY } = process.env

export const getStorefrontData = (storefrontId: string) => {
  const country = 'de'
  const url = `${REACT_APP_API_GATEWAY}/${country}/${REACT_APP_BACKEND_BRAND}/journey/${storefrontId}`
  return axios.get(url, {
    validateStatus: (statusCode) => statusCode === 200,
  })
}

export const loadStorefrontDataToRedux = (cid: string) => {
  store.dispatch(actions.storeId(cid))
  return internalLoadStorefrontDataToRedux(cid)
}
export const savePrefillData = (data) => {
  if (data?.custom?.lastFormSectionCompleted) {
    store.dispatch(
      customActions.setContinueJourney(data?.custom?.lastFormSectionCompleted)
    )
  }
  return store.dispatch(actions.initial(data))
}
const internalLoadStorefrontDataToRedux = (cid: string) => {
  store.dispatch(actions.pending())
  return getStorefrontData(cid)
    .then((response) => {
      return response.data
    })
    .then((data) => {
      return savePrefillData(data)
    })
}
