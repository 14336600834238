import axios from 'axios'

export const logOut = async ({
  token,
  clientId,
  clientSecret,
  refreshToken,
}) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const data = {
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: refreshToken,
  }

  const domain = process.env.REACT_APP_IDP_URL
  const url = `${domain}/realms/vwfs-du-rentde/protocol/openid-connect/logout`

  return axios.post(url, new URLSearchParams(data), { headers })
}