import i18n from 'i18next'
import * as Yup from 'yup'

const ValidationSchema = (tlsValid, dataPrivacyConsentAcceptance) => {
  return Yup.object().shape({
    termsAgreeCheckAgree: Yup.string()
      .validateTermsAgree(tlsValid, dataPrivacyConsentAcceptance, i18n.t('validation:termsAgreeError'))
  })
}

export default ValidationSchema
