export function returnAvailableValues({
  paths,
  defaultValue = '',
  formater,
}: {
  paths: {
    formData?: any
    keycloakData?: any
    storefrontData?: any
  }
  defaultValue?: any
  formater?: (field) => string
}) {
  if (formater) {
    const value = paths.formData || paths.storefrontData || paths.keycloakData

    return formater(value) || defaultValue
  }

  return (
    paths.formData || paths.storefrontData || paths.keycloakData || defaultValue
  )
}

// Convert YYYY-MM-DD to DD.MM.YYYY
export const formatDate = (input: string): string => {
  if (!input) {
    return input
  }

  const regex = /^\d{4}-\d{2}-\d{2}$/

  if (!regex.test(input)) {
    return input
  }

  const [year, month, day] = input.split('-')
  const date = new Date(`${month}/${day}/${year}`)

  const formattedDay = date
    .getDate()
    .toString()
    .padStart(2, '0')
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0')

  return `${formattedDay}.${formattedMonth}.${date.getFullYear()}`
}

export const getErrorFields = (errors: any) => {
  const getKeys = obj =>
    Object.keys(obj).flatMap(k =>
      Object(obj[k]) === obj[k] ? [...getKeys(obj[k])] : k
    )
  return getKeys(errors).join('; ')
}
