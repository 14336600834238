import i18n from 'i18next'
import * as Yup from 'yup'
import { DATE_FORMAT, DATE_SEPARATOR } from '../../../config'
import { formatDate } from '../utils'

const regExText = /^[^\s][\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšßẞ.'-]*$/

const getValidationSchema = storefrontData => {
  const storefrontMinAge = storefrontData?.vehicleData?.minAge
  const minAllowedAge = storefrontMinAge > 18 ? storefrontMinAge : 18

  return Yup.object().shape({
    additionalDrivers: Yup.array().of(
      Yup.object()
        .shape({
          firstName: Yup.string()
            .matches(regExText, i18n.t('validation:invalidCharacter'))
            .min(2, i18n.t('validation:minLength', { value: 2 }))
            .max(35, i18n.t('validation:maxLength', { value: 35 }))
            .required(i18n.t('validation:required')),
          lastName: Yup.string()
            .matches(regExText, i18n.t('validation:invalidCharacter'))
            .min(2, i18n.t('validation:minLength', { value: 2 }))
            .max(35, i18n.t('validation:maxLength', { value: 35 }))
            .required(i18n.t('validation:required')),
          dateOfBirth: Yup.string()
            .formatOfDate(
              DATE_FORMAT,
              DATE_SEPARATOR,
              i18n.t('validation:invalidDateformat')
            )
            .notInFuture(DATE_SEPARATOR, i18n.t('validation:dateInFuture'))
            .notMoreThanHundredYearsAgo(
              DATE_SEPARATOR,
              i18n.t('validation:dateMoreThanHundredYearsAgo')
            )
            .fullAge(
              minAllowedAge,
              DATE_SEPARATOR,
              i18n.t('validation:minAge', {
                minAge: minAllowedAge,
              })
            )
            .required(i18n.t('validation:required')),
        })
        .test(
          'matches-main-driver',
          i18n.t('validation:driverIsMainDriver'),
          value => {
            const prohibitedDriver = {
              firstName: storefrontData?.customerData?.firstName,
              lastName: storefrontData?.customerData?.lastName,
              dateOfBirth: formatDate(
                storefrontData?.customerData?.dateOfBirth
              ),
            }
            return (
              value.firstName !== prohibitedDriver.firstName ||
              value.lastName !== prohibitedDriver.lastName ||
              value.dateOfBirth !== prohibitedDriver.dateOfBirth
            )
          }
        )
    ),
  })
}

export default getValidationSchema
