import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ButtonContainer,
  Button,
  ContentSection,
  InstructionStepper,
  Layout,
  Paragraph,
  SectionHeading,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'
import { Analytics } from '../../services/analytics'
import { setActualPage } from '../../services/redux/features/custom.redux'
import { pageName, trackPage, trackRedirectButton } from './tracking'
import { scrollToTop } from '../../services/common/form'

export type ConfirmationPageProps = {
  setActualPage: Function
}

const ConfirmationPage: FunctionComponent<ConfirmationPageProps> = props => {
  const { t } = useTranslation()
  React.useEffect(() => {
    document.title = `${t('confirmation:tabTitle')}`
  }, [t])

  React.useEffect(() => {
    scrollToTop()
    props.setActualPage(pageName)
    Analytics.pageName = pageName
    setTimeout(() => {
      trackPage()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handlePortal = () => {
    window.location.href = t('confirmation:buttonPortal:link')
  }
  const handleRedirect = () => {
    trackRedirectButton()
    window.location.href = t('confirmation:buttonRedirect:link')
  }
  return (
    <ContentSection pageWrap>
      <div className="u-text-center">
        <h1>
          <i className="c-icon c-icon--[semantic-success] u-text-success u-giga" />
        </h1>
      </div>
      <SectionHeading level="2" center testId="confirmationHeading" primary>
        {t('confirmation:heading')}
      </SectionHeading>
      <SectionHeading level="3" center testId="confirmationSubHeading">
        {t('confirmation:sub-heading-1')}
      </SectionHeading>
      <InstructionStepper>
        {t('confirmation:instructionStepper', { returnObjects: true}).map((step: any) => (
          <InstructionStepper.Step
            icon={step.icon}
            title={step.title}
          >
            {step.text}
          </InstructionStepper.Step>
        ))}
      </InstructionStepper>
      <Paragraph testId="confirmationSubHeading2" className="u-mt">
        <strong>{t('confirmation:sub-heading-2')}</strong>
      </Paragraph>
      <Paragraph testId="confirmationText">{t('confirmation:text')}</Paragraph>
      <Layout center>
        <Layout.Item default="1/3" s="1/1">
          <ButtonContainer center vertical stretch className="u-mt">
            <Button
              title={t('confirmation:buttonPortal:text')}
              onClick={handlePortal}
            >
              {t('confirmation:buttonPortal:text')}
            </Button>
            <Button
              title={t('confirmation:buttonRedirect:text')}
              onClick={handleRedirect}
            >
              {t('confirmation:buttonRedirect:text')}
            </Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </ContentSection>
  )
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { setActualPage })(ConfirmationPage)
