/* eslint-disable no-unused-vars */
export enum LicenseClassType {
  B = 'B',
  TWO = '2',
  THREE = '3',
  OTHER = 'Andere',
}

export enum TruthType {
  TRUE = 'true',
  FALSE = 'false',
}

export enum OauthProviders {
  VW = 'Volkswagen ID',
  AUDI = 'Audi ID',
}

// Enum key is <FrontendBrand_AuthorizationProvider>.
// Enum value is defined by Id Broker
export enum OauthProvidersHint {
  AUDI_VW = 'idkrentdeaudiaudi',
  VWFS_VW = 'idkrentdevwfs',
}

export enum OauthClientId {
  AUDI = 'custPortalClientAudi',
  VWFS = 'custPortalClientVwfs',
}

export enum Brand {
  AUDI = 'audi',
  VWFS = 'vwfs',
}

export enum LastFormSectionCompleted {
  PERSONAL = 'PERSONAL',
  CONTACT = 'CONTACT',
  DRIVERS = 'DRIVERS',
  PAYMENT = 'PAYMENT',
}

export enum TransactionStatus {
  INITIALIZED = 'INITIALIZED',
  APPLICATIONFORM_STARTED = 'APPLICATIONFORM_STARTED',
}