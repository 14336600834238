import axios from 'axios'
import { store } from '../../../redux'

const { REACT_APP_API_GATEWAY, REACT_APP_BACKEND_BRAND } = process.env

export const storePaymentData = (data): Promise<string> => {
  const country = 'de'
  const dataStorageId = store.getState().storefront.id
  const { minAge, ...storable } = data
  const url = `${REACT_APP_API_GATEWAY}/payment/${country}/${REACT_APP_BACKEND_BRAND}/initialize/${dataStorageId}`
  return axios.post(url, storable).then(response => {
    return response.data
  })
}
