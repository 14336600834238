import { Address, AddressType } from '../../../models/AppPage/Contact'
import {
  removeCountryCode,
  extractCountryCode,
} from '../../../services/common/format'
import { returnAvailableValues } from '../utils'

const getInitialValues = ({
  formData,
  keycloakData,
  storefrontData,
  countryCodeList,
}) => {
  const addressesInStorefront: Address[] | undefined =
    storefrontData?.customerData?.addresses

  const hasBusinessAddressInStorefront =
    addressesInStorefront &&
    addressesInStorefront.some(
      (address) => address.addressType === AddressType.BUSINESS
    )

  const formatAddressForValidation = (field) => {
    const newField =
      field &&
      field.map((address) => {
        if (address.addressType === AddressType.PRIMARY) {
          return {
            ...address,
            companyName: '',
            hasCompanyName: false,
            zipCodeSelected: !address?.isManual,
            streetSelected: !address?.isManual,
          }
        }

        if (address.addressType === AddressType.BUSINESS) {
          return {
            ...address,
            hasCompanyName: true,
            zipCodeSelected: !address?.isManual,
            streetSelected: !address?.isManual,
          }
        }

        return address
      })

    return newField
  }

  const phoneNumberFromKeycloak =
    keycloakData?.customerData?.contactData?.fullPhoneNumber

  const phoneNumberFromKeycloakCountryCode =
    keycloakData?.customerData?.contactData?.fullPhoneNumber &&
    extractCountryCode(phoneNumberFromKeycloak, countryCodeList)

  const phoneNumberFromKeycloakNumber =
    phoneNumberFromKeycloak &&
    removeCountryCode(phoneNumberFromKeycloak, countryCodeList)

  return {
    addresses: returnAvailableValues({
      paths: {
        formData: formData?.contactDetails?.addresses,
        storefrontData: storefrontData?.customerData?.addresses,
      },
      defaultValue: [initalAdressFields],
      formater: (field) => formatAddressForValidation(field),
    }),

    contactData: {
      mobilePhoneCountryCode: returnAvailableValues({
        paths: {
          formData:
            formData?.contactDetails?.contactData?.mobilePhoneCountryCode,
          storefrontData:
            storefrontData?.customerData?.contactData?.mobilePhoneCountryCode,
          keycloakData: phoneNumberFromKeycloakCountryCode,
        },
        defaultValue: '+49',
      }),

      mobilePhoneNumber: returnAvailableValues({
        paths: {
          formData: formData?.contactDetails?.contactData?.mobilePhoneNumber,
          storefrontData:
            storefrontData?.customerData?.contactData?.mobilePhoneNumber,
          keycloakData: phoneNumberFromKeycloakNumber,
        },
      }),

      email: returnAvailableValues({
        paths: {
          formData: formData?.contactDetails?.contactData?.email,
          storefrontData: storefrontData?.customerData?.contactData?.email,
          keycloakData: keycloakData?.customerData?.contactData?.email,
        },
      }),
    },

    showBusinessAddress:
      formData?.contactDetails?.showShippingAddress ||
      hasBusinessAddressInStorefront ||
      false,
  }
}

export default getInitialValues

export const initalAdressFields: Address = {
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  countryCode: 'DE',
  state: undefined,
  addressType: AddressType.PRIMARY,
  hasCompanyName: undefined,
  companyName: '',
  isManual: false,
}
