import axios from 'axios'
import { store } from '../../../redux'
import * as actions from '../../../redux/features/storefront.redux'
import { ContactBody } from '../../../../models/AppPage/Contact'

const { REACT_APP_API_GATEWAY, REACT_APP_BACKEND_BRAND } = process.env

export const storeContactData = (data: ContactBody): Promise<string> => {
  const country = 'de'
  const dataStorageId = store.getState().storefront.id
  const url = `${REACT_APP_API_GATEWAY}/contact/${country}/${REACT_APP_BACKEND_BRAND}/journey/${dataStorageId}`
  return axios.put(url, data).then(response => {
    // Update Redux state with the data returned from the service.
    store.dispatch(actions.done(response.data.data))
    return response.data
  })
}
