import axios from 'axios'
import { store } from '../../../redux'
import { AdditionalDrivers } from '../../../../models/AppPage/AdditionalDrivers'

const { REACT_APP_API_GATEWAY, REACT_APP_BACKEND_BRAND } = process.env

export const storeAdditionalDriversData = (
  data: AdditionalDrivers
): Promise<string> => {
  const country = 'de'
  const dataStorageId = store.getState().storefront.id
  const url = `${REACT_APP_API_GATEWAY}/additional-drivers/${country}/${REACT_APP_BACKEND_BRAND}/journey/${dataStorageId}`

  return axios.patch(url, data).then(response => {
    return response.data
  })
}
