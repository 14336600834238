import React, { FunctionComponent } from 'react'

import {
  FoldOut,
  ProductInfo,
  ProductInfoSummary,
  FloatingBar,
  Layout,
  Button,
} from '@vwfs-bronson/bronson-react'
import { ShoppingCartModel } from './ShoppingCartModel'
import { useTranslation } from 'react-i18next'

const scrollRef: any = React.createRef()

const ShoppingCart: FunctionComponent<ShoppingCartModel> = ({
  id,
  foldout,
  floatingBar,
  imgSrc,
  imgSrcSet,
  imgSizes,
  title,
  titleSuffix,
  subtitle,
  infoLabel,
  infoValue,
  children,
  subtitleImg,
  storefrontRedirectURL,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div ref={scrollRef}></div>
      {foldout ? (
        <FoldOut
          id={id}
          productInfoSummary={
            <ProductInfoSummary
              imgSrc={imgSrc}
              imgSrcSet={imgSrcSet}
              imgSizes={imgSizes}
              imgAlt={title}
              title={title}
              subtitle={subtitle}
              infoLabel={infoLabel}
              infoValue={infoValue}
            />
          }
        >
          <Layout className="row" center>
            <Layout.Item default={`${storefrontRedirectURL ? "1/2" : "2/2"}`} s="1/1">
              <h3 className="c-product-info__title">{title}</h3>
            </Layout.Item>
            {storefrontRedirectURL && (
              <Layout.Item default="1/2" s="1/1" className="u-text-right@s-up">
                <Button
                  element="a"
                  href={storefrontRedirectURL}
                  icon="semantic-edit"
                  link
                  simple
                >
                  {t('form:shoppingCart:goBackButton')}
                </Button>
              </Layout.Item>
            )}
          </Layout>
          <ProductInfo
            caption={subtitleImg}
            titleSuffix={titleSuffix}
            media={<img src={imgSrc} />}
          >
            {children}
          </ProductInfo>
        </FoldOut>
      ) : (
        <ProductInfo
          title={title}
          titleSuffix={titleSuffix}
          media={<img src={imgSrc} />}
          caption={subtitle}
        >
          {children}
        </ProductInfo>
      )}
      {floatingBar ? (
        <FloatingBar target={scrollRef}>
          <ProductInfoSummary
            imgSrc={imgSrc}
            imgAlt={title}
            title={title}
            subtitle={subtitle}
            infoLabel={infoLabel}
            infoValue={infoValue}
          />
        </FloatingBar>
      ) : null}
    </>
  )
}

export { ShoppingCart }
