import get from 'lodash/get'
import { Analytics } from './index'
import {
  getAnalyticsDate,
  calculateDurationDays,
} from '../../services/common/data'

export function pushStorefrontDataToDatalayer(storefrontData) {
  const contractAmount = get(
    storefrontData,
    'financialProduct.rentalPriceTotal.rentalTotalCostGrossAmount'
  )
  const dateFrom = get(storefrontData, 'custom.contract.startDate')
  const dateTo = get(storefrontData, 'custom.contract.endDate')
  const duration = calculateDurationDays({ dateFrom, dateTo })
  return Analytics.addToDatalayer({
    'core.attributes.brand': process.env.REACT_APP_WEBSITE_BRAND,
    'product[0].attributes.startDateOfContract': getAnalyticsDate(
      get(storefrontData, 'custom.contract.startDate')
    ),
    'product[0].attributes.endDateOfContract': getAnalyticsDate(
      get(storefrontData, 'custom.contract.endDate')
    ),
    'product[0].attributes.contractMileage': get(
      storefrontData,
      'financialProduct.includedMileage'
    ),
    'product[0].attributes.contractAmount': contractAmount,
    'product[0].attributes.duration': duration,
    'product[0].vehicleModel[0].manufacturer': get(
      storefrontData,
      'vehicleData.vehicleBrand'
    ),
    'product[0].vehicleModel[0].descriptionLong': get(
      storefrontData,
      'vehicleData.vehicleDescription'
    ),
    'product[0].vehicleModel[0].name': get(
      storefrontData,
      'vehicleData.vehicleModel'
    ),
    'dealerData.companyId': get(storefrontData, 'companyData.stationId'),
    'dealerData.companyName': get(storefrontData, 'companyData.name'),
    'dealerData.address.street': get(
      storefrontData,
      'companyData.address.street'
    ),
    'dealerData.address.zipCode': get(
      storefrontData,
      'companyData.address.zipCode'
    ),
    'dealerData.address.city': get(storefrontData, 'companyData.address.city'),
  })
}
