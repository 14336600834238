export const DEFAULT_DATALAYER = {
  core: {
    /*
        Please put here the stage (DEV, INT, CONS, PROD) on which this site is
        */
    stagingEnvironment: null,

    dataLayerVersion: '2.8',

    pageInfo: {
      /*
            The values for pagename are defined in tracking specification
            document.
            */
      pageName: null,

      /*
            The device type the website is intended for.
            Type: Enum
            Mobile phone
            Tablet
            Desktop
            all
            */
      intendedCustomerDeviceType: 'all',

      /*
            Version or release of the site. Constant value of your choice
            which identifies best this current application version.
            */
      version: 'Rel. 1.0',

      /*
            Release date of this site as a constant value. Format YYYY-MM-DD
            */
      releaseDate: '2021-12-15',

      /*
            Current display language.
            Type: Enum
            ISO-639-2; lower case two letter code
            Hint: Visitor can change the language while his visit (e.g. in
            Switzerland).
            */
      language: 'de',

      /*
            Type: Enum
            The country name of the market this site is serving
            (e.g. DE: W&I -> DE, UK: S&I -> UK).
            ISO-3166-1-alpha-2; upper case two letter code
            */
      market: 'DE',

      /*
            Type: Enum
            Name of responsible department (DU, local-DE, local-UK)
            */
      publisher: 'DU',
    },
    category: {
      /*
            Main category of this site.
            Type: Enum
            Banking
            Charge & fuel
            Customer portal
            Digital renewal
            Financing
            Fleet
            fob
            Insurance
            Leasing
            Local customer portal
            Local portal
            Local public site
            NBW
            Rental
            Service
            */
      primaryCategory: 'rental',

      /*
            Please further describe your journey/site *if necessary*. The
            product owner can choose a unique name.
            Examples:
            Champions Club
            OperateLease
            Short term insurance
            Service and inspection
            AutoCredit & ClassicCredit
            */
      secondaryCategory: 'rental',

      /*
            If your journey splits in sub processes, standalone, checkout site,
            app, ...
            Type: Enum
            app
            checkoutSite
            standalone
            */
      siteType: 'checkoutSite',

      /*
            Asset-based products
            Non-Asset-based products
            Other products
            */
      inventoryType: 'Other products',

      /*
            Classification of the result which should be achieved by the journey
            Type: Enum
            Lead
            Online contract
            */
      maturityLevel: 'Online contract',
    },
    attributes: {
      /*
            Type: Enum
            customer-facing-product-journey
            customer-facing-journey
            dealer-facing-product-journey
            dealer-facing-journey
            after-sales-journey
            */
      journeyType: 'customer-facing-product-journey',

      /*
            If your page contains expandables, which once expanded do not
            change the URL. Example: On page personal data, there are
            expandables for address, account details, ... The values are
            specified in the corresponding specification document.
            */
      viewChange: null,

      /*
            Refers to the branding of the site.

            Never include design versions here: This should not be changed to
            vw6 when a new design is released. The brand is still vw.
            vwcv refers to VW Commercial Vehicles. BFF’s data model supplies
            vwn, but we expect vwcv.

            @Developers: Please fill in the correct value here based on the
            site's
            branding

            Type: Enum
            Audi
            Bentley
            Cupra
            Ducati
            Lamborghini
            MAN
            Porsche
            Scania
            Seat
            Skoda
            VW
            VWCV
            VWFS
            */
      brand: null,

      /*
            @Developers:
            Acceptance criteria:
            - If the viewChange relates to a selection of a FAQ please out the
            question text (not the answer text) here

            */
      faqText: null,
    },
  },
  /*
    In case of any error situations (mainly standard errors like 401, 404, ...
    and in context of forms) an error code (if available) and the error message
    (if possible a shortened version) are placed here. If available the causing
    URL (e.g. the wrong URL in case of 404, the referring URL in case 401, ...)
    should be placed here, too.
    */
  error: {
    // Error code of application exceptions or caught errors
    errorCode: null,

    // Error message for caught errors with available message
    errorMessage: null,

    // Full URL which caused the error
    errorCausingURL: null,
  },

  /*
    This structure is filled as soon as the user provides information
    regarding any product.

    Please note that this structure is an array structure. If the customer can
    bundle his contract (i.e. get a leasing contract, a service and maintenance
    package, and a tire package, this array should have three entries. Same is
    e.g. in Customer portal if customer has more than one product.
    */
  product: [
    {
      // Station-ID
      productID: null,

      /*
        The main category of the current contract.
        Type: Enum
        Finance
        Insurance
        Leasing
        Service
        Other
        */
      category: 'rental',

      /*
        The name of the product from the given list. If you cannot find a
        suitable entry for the product sold in the journey, please reach out
        to the web analytics team. Please do not add any products to this list
        on your own.

        Type: Enum
        AutoCredit
        CarSharingProtection
        ChargeAndFuel
        ClassicCredit
        Credit
        CreditProtectionInsurance
        LeasingRateInsurance
        DigitalRenewal
        ExtendedWarranty
        FinanceLease
        GuaranteedAssetProtection
        MotorInsurance
        OperateLease
        RentalCarProtection
        ServiceInspection
        ServiceLimitedMaintenance
        TestDriveProtection
        ThirdPartyProtection
        UsedCarWarranty
        TirePackage
        */
      name: 'rental',

      /*
        Additional options which the customer has chosen
        E.g. Glass breakage insurance, credit rate insurance, tyre insurance
        */
      productAddons: [],

      attributes: {
        /*
            Specifies the type of complete sales process

            Type: Enum
            Hybrid sales
            Online sales
            Partner sales
            */
        typeOfSale: 'Online sales',

        /*
            Unit for payment frequency.
            Type: Enum
            single payment
            weekly payment
            biweekly payment
            monthly payment
            bimonthly payment
            quarterly payment
            biannual payment
            annual payment
            */
        paymentFrequency: 'single payment',

        /*
            The currency unit used for displayed prices (regular the local
            currency). The value is taken from ISO-Code 4217.
            */
        currency: 'EUR',

        /*
            E.g. monthly payment for insurance or leasing contract or an
            installment. Please provide exact (float) value
            Type: Float
            The currency is the local currency.
            The value is stored without currency unit.
            */
        recurringPayment: null,

        /*
            Planned duration of contract; please use value -1 if indefinite
            Type: Integer
            */
        duration: null,

        /*
            Unit for planned duration of contract.
            Type: Enum
            DAY
            WEEK
            TWO-WEEKS
            MONTH
            TWO-MONTHS
            QUARTER
            SIX-MONTHS
            YEAR
            */
        durationUnit: 'DAY',

        /*
            The start date of contract in focus.
            Type: Date (YYYY-MM-DD)
            */
        startDateOfContract: null,

        /*
            The end date of contract in focus.
            Type: Date (YYYY-MM-DD)
            */
        endDateOfContract: null,

        /*
            For contracts with a term of significantly less than a year
            Type: Integer
            */
        contractMileage: null,

        /* The unit of yearlyMileage, excessMileage or undrivenMileage.
            Should always be KILOMETERS in the european region.
            Type: Enum
            KILOMETERS,
            MILES
            */
        mileageUnit: 'KILOMETERS',

        /*
            The type of payment.

            Type: Enum

            Automatic Bank Transfer
            Credit Card
            Direct Deposit
            E-Wallet
            Mobile Payment
            */
        paymentType: null,

        /*
            The final amount customer has to pay to fulfill the contract.
            Type: Float
            The currency is the local currency.
            The value is stored without currency unit.
            */
        contractAmount: null,
      },
      // Aligned with BFF data model!
      vehicleModel: [
        {
          /*
            The manufacturer of the current vehicle.
            Type: Enum
            audi
            bentley
            cupra
            ducati
            man
            porsche
            scania
            seat
            skoda
            vw
            vwcv
            vwfs
            */
          manufacturer: null,

          /*
            The main name of the current car.

            Type: string, for example
            A3
            Kodiaq
            XC60
            Auris
            Golf
            */
          name: null,

          /*
            The first subcategory of the current car in manufacturers product
            program.
            Type: string
            For example
            GTI
            */
          modelVariation: null,

          /*
            The year when the model has been released.
            Type: string
            Format: YYYY
            */
          year: null,

          /*
            The second subcategory of the current car in manufacturers product
            program.
            Type: string
            For example
            Sportline
            Momentum
            Sportback
            Performance
            */
          modelLine: null,

          /*
            Long description of the vehicle model
            Type: String
            Example:
            Golf GTI Performance
            A3 Sportback 1.5 TFSI Black Edition
            */
          descriptionLong: null,

          /*
            Describes the body type of the vehicle.
            Type: Enum
            CONVERTIBLE
            COUPE
            CROSSOVER
            CABRIO
            LUXURY CAR
            SEDAN
            SPORTS CAR
            SUV
            TRUCK
            VAN
            WAGON
            */
          bodyType: null,

          /*
            The base color of the current vehicle

            Enum:
            Black
            Blue
            Brown
            Green
            Grey
            Red
            Violet
            White
            Yellow
            */
          colorExterior: null,

          /*
            The type of usage

            Enum:
            Private
            Business
            Both
            */
          typeOfUse: 'Both',

          engine: {
            /*
                The engines fuel type of the vehicle

                Enum:
                CNG
                Diesel
                Electric
                Hybrid
                Hydrogen
                LPG
                Petrol
                */
            fuelType: null,

            /*
                The emission value of the engine.
                Format: <nnn> g/km
                */
            emission: null,

            /*
                Transmission; e.g.
                6-speed manual
                7-speed automatic
                */
            transmission: null,

            /*
                The engines power in both units: PS and kW. E.g.
                115 PS (84 kW)
                */
            enginePower: null,
          },
        },
      ],
    },
  ],
  // Aligned with BFF data model!
  dealerData: {
    /*
    The 5-digit company id which uniquely identifies the car dealer within
    the VW ecosystem.
    Type: string
    */
    companyId: null,

    /*
    The company name of the car dealer.
    Type: string
    */
    companyName: null,

    /*
    The 3-digit region id allows to group car dealers in the VW ecosystem
    by regions. The region id should be sent with every transaction to
    correctly assign transactions to regional systems.
    Type: string
    */
    regionId: null,

    /*
    The sales persons internal ID.
    Type: string
    */
    KVPS: null,
    address: {
      // Dealers address
      street: null,
      // The postal code of dealer’s address.
      zipCode: null,
      // The city of the address.
      city: null,
      /* The region the dealer is located. In Germany this would be the
        federal state.
        */
      state: null,
    },
  },
  form: {
    /* The main subject of the form. Search forms are handled by the search
        element of this data layer.
        */
    type: null,

    /* The meaningful descriptive name of the form in context of the current
        site.
        */
    name: null,

    /* If required content of form fields are included here. Syntax:
        <shortened field name1>: <value1> | <shortened field name2>: <value2>
        Field names should be in English.
        */
    fieldValues: null,

    // If the form is aborted the meaningful name of last touched field.
    lastTouchedField: null,

    /* In case of user input errors, a semicolon-/whitespace separated
        list of meaningful shortened names of all erroneous fields.
        Example:
        Plate; Zip
        */
    errorFields: null,
  },
  design: {
    /*
        For responsive design: Thresholds when design changes. The values are
        the Bronson breakpoints:
        xxl   (browser tab width >= 1920 px)
        xl    (browser tab width >= 1600 px)
        l     (browser tab width >= 1280 px)
        m     (browser tab width >=  960 px)
        s     (browser tab width >=  720 px)
        xs    (browser tab width >=  480 px)
        Type: string
        */
    browserResolutionBreakpoint: null,
  },
  customerData: {
    /*
        Indicates whether the user is currently logged in or not
        Type: Boolean
        */
    loginStatus: null,

    /*
        The user group the customer belongs to. Specified values are:
        - private
        - business (small)
        - business (corporate)
        - business
        (the latter applies if there is no differentiation according to
        company size)
        If there is no data available the value is
        - not available
        */
    loggedInUserGroup: null,

    /*
        The year the customer was born.

        Format: YYYY
        */
    yearOfBirth: null,

    /*
        The gender of the customer
        Type: Enum
        MALE
        FEMALE
        */
    gender: null,
    address: {
      /* If the customer provides multiple addresses, we expect the zip
            code of latest address here.
            */
      zipCode: null,
    },
  },
  targeting: {
    /*
        For real-time purposes in the context of A/B tests, identifier of Adobe
        Target experience.
        Type: string
        */
    pageTargetVersion: null,

    /*
        Micro steps of the customer journey, e.g.
        landing | calculator | personalData | payment | success
        Each time a new page is visited, add the page name to this array.
        If a page is visited more than one time, (Page A, page B, Page A, …),
        please attach page A again.
        Type: string

        Example:
        Customer on index.html
        Data layer value:
        ['landing']
        Example:
        Customer subsequently visits calculator page
        Data layer value:
        ['landing', 'calculator']
        */
    completeVisitPath: [],
  },
  // Aligned with BFF data model!
  dataPrivacyStatement: {
    /*
        If the customer allows a contact via postal the value is "Postal".
        When distinguishing between contact partners please use the
        descriptive term as Suffix (enclosed in round brackets).
        Example:
        Postal
        Postal (VWFS)
        Postal (Business partner)
        Type: String
        */
    allowPostalAds: null,

    /*
        If the customer allows a contact via phone the value is "Phone".
        When distinguishing between contact partners please use the
        descriptive term as Suffix (enclosed in round brackets).
        Example:
        Phone
        Phone (VWFS)
        Phone (Business partner)
        Type: String
        */
    allowPhoneAds: null,

    /*
        If the customer allows a contact via Electronic channels the value is
         "Electronic". When distinguishing between contact partners please use
        the descriptive term as Suffix (enclosed in round brackets).
        Example:
        Electronic
        Electronic (VWFS)
        Electronic (Business partner)
        Type: String
        */
    allowElectronicAds: null,

    /*
        If the customer allows a contact via mail the value is "Mail".
        When distinguishing between contact partners please use the
        descriptive term as Suffix (enclosed in round brackets).
        Example:
        Mail
        Mail (VWFS)
        Mail (Business partner)
        Type: String
        */
    allowMailAds: null,

    /*
        If the customer allows a delivery of Invoices via electronic
        applications the value is "Electronic invoices".
        Type: String
        */
    allowElectronicInvoices: null,
  },
  /*
    Together with the PO or BA we map our standard events to your journey and
    provide you a specification document with an annotated version of the
    journey. Please fill use the values specified in this document.
    */
  event: [
    {
      eventInfo: {
        /*
            The type of event which occurred. This information describes the
            context in which the current event occurred. The values are
            specified in the corresponding specification document.
            */
        eventType: null,

        /*
            The event which occurred. The values are specified in the
            corresponding specification document.
            */
        eventAction: null,

        /*
            If a link or button triggers this event, please provide the target
            url of this link.
            */
        eventTargetURL: null,

        /*
            If additional information in context of an interaction element
            is needed. The values are specified in the corresponding
            specification document.
            */

        linkInformation: null,
      },
    },
  ],
}
