import React, { useEffect, useRef, useState } from 'react'
import { string, func } from 'prop-types'

const SCRIPT_ID = 'smartpay'
const SELECTOR_CONTAINER_ID = 'selector-container'

export function SmartPay({
  sdkUrl,
  transactionId,
  onBeforeSubmit,
  onError,
  onSuccess,
  customSubmitButtonId,
}) {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const onSuccessRef = useRef(onSuccess)
  const onErrorRef = useRef(onError)

  // values (onSuccess, onError) are updated every re-render of the component

  onSuccessRef.current = onSuccess
  onErrorRef.current = onError

  function handleError(errorCode, error) {
    onErrorRef.current(errorCode, error)
  }

  function handleSuccess(data) {
    onSuccessRef.current(data)
  }

  function renderPaymentSelection() {
    const app = window.SmartPaySubscriptionsSdk
    app.renderWidget({
      containerId: SELECTOR_CONTAINER_ID,
      submitButtonContainerId: customSubmitButtonId,
      objectId: transactionId,
      objectType: 'payment-series',
      onBeforeSubmit,
      successHandler: handleSuccess,
      errorHandler: handleError,
    })
  }

  function loadSmartpayScript() {
    const script = document.createElement('script')
    script.id = SCRIPT_ID
    script.src = sdkUrl
    script.async = true
    script.onload = handleScriptLoad
    document.body.appendChild(script)
  }

  function removeSmartpayScript() {
    const script = document.getElementById(SCRIPT_ID)
    if (script) {
      document.body.removeChild(script)
    }
  }

  function handleScriptLoad() {
    setScriptLoaded(true)
  }

  useEffect(() => {
    if (window.SmpLibrary && scriptLoaded && transactionId) {
      renderPaymentSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, transactionId])

  useEffect(() => {
    loadSmartpayScript()

    return () => {
      removeSmartpayScript()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id={SELECTOR_CONTAINER_ID}></div>
}

SmartPay.defaultProps = {
  sdkUrl: process.env.REACT_APP_SMARTPAY_WIDGET_URL,
  onError: () => {},
  onSuccess: () => {},
  customSubmitButtonId: undefined,
}

SmartPay.propTypes = {
  sdkUrl: string,
  onError: func,
  onSuccess: func,
  transactionId: string,
  customSubmitButtonId: string,
}
