import { KeycloakState } from '../models/KeycloakState'
import {
  KeycloakActions,
  SET_KEYCLOAK_DATA,
  SET_KEYCLOAK_IDP_HINT,
  SET_KEYCLOAK_TOKENS,
} from './actions/keycloak.actions'

export function setKeycloakTokens(data): KeycloakActions {
  return {
    type: SET_KEYCLOAK_TOKENS,
    payload: { data },
  }
}
export function setKeycloak(data): KeycloakActions {
  return {
    type: SET_KEYCLOAK_DATA,
    payload: data,
  }
}

export function setKeycloakIdpHint(idpHint): KeycloakActions {
  return {
    type: SET_KEYCLOAK_IDP_HINT,
    payload: idpHint,
  }
}

export function getKeycloak(store) {
  return store.keycloak.data
}

export function getKeycloakIdpHint(store) {
  return store.keycloak.idpHint
}

export function getKeycloakTokens(store) {
  return store.keycloak.tokens
}
export function getKeycloakIdpSub(store) {
  return store.keycloak.data.idpSub
}
const initialState: KeycloakState = {
  idpHint: '',
  data: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_KEYCLOAK_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      }

    case SET_KEYCLOAK_IDP_HINT:
      return {
        ...state,
        idpHint: action.payload,
      }
    case SET_KEYCLOAK_TOKENS:
      return {
        ...state,
        tokens: action.payload,
      }

    default:
      return state
  }
}
