import validation from './validation.json'
import contact from './contact.json'
import app from './app.json'
import confirmation from './confirmation.json'
import wizard from './wizard.json'
import error from './error.json'
import oauth from './oauth.json'
import loading from './loading.json'
import form from './form.json'
import personalDetails from './personal-details.json'
import contactDetails from './contact-details.json'
import deliveryDetails from './delivery-details.json'
import summary from './summary.json'
import navigationBlockedModal from './navigation-blocked-modal.json'
import landingVw from './landing-vw.json'
import discount from './discount.json'
import paymentDetails from './payment-details.json'
import additionalDrivers from './additional-drivers-detail.json'

export const i18n = {
  validation,
  contact,
  app,
  confirmation,
  wizard,
  error,
  oauth,
  landingVw,
  loading,
  form,
  'personal-details': personalDetails,
  'contact-details': contactDetails,
  'delivery-details': deliveryDetails,
  'payment-details': paymentDetails,
  summary,
  navigationBlockedModal,
  discount,
  'additional-drivers': additionalDrivers,
}
