import React from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Fieldset, Form, Spinner } from '@vwfs-bronson/bronson-react'
import cloneDeep from 'lodash/cloneDeep'
import getInitialValues from './initial-values'
import renderFieldset from './fieldset'
import getValidationSchema from './validation-schema'
import {
  scrollToElement,
  isValidationDisabled,
  scrollToActiveFormSection,
} from '../../../services/common/form'
import { getDataModels } from '../../../services/redux/features/data-models.redux'
import { isAudi, scrollElWithDelay } from '../../../services/common/utils'

import {
  getKeycloak,
  getKeycloakIdpHint,
  getKeycloakTokens,
  setKeycloak,
} from '../../../services/redux/features/keycloak.redux'

import {
  saveFormData,
  getFormData,
} from '../../../services/redux/features/form.redux'
import { resetOpenSectionState } from '../../../components/FormSection'

import { Analytics } from '../../../services/analytics'
import { storeContactData } from '../../../services/api/forms/contact/contact'
import { ContactBody } from '../../../models/AppPage/Contact'
import {
  setTlsValid,
  setActualPage,
} from '../../../services/redux/features/custom.redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import {
  pageName,
  trackAbort,
  trackDirty,
  trackErrors,
  trackPage,
  trackSuccess,
} from './tracking'

const ContactDetails = (props, ref) => {
  const { updateValidSections, hidden } = props

  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getData: () => {
      return formState.current.values
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      props.onValid(formState.current.isValid)
      return formState.current.isValid
    },
  }))
  const { dataModels, formData, storefrontData, keycloakData } = props
  const formState = React.useRef({} as any)
  const [initialValid, setInitialValid] = React.useState<boolean | null>(null)
  const [errors, setErrors] = React.useState(undefined as any)
  const [isloading, setisloading] = React.useState(false)
  const [isDirty, setIsDirty] = React.useState(false)
  const [touched, setTouched] = React.useState(undefined as any)
  const [isSubmitPressed, setIsSubmitPressed] = React.useState(false)
  const [lastTouchedField, setLastTouchedField] = React.useState(undefined as any)
  const fillDatalayer = values => {
    Analytics.addToDatalayer({
      'customerData.address.zipCode': values.addresses[0].zipCode,
    })
  }
  const onSubmit = (values: ContactBody) => {
    const { otherAddress, ...data } = cloneDeep(values)
    const enableRemove = false
    // removing hasCompanyName boolean from request data
    data.addresses.map(address => {
      const addressCopy = address
      delete addressCopy.hasCompanyName
      delete addressCopy.zipCodeSelected
      delete addressCopy.streetSelected
      return addressCopy
    })

    if (!otherAddress && enableRemove) {
      data.addresses.splice(1, 1)
    }
    // removing only boolean var to prevent error case
    delete data.businessAddress
    if (data.addresses[1] && data.addresses[1]?.street === '') {
      data.addresses.splice(1, 1)
    }
    setisloading(true)
    storeContactData(data)
      .then((e: any) => {
        trackSuccess()
        setisloading(false)
        resetOpenSectionState()
        props.setTlsValid(e.tlsSupported)
        updateValidSections('contactDetails', true)
        props.saveFormData({
          ...formData,
          contactDetails: data,
        })
        fillDatalayer(data)
        scrollElWithDelay(scrollToActiveFormSection)
      })
      .catch(() => {
        setisloading(false)
        props.onErrorFetch()
        trackAbort(lastTouchedField)
      })
  }

  const onChange = ev => {
    updateValidSections('contactDetails', false)
    if (!isValidationDisabled(ev.target)) {
      props.onValid(false)
    }
  }

  const onBlur = ev => {
    if (ev && ev.target && ev.target.name) {
      setLastTouchedField(
        ev.target.name.split('.')[ev.target.name.split('.').length - 1]
      )
    }
  }

  React.useEffect(() => {
    if (!hidden) {
      props.setActualPage(pageName)
      trackPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  React.useEffect(() => {
    if (isDirty) {
      trackDirty()
    }
  }, [isDirty])
  React.useEffect(() => {
    window.addEventListener('beforeunload', () => trackAbort(lastTouchedField))
    return () => {
      window.removeEventListener('beforeunload', () =>
        trackAbort(lastTouchedField)
      )
    }
  })

  React.useEffect(() => {
    if (
      errors !== undefined &&
      Object.entries(errors).length !== 0 &&
      errors.constructor === Object
    ) {
      scrollToElement('.is-error', 'start')
    }
  }, [errors, touched])

  const { t } = useTranslation()

  const isInitialValid = formikProps => {
    if (initialValid !== null) {
      return initialValid
    }
    if (getValidationSchema()) {
      const isValid = getValidationSchema().isValidSync(
        formikProps.initialValues
      )
      setInitialValid(isValid)
      return isValid
    }
    return false
  }

  return (
    <>
      {Object.keys(dataModels).length ? (
        <Formik
          enableReinitialize
          isInitialValid={isInitialValid}
          initialValues={getInitialValues({
            formData,
            keycloakData,
            storefrontData,
            countryCodeList: dataModels.phoneCountryCodes,
          })}
          validationSchema={getValidationSchema()}
          onSubmit={values => onSubmit(values)}
          render={formProps => {
            const { values, handleSubmit } = formProps
            setErrors(formProps.errors)
            if (errors && isSubmitPressed) {
              trackErrors(errors, setIsSubmitPressed)
            }
            setTouched(formProps.touched)
            formState.current = { values, isValid: formProps.isValid }
            submitFunction = formProps.submitForm
            setIsDirty(formProps.dirty)
            const handleSubmitOwn = e => {
              setIsSubmitPressed(true)
              setTimeout(() => setIsSubmitPressed(false), 500)
              handleSubmit(e)
            }

            return (
              <Form
                onChange={onChange}
                onSubmit={handleSubmitOwn}
                onBlur={onBlur}
                floatingLabel
              >
                {renderFieldset(
                  t,
                  dataModels,
                  formData,
                  storefrontData,
                  formProps
                )}
                <Fieldset>
                  <Fieldset.Row className="u-text-right u-mt-small">
                    <Button
                      id="contactDetailsButton"
                      testId="contactDetailsButton"
                      type="submit"
                    >
                      {isloading ? (
                        <Spinner section center small>
                          {isAudi() ? <span>&nbsp;</span> : ''}
                        </Spinner>
                      ) : (
                        t('form:btnContinue')
                      )}
                    </Button>
                  </Fieldset.Row>
                </Fieldset>
              </Form>
            )
          }}
        />
      ) : (
        <div className="u-p-xlarge">
          <Spinner section />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    dataModels: getDataModels(state),
    formData: getFormData(state),
    storefrontData: getStorefrontData(state),
    getIdpHint: getKeycloakIdpHint(state),
    getTokens: getKeycloakTokens(state),
    keycloakData: getKeycloak(state),
  }
}

export default connect(
  mapStateToProps,
  { saveFormData, setTlsValid, setActualPage, setKeycloak },
  null,
  {
    forwardRef: true,
  }
)(React.forwardRef(ContactDetails))
