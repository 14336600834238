const getInitialValues = () => {
  return {
    dataPrivacyConsentAcceptance: false,
    selectall: false,
    allowPhoneAds: false,
    allowMailAds: false,
    termsAgreeCheckAgree: 'false',
    termsAgreeCheckDisagree: false,
  }
}

export default getInitialValues
