import i18n from 'i18next'
import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  mitConsent: Yup.boolean()
    .required(i18n.t('validation:MITRequired'))
    .oneOf([true], i18n.t('validation:MITRequired')),
})

export default ValidationSchema
