import { FormState } from '../models/FormState'
import {
  SAVE_FORM_DATA,
  RESET_FORM_DATA,
  FormActions,
} from './actions/form.actions'

export function saveFormData(data): FormActions {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  }
}

export function resetFormData(): FormActions {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getFormData(store) {
  return store.form.data
}

const initialState: FormState = {
  data: {
    additionalDrivers: {},
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      }

    case SAVE_FORM_DATA:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
