import { pushStorefrontDataToDatalayer } from '../../analytics/storefront'
import { StoreFrontState } from '../models/StoreFrontState'
import {
  FETCH_STOREFRONT_PENDING,
  FETCH_STOREFRONT_DONE,
  FETCH_STOREFRONT_ERROR,
  StoreFrontActionTypes,
  FETCH_STOREFRONT_STORE_ID,
} from './actions/storefront.actions'

export function pending(): StoreFrontActionTypes {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  }
}

export function initial(data): StoreFrontActionTypes {
  pushStorefrontDataToDatalayer(data)
  return done(data)
}

export function done(data): StoreFrontActionTypes {
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  }
}

export function error(err): StoreFrontActionTypes {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  }
}

export function storeId(id): StoreFrontActionTypes {
  return {
    type: FETCH_STOREFRONT_STORE_ID,
    payload: {
      id,
    },
  }
}

export function getStorefrontData(store) {
  return store.storefront.data
}

export function getStoreFrontId(store) {
  return store.storefront.id
}

const initialState: StoreFrontState = {
  id: '',
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }
    case FETCH_STOREFRONT_STORE_ID:
      return {
        ...state,
        id: action.payload.id,
      }

    default:
      return state
  }
}
