import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'
import { setActualPage } from '../../services/redux/features/custom.redux'
import { isAudi } from '../../config'
import { trackPage } from './tracking'

export type ErrorPageProps = { entryUrl?: string; isApi?: boolean, setActualPage: Function }

const ErrorPage: FunctionComponent<ErrorPageProps> = props => {
  const { isApi, entryUrl } = props
  const { t } = useTranslation()
  const pageName = isApi ? 'Summary' : 'Error page'

  React.useEffect(() => {
    props.setActualPage(pageName)
    document.title = `${t('error:tabTitle')}`
    trackPage(isApi, entryUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return (
    <ContentSection pageWrap>
      <SectionHeading level="2" center testId="errorHeading">
        {t('error:heading')}
      </SectionHeading>
      <Paragraph testId="errorText" className="u-text-center">
        {t('error:text1')}
      </Paragraph>
      <Paragraph testId="errorText" className="u-text-center">
        {t('error:text2')}
      </Paragraph>
      {!isAudi() && (
        <Paragraph testId="linkErrorText" className="u-text-center">
          <a href={t('error:link:url')} target={t('error:link:url')}>
            {t('error:link:title')}
          </a>
        </Paragraph>
      )}
    </ContentSection>
  )
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, { setActualPage })(ErrorPage)
