import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  DataOverview,
  SectionHeading,
  Spinner,
  ContentSection,
  Paragraph,
  Modal,
  DescriptionList,
} from '@vwfs-bronson/bronson-react'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { getFormData } from '../../services/redux/features/form.redux'
import { StorefrontShoppingCart } from '../../components/ShoppingCart'
import DataProtection from './DataProtection'
import { ArrayDefinitionList } from './ArrayDefinitionList'
import { history } from '../../services/routing'
import { formatNumber, formatPrice } from '../../services/common/format'
import { titles } from '../../resources/base/models/titles'
import { salutationsList } from '../../resources/base/models'
import { countries } from '../../resources/base/models/countries'
import { setActualPage as setActualPageAction } from '../../services/redux/features/custom.redux'
import { isFalsy, isVWFS } from '../../services/common/utils'
import { getFullDate } from '../../services/common/data'
import { pageName, trackPage, trackSubmit } from './tracking'

const SummaryPage = (props) => {
  const { storefrontData: storefnt, formData: form, setActualPage } = props
  const { t } = useTranslation()
  setActualPage(pageName)

  React.useEffect(() => {
    setTimeout(() => {
      trackPage()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEdit = ({ section }) => {
    history.pushPreservePath(
      `/edit/?id=${storefnt.transaction.id}&section=${section}`
    )
  }
  const editButton = (section) => {
    return {
      buttonProps: {
        onClick: (e) => {
          e.preventDefault()
          return handleEdit({ section })
        },
        icon: 'semantic-edit',
        reversed: false,
      },
      buttonLabel: t('form:btnEdit'),
    }
  }
  const closeHandler = () => {
    return true
  }

  const getDeliveryAddress = (companyData) => {
    return `${companyData?.name}, ${companyData?.address?.street} ${companyData?.address?.houseNumber}, ${companyData?.address?.zipCode}`
  }

  const renderTotalPrice = () => {
    return formatPrice(
      storefnt.financialProduct?.rentalPriceTotal?.rentalTotalCostGrossAmount
    )
  }

  const buildExtras = () => {
    const baseExtras = storefnt?.financialProduct?.additionalProducts?.map(
      (additional) => ({
        label: additional?.name,
        value: formatPrice(
          additional?.additionalProductCostAmount?.grossAmount
        ),
      })
    )
    if (
      !isFalsy(storefnt?.financialProduct?.rentalPriceTotal?.addOnsGrossAmount)
    ) {
      baseExtras.push({
        label: t('summary:addonsGrossAmount'),
        value: formatPrice(
          storefnt?.financialProduct?.rentalPriceTotal?.addOnsGrossAmount
        ),
      })
    }
    return baseExtras
  }

  const renderAdditionalDrivers = () => {
    return (
      <ContentSection.ComponentWrapper>
        <DataOverview
          title={t('additional-drivers:title')}
          icon="semantic-checkmark"
          {...editButton(2)}
        >
          {form?.additionalDrivers.map((driver) => {
            return (
              <DescriptionList key={`${driver.firstName}-${driver.lastName}-${driver.dateOfBirth}`} grid>
                <DescriptionList.Group
                  termText={t('additional-drivers:firstName')}
                  key={t('additional-drivers:firstName')}
                >
                  <DescriptionList.Detail>
                    {driver.firstName}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
                <DescriptionList.Group
                  title={t('additional-drivers:lastName')}
                  key={t('additional-drivers:lastName')}
                >
                  <DescriptionList.Detail>
                    {driver.lastName}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
                <DescriptionList.Group
                  title={t('additional-drivers:dateOfBirth')}
                  key={t('additional-drivers:dateOfBirth')}
                >
                  <DescriptionList.Detail>
                    {driver.dateOfBirth}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
              </DescriptionList>
            )
          })}
        </DataOverview>
      </ContentSection.ComponentWrapper>
    )
  }

  const paymentData = useMemo(() =>
    form?.paymentDetails?.paymentData || storefnt?.customerData?.paymentData,
    [form, storefnt]
  )

  const renderPaymentData = () => {
    return (
      <ContentSection.ComponentWrapper>
        <DataOverview
          title={t('payment-details:confirmation')}
          icon="semantic-checkmark"
          {...editButton(form?.additionalDrivers?.length ? 3 : 2)}
        >
          <ArrayDefinitionList>
            {[
              {
                label: t('payment-details:summary:paymentMethodName'),
                value: paymentData?.paymentMethodName,
                hide: false,
              },
              /*  GFTDE-9527
               {
                              label: t('payment-details:summary:name'),
                              value: `${paymentData?.firstName || ''} ${paymentData?.lastName || ''}`,
                              hide: false,
                            }, 
              */
              {
                label: t('payment-details:summary:carrierNumber'),
                value: paymentData?.carrierNumber,
                hide: !paymentData?.carrierNumber,
              },
            ]}
          </ArrayDefinitionList>
        </DataOverview>
      </ContentSection.ComponentWrapper>
    )
  }

  const selectedVehicleText = isVWFS()
    ? `${storefnt.vehicleData?.acrissCodeDescription} (${
        storefnt.vehicleData?.vehicleBrand
      }
      ${storefnt.vehicleData?.vehicleModel} ${t('form:vehicleData:orSimilar')})`
    : `${storefnt.vehicleData?.vehicleDescription}`

  const navigateBack = () => {
    window.location.href = `${storefnt.salesChannel?.storefrontRedirectUrl}`
  }

  return (
    <>
      <Modal
        title={'Output.resolve("pages.summary.initialization.text")'}
        shown={!storefnt}
        onClose={closeHandler}
      >
        <Spinner center />
      </Modal>

      <main>
        <ContentSection>
          <ContentSection.ComponentWrapper>
            <StorefrontShoppingCart foldout floatingBar />
          </ContentSection.ComponentWrapper>
        </ContentSection>
        <ContentSection pageWrap>
          <SectionHeading level="2">{t('summary:title')}</SectionHeading>
          <Paragraph> {t('summary:heading')}</Paragraph>
          <ContentSection.ComponentWrapper>
            <DataOverview
              buttonLabel={
                storefnt.salesChannel?.storefrontRedirectUrl &&
                t('form:shoppingCart:goBackButton')
              }
              buttonProps={{
                onClick: navigateBack,
              }}
              icon="semantic-checkmark"
              title={t('summary:yourChoice')}
            >
              <ArrayDefinitionList>
                {[
                  {
                    label: t('form:vehicleData:selectedVehicle'),
                    value: selectedVehicleText,
                    hide: false,
                  },
                  {
                    label: t('summary:details'),
                    value: [
                      ...(storefnt?.financialProduct?.rentalPriceTotal
                        ?.basePriceGrossAmount
                        ? [
                            {
                              label: t('summary:basePriceGrossAmount'),
                              value: formatPrice(
                                storefnt?.financialProduct?.rentalPriceTotal
                                  ?.basePriceGrossAmount
                              ),
                            },
                          ]
                        : []),
                      {
                        label: t('summary:includedMileage'),
                        value: storefnt?.financialProduct?.includedMileage,
                      },
                      {
                        label: t('summary:excessMileagePrice'),
                        value: `${formatNumber(
                          storefnt?.financialProduct?.excessMileagePriceAmount,
                          2
                        )} ${
                          storefnt?.financialProduct?.excessMileagePriceUnit
                        }`,
                      },
                      {
                        label: t('summary:minimumAge'),
                        value: `${storefnt?.vehicleData?.minAge} ${t(
                          'form:yearsAlt'
                        )}`,
                      },
                      {
                        label: t('summary:minimumLicense'),
                        value: `${
                          storefnt?.vehicleData?.minDurationDriverLicense
                        } ${t('form:years')}`,
                      },
                    ],
                    hide: false,
                  },
                  {
                    label: t('form:vehicleData:receptionStation'),
                    value: getDeliveryAddress(storefnt?.companyData),
                    hide: false,
                  },
                  {
                    label: t('form:shoppingCart:extras'),
                    value: buildExtras(),
                    hide: !storefnt?.financialProduct?.additionalProducts?.[0],
                  },
                  {
                    label: '',
                    value: `   `,
                    hide: storefnt?.financialProduct?.additionalProducts?.[0],
                  },
                  {
                    label: t('form:vehicleData:start'),
                    value: `${getFullDate(
                      storefnt.custom?.contract?.startDate
                    )} ${t('summary:hours')}`,
                    hide: false,
                  },
                  {
                    label: '',
                    value: `   `,
                    hide: false,
                  },
                  {
                    label: t('form:vehicleData:end'),
                    value: `${getFullDate(
                      storefnt.custom?.contract?.endDate
                    )} ${t('summary:hours')}`,
                    hide: false,
                  },
                  {
                    label: t('form:vehicleData:totalPrice'),
                    value: `<strong>${renderTotalPrice()} ${t(
                      'form:shoppingCart:VATInc'
                    )}</strong> <div  class="u-mt-small u-font-size-fs-2">
                    ${t('form:shoppingCart:afterPriceText')}
                  </div>`,
                    hide: false,
                  },
                ]}
              </ArrayDefinitionList>
            </DataOverview>
          </ContentSection.ComponentWrapper>

          <ContentSection.ComponentWrapper>
            <DataOverview
              icon="semantic-checkmark"
              title={t('personal-details:title')}
              {...editButton(0)}
            >
              <ArrayDefinitionList>
                {[
                  {
                    label: t('personal-details:salutation'),
                    value: salutationsList.filter(
                      (item) => item.value === form.personalDetails.salutation
                    )[0]?.label,
                  },
                  {
                    label: t('personal-details:titleLabel'),
                    value:
                      titles.filter(
                        (item) => item.value === form.personalDetails.title
                      )[0]?.label || '',
                  },
                  {
                    label: t('personal-details:firstName'),
                    value: form.personalDetails.firstName,
                  },
                  {
                    label: t('personal-details:lastName'),
                    value: form.personalDetails.lastName,
                  },
                  {
                    label: t('personal-details:dateOfBirth'),
                    value: form.personalDetails.dateOfBirth,
                  },
                ]}
              </ArrayDefinitionList>
            </DataOverview>
          </ContentSection.ComponentWrapper>

          <ContentSection.ComponentWrapper>
            <DataOverview
              icon="semantic-checkmark"
              title={t('contact-details:title')}
              {...editButton(1)}
            >
              <ArrayDefinitionList>
                {[
                  {
                    label: t('contact-details:street'),
                    value: form.contactDetails.addresses[0].street,
                  },
                  {
                    label: t('contact-details:houseNumber'),
                    value: form.contactDetails.addresses[0].houseNumber,
                  },
                  {
                    label: t('contact-details:zipCode'),
                    value: form.contactDetails.addresses[0].zipCode,
                  },
                  {
                    label: t('contact-details:city'),
                    value: form.contactDetails.addresses[0].city,
                  },
                  {
                    label: t('contact-details:countryCode'),
                    value: countries.filter(
                      (item) =>
                        item.value ===
                        form.contactDetails.addresses[0].countryCode
                    )[0].label,
                  },
                  {
                    label: t('contact-details:email'),
                    value: form.contactDetails.contactData.email,
                  },
                  {
                    label: t('contact-details:mobilePhoneNumber'),
                    value: `${form.contactDetails.contactData.mobilePhoneCountryCode} ${form.contactDetails.contactData.mobilePhoneNumber}`,
                  },
                ]}
              </ArrayDefinitionList>
            </DataOverview>
          </ContentSection.ComponentWrapper>

          {form?.additionalDrivers?.length && renderAdditionalDrivers()}
                  
          {renderPaymentData()}
        </ContentSection>
        <DataProtection trackSubmit={trackSubmit} />
      </main>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: getStorefrontData(state),
    formData: getFormData(state),
  }
}
export default connect(mapStateToProps, { setActualPage: setActualPageAction })(
  SummaryPage
)
