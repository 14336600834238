import axios from 'axios'
import moment from 'moment/moment'
import { store } from '../../redux'
import { history } from '../../routing'
import routes from '../../../routes'

const { REACT_APP_API_GATEWAY, REACT_APP_BACKEND_BRAND } = process.env

export const completeJourney = (data: any): Promise<string> => {
  const country = 'de'
  const dataStorageId = store.getState().storefront.id
  const url = `${REACT_APP_API_GATEWAY}/complete-form-async/${country}/${REACT_APP_BACKEND_BRAND}/journey/${dataStorageId}`
  return axios
    .post(url, data)
    .then(response => response)
    .catch(error => {
      history.pushPreservePath(routes.apiErrorPage)
      return error
    })
}

const checkStatus = () => {
  const dataStorageId = store.getState().storefront.id
  const checkFinishStatusUrl = `${REACT_APP_API_GATEWAY}/complete-form-async/status/${dataStorageId}`
  return axios
    .get(`${checkFinishStatusUrl}?rand=${moment().unix()}`, {
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
      },
      validateStatus: status => status === 200,
    })
    .then(response => response.data)
}
export { checkStatus }
