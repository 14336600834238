export const scrollToElement = (selector, align = 'start', submitSelector) => {
  // get shopping cart height so that scrolling won't put the element
  // behind it
  const shoppingCart = document.querySelector('.c-floating-bar')
  const heightOffset =
    shoppingCart && shoppingCart.getBoundingClientRect().height + 20
  let alignmentHeight
  switch (align) {
    case 'center':
      alignmentHeight = window.innerHeight / 2
      break
    case 'end':
      alignmentHeight = window.innerHeight - window.innerHeight / 5
      break
    case 'start':
    default:
      alignmentHeight = 0
  }
  const element = document.querySelector(selector)
    ? document.querySelector(selector)
    : document.querySelector(submitSelector)

  if (element) {
    let labelOffset = 0
    if (element.tagName.toUpperCase() === 'INPUT') {
      const label = document.querySelector('.c-form-field__label')
      labelOffset = label && label.getBoundingClientRect().height
    }
    // get relative element position from top for scrolling
    const position = element.getBoundingClientRect().top
    if (/Edge/.test(navigator.userAgent)) {
      window.scrollTo(
        0,
        element.getBoundingClientRect().top + window.scrollY - labelOffset - 120
      )
    } else if (document.documentMode) {
      window.scrollTo(
        0,
        element.getBoundingClientRect().top +
          window.pageYOffset -
          labelOffset -
          120
      )
    } else {
      window.scrollBy({
        top: position - heightOffset - alignmentHeight - labelOffset,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
}

// TODO: complete this method
export const scrollToActiveFormSection = () => {
  scrollToElement(
    '.c-form-section__header.is-active',
    'start'
    // TODO: watch this...
    // '#data-protection-form'
  )
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const getLabelForValue = (dataModels, valueListName, value) => {
  if (!dataModels[valueListName]) return value
  const matchingValues = dataModels[valueListName].filter(item => {
    return item.value === value
  })
  if (matchingValues.length) {
    return matchingValues[0].label
  }
  return value
}
export const isValidationDisabled = property => {
  return property.className.indexOf('disabled-validation') !== -1
}
