import { Analytics } from '../../services/analytics'

export const pageName = 'Summary'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = () => {
  Analytics.setTrackingData({
    ...defaultTrackingData,
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.eventType(1)]: 'SummaryPage',
    [Analytics.keys.eventAction(1)]: 'Success',
  })
  Analytics.trackPage()
}

export const trackSubmit = data => {
  Analytics.addToDatalayer({
    'dataPrivacyStatement.allowPhoneAds': data.allowPhoneAds ? 'Phone' : false,
    'dataPrivacyStatement.allowMailAds': data.allowMailAds ? 'Mail' : false,
  })
  Analytics.setTrackingData({
    ...defaultTrackingData,
    [Analytics.keys.viewChange()]: 'Transfer in progress',
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  })
  Analytics.trackPage()
}
