export const SET_KEYCLOAK_DATA = 'keycloak//SET_KEYCLOAK_DATA'
export const SET_KEYCLOAK_IDP = 'keycloak//SET_KEYCLOAK_IDP'
export const SET_KEYCLOAK_IDP_HINT = 'keycloak//SET_KEYCLOAK_IDP_HINT'
export const SET_KEYCLOAK_TOKENS = 'keycloak//SET_KEYCLOAK_TOKENS'

interface SetKeycloakAction {
  type: typeof SET_KEYCLOAK_DATA
  payload: any
}

interface SetKeycloakIDPHintAction {
  type: typeof SET_KEYCLOAK_IDP_HINT
  payload: any
}
interface SetKeycloakTokensAction {
  type: typeof SET_KEYCLOAK_TOKENS
  payload: any
}

export type KeycloakActions =
  | SetKeycloakAction
  | SetKeycloakIDPHintAction
  | SetKeycloakTokensAction
