import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { getBrandName } from './config'
import { DEFAULT_DATALAYER } from './datalayer'
import { setDataModels } from './services/redux/features/data-models.redux'
import {
  getKeycloakIdpHint,
  setKeycloakIdpHint,
  setKeycloakTokens,
} from './services/redux/features/keycloak.redux'
import { Analytics } from './services/analytics'
import { history, qs } from './services/routing'
import { brandNames } from './resources/index'
import Routes from './components/Routes'
import { Page } from './models/page'
import basePages from './resources/base/pages/index.json'
import * as baseModels from './resources/base/models'
import keycloakClientConfig from './keycloak'
import { isDev } from './services/common/data'
import routes from './routes'
import './app.css'
import { Brand } from './models/enums'

const Loading = () => (
  <div className="u-text-center">
    <br />
    <br />
    <Spinner center />
    <br />
    <br />
  </div>
)

type AppStateProps = {
  setDataModels: any
  setKeycloakIdpHint: any
  setKeycloakTokens: any
}

export const App = (props: AppStateProps) => {
  const { t, i18n } = useTranslation()

  const {
    setDataModels: setModels,
    setKeycloakIdpHint: setIdpHint,
    setKeycloakTokens: setTokens,
  } = props

  const [isKeycloakReady, setKeycloakReady] = React.useState(false)
  const [isReady, setReady] = React.useState(false)
  const [page, setPage] = React.useState('')
  const [entryUrl, setEntryUrl] = React.useState('')
  const [pages, setPages] = React.useState([] as any)

  const [identificationId] = React.useState(() => {
    return qs(document.location.search, 'transaction-number')
  })
  const [storefrontId] = React.useState(() => {
    setEntryUrl(window.location.href)
    const id = qs(document.location.search, 'id')
    if (
      id === '' &&
      identificationId === '' &&
      history.location.pathname !== '/error'
    ) {
      history.push(routes.errorPage)
    }
    return id
  })

  React.useEffect(() => {
    setModels({ ...baseModels })
    setReady(true)
    setPages(basePages)
  }, [setModels])

  React.useEffect(() => {
    Analytics.setDefaults(DEFAULT_DATALAYER)
    Analytics.updateScreenSize()
    window.onresize = () => {
      Analytics.updateScreenSize()
    }
  }, [])

  function getPageFile(pageName) {
    if (!pages.length) {
      return ''
    }
    const currentPage = pages.find((p: Page) => p.url === `/page/${pageName}`)
    if (!currentPage) {
      history.push('/')
      // eslint-disable-next-line consistent-return
      return ''
    }
    setPage(currentPage)
    // eslint-disable-next-line consistent-return
    return currentPage.file || ''
  }

  React.useEffect(() => {
    document.title = `${t('app:pageTitle')}`
    const description: HTMLMetaElement | undefined = Array.from(
      document.getElementsByTagName('meta')
    ).find(meta => {
      return meta.name.toLocaleLowerCase() === 'description'
    })
    if (description) {
      description.content = `${brandNames[getBrandName()]} ${t(
        'app:pageTitle'
      )}`
    }
  }, [page, t])

  React.useEffect(() => {
    i18n.changeLanguage(getBrandName())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const keycloakBrand = getBrandName() as Brand

  const kc = qs(document.location.search, 'kc')
  if (kc) {
    setIdpHint(keycloakClientConfig[keycloakBrand].idpHint)
  }

  const eventLogger = (event: unknown, error: unknown) => {
    if (isDev()) {
      console.log('onKeycloakEvent', event, error)
    }
    if (event === 'onReady' || event === 'onInitError') {
      setKeycloakReady(true)
    }
  }

  const tokenLogger = (tokens: unknown) => {
    setTokens(tokens)
    if (isDev()) {
      console.log('onKeycloakTokens', tokens)
    }
  }

  const keycloak = keycloakClientConfig[keycloakBrand].kc

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <div className="o-root">
        {isReady && isKeycloakReady ? (
          <Routes
            pages={pages}
            storefrontId={storefrontId}
            identificationId={identificationId}
            getPageFile={getPageFile}
            entryUrl={entryUrl}
          />
        ) : (
          <Loading />
        )}
      </div>
    </ReactKeycloakProvider>
  )
}
const mapStateToProps = state => {
  return {
    getKeycloakIdp: getKeycloakIdpHint(state),
  }
}

export default connect(mapStateToProps, {
  setDataModels,
  setKeycloakIdpHint,
  setKeycloakTokens,
})(App)
