import { Analytics } from '../../services/analytics'

export const trackPage = (isApi, entryUrl) => {
  const pageName = isApi ? 'Summary' : 'Error page'
  const eventType = isApi ? 'API' : 'Error'
  const eventAction = isApi ? 'Error' : 'Technical Error'
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: pageName,
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.eventType(1)]: eventType,
    [Analytics.keys.eventAction(1)]: eventAction,
    [Analytics.keys.errorCode()]: 'Error',
    [Analytics.keys.errorMessage()]: 'Technical Error',

    ...(entryUrl && { [Analytics.keys.errorCausingURL()]: entryUrl }),
  })
  Analytics.trackPage()
}
