/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Modal } from '@vwfs-bronson/bronson-react'
import ContactPage from './contact-page'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { pageModalProps } from '../../models/pageModalProps'
import { getActualPage } from '../../services/redux/features/custom.redux'
import { trackOpenContact } from './tracking'

const ContactPageModal = (props: pageModalProps) => {
  React.useEffect(() => {
    if (props.visible) {
      trackOpenContact(props.actualPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible])

  const { t } = useTranslation()

  const { storefrontData } = props

  return (
    <Modal
      testId={props.testId}
      id="contactModal"
      shown={props.visible}
      onClose={props.onClose}
      onClickOutside={props.onClose}
      onConfirm={props.onClose}
      stretch
      title={t('contact:headline')}
      buttonConfirmText={t('contact:closeBtn')}
    >
      <ContactPage storefrontData={storefrontData} />
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    storefrontData: getStorefrontData(state),
    actualPage: getActualPage(state),
  }
}

export default connect(mapStateToProps)(ContactPageModal)
