import React from 'react'
import { Layout, Paragraph } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import Icon, { IconSize } from '../../components/Icon/Icon'

const ContactPage = ({ storefrontData }) => {
  const { t } = useTranslation()

  const { companyData } = storefrontData || {}
  const { address, contactData } = companyData || {}

  return (
    <>
      <Layout className="u-pb-small">
        <Layout.Item>
          <Paragraph className="u-text-center">
            <Icon icon="mail" size={IconSize.large} />
          </Paragraph>
          <Paragraph testId="contactText">{t('contact:headText')}</Paragraph>

          {companyData && (
            <>
              {companyData.name && (
                <Paragraph testId="contactTextCompanyName">
                  <b>{companyData.name}</b>
                </Paragraph>
              )}

              {address && (
                <Paragraph testId="contactTextAdress">
                  {address.street} {address.houseNumber}
                  <br />
                  {address.zipCode} {address.city}
                </Paragraph>
              )}

              {contactData && (
                <Paragraph testId="contactTextContact">
                  {contactData.telephoneNumber && (
                    <>
                      {t('contact:phone')} {contactData.telephoneNumber}
                      <br />
                    </>
                  )}
                  {contactData.email && (
                    <>
                      {t('contact:mail')} {contactData.email}
                    </>
                  )}
                </Paragraph>
              )}
            </>
          )}
        </Layout.Item>
      </Layout>
    </>
  )
}
export default ContactPage
