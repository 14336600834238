import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import { i18n as i18nResources } from '../../resources/base/content'
import { i18n as i18naudi } from '../../resources/base/content/audi'
import { i18n as i18nvwfs } from '../../resources/base/content/vwfs'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'audi',
    resources: {
      audi: i18naudi,
      vwfs: i18nvwfs,
    },
    lng: 'audi',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })
