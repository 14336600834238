/* eslint-disable no-unused-vars */
export interface Address {
  street: string
  houseNumber: string
  zipCode: string
  city: string
  countryCode: string
  state?: string
  addressType?: AddressType
  hasCompanyName?: boolean
  companyName?: string
  isManual?: boolean
}

export enum AddressType {
  PRIMARY = 'Primary',
  BUSINESS = 'Business',
}

export interface ContactData {
  mobilePhoneCountryCode: string
  mobilePhoneNumber: string
  email: string
}

export interface ContactBody {
  addresses: Address[]
  contactData: ContactData
  otherAddress?: boolean
}
