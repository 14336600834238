export const isVWFS = () => {
  const brand = process.env.REACT_APP_WEBSITE_BRAND
  return brand === 'vwfs'
}

export const isFalsy = value => {
  return value === undefined || value === null
}

export const isAudi = () => {
  const brand = process.env.REACT_APP_WEBSITE_BRAND
  return brand === 'audi'
}

export const scrollElWithDelay = (func, timeout = 500) => {
  setTimeout(() => func(), timeout)
}

export const hasAdditionalDrivers = storefrontData => {
  return storefrontData?.financialProduct?.additionalDrivers?.amount > 0
}

export const isMandatoryLogin = () => {
  return (
    process.env.REACT_APP_MANDATORY_LOGIN &&
    process.env.REACT_APP_MANDATORY_LOGIN === 'true'
  )
}

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}
