/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import debounce from 'lodash.debounce'
import { ErrorMessage, Input, Spinner } from '@vwfs-bronson/bronson-react'
import { useField } from 'formik'
import './AddressAutocomplete.css'
import { useTranslation } from 'react-i18next'
import { autocomplete } from '../../services/api/addressValidation/autocomplete'
import { FormField } from '../FormField'
import { getListItemChildren } from './common'

interface Props {
  prefix: string
  label?: string
  infoIconText?: React.ReactNode
}

const DEBOUNCE_MILLIS = 300
const MIN_QUERY_LENGTH = 2

export const StreetAutocomplete = ({ prefix, label }: Props) => {
  const { t } = useTranslation()
  const [fieldProps, , { setValue: setAutocompleteQuery }] = useField<string>(
    `${prefix}street`
  )
  const [{ value: countryCode }] = useField<string>(`${prefix}countryCode`)
  const [{ value: zipCode }] = useField<string>(`${prefix}zipCode`)
  const [{ value: city }] = useField<string>(`${prefix}city`)
  const [{ value: zipCodeSelected }] = useField<boolean>(
    `${prefix}zipCodeSelected`
  )
  const [, , { setValue: setStreetSelected }] = useField<boolean>(
    `${prefix}streetSelected`
  )

  const abortController = useRef<AbortController>()
  const [query, setQuery] = useState('')
  const [error, setError] = useState<string>()
  const [items, setItems] = useState<any[]>([])
  const [currentItem, setCurrentItem] = useState<any>()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (
      currentItem &&
      (fieldProps?.value !== currentItem.street ||
        zipCode !== currentItem.zipCode ||
        city !== (currentItem.city || currentItem.county) ||
        countryCode !== currentItem.countryCode)
    ) {
      setError(undefined)
      setItems([])
      setCurrentItem(undefined)
      setStreetSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps?.value, zipCode, city, countryCode])

  useEffect(() => {
    if (initialized) {
      if (currentItem && fieldProps?.value !== currentItem.street) {
        setCurrentItem(undefined)
      }
      onDebouncedAutocompleteQueryChange((fieldProps?.value ?? '').trim())
    } else {
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldProps?.value])

  const onAutocompleteQueryChange = (newQuery: string) => {
    if (newQuery === query && !error) {
      return
    }
    // eslint-disable-next-line no-unused-expressions
    abortController.current?.abort()
    setQuery(newQuery)
    setError(undefined)
    setItems([])
    if (newQuery.length < MIN_QUERY_LENGTH) {
      abortController.current = undefined
      return
    }
    abortController.current = new AbortController()
    setLoading(true)
    autocomplete(
      {
        street: fieldProps?.value,
        ...(countryCode && { countryCode }),
        ...(city && { city }),
        ...(zipCode && { zipCode }),
      },
      abortController.current
    )
      .then(i => {
        setError(
          i.length === 0
            ? t('contact-details:errors:noAddressFound')
            : undefined
        )
        setItems(i)
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          setError(t('contact-details:errors:genericError'))
          setItems([])
        }
      })
      .finally(() => {
        abortController.current = undefined
        setLoading(false)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedAutocompleteQueryChange = debounce(
    onAutocompleteQueryChange,
    DEBOUNCE_MILLIS
  )

  return (
    <div className="address-autocomplete">
      <FormField
        testId="street"
        type="input"
        labelText={label}
        {...fieldProps}
        render={props => {
          return (
            <Input {...props} disabled={!zipCodeSelected} autocomplete="nope" />
          )
        }}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {items && items.length > 0 && (
        <div className="address-autocomplete">
          <ul className="c-list-ui">
            {items.map((item, index) => (
              <li
                className="c-list-ui__item"
                key={index}
                onClick={() => {
                  setStreetSelected(true)
                  setQuery(item.street)
                  setAutocompleteQuery(item.street, false)
                  setCurrentItem(item)
                  setError(undefined)
                  setItems([])
                }}
              >
                {getListItemChildren(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!items.length && loading && (
        <ul className="c-list-ui">
        <li
          className="c-list-ui__item"
          key="streetLoading"
        >
          <Spinner small />
        </li>
      </ul>
      )}
    </div>
  )
}
