import Keycloak from 'keycloak-js'
import { OauthClientId, OauthProvidersHint } from './models/enums'

const scope =
  'profile email phone address birthdate birthplace firstName lastName'

const url = process.env.REACT_APP_IDP_URL || ''

const keycloakConfig = {
  audi: {
    realm: 'vwfs-du-rentde',
    clientId: OauthClientId.AUDI,
    url,
    scope,
  },
  vwfs: {
    realm: 'vwfs-du-rentde',
    clientId: OauthClientId.VWFS,
    url,
    scope,
  },
}

const keycloakClientConfig = {
  audi: {
    name: 'audi',
    idpHint: OauthProvidersHint.AUDI_VW,
    kc: Keycloak(keycloakConfig.audi),
  },
  vwfs: {
    name: 'vwfs',
    idpHint: OauthProvidersHint.VWFS_VW,
    kc: Keycloak(keycloakConfig.vwfs),
  },
}

export default keycloakClientConfig
