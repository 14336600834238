export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING'
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE'
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR'
export const FETCH_STOREFRONT_STORE_ID = 'storefront//FETCH_STOREFRONT_STORE_ID'

interface PendingAction {
  type: typeof FETCH_STOREFRONT_PENDING
  payload: {}
}

interface DoneAction {
  type: typeof FETCH_STOREFRONT_DONE
  payload: any // TODO: change when we now the data type here
}

interface ErrorAction {
  type: typeof FETCH_STOREFRONT_ERROR
  payload: any // TODO: change when we now the error type here
}

interface StoreIdAction {
  type: typeof FETCH_STOREFRONT_STORE_ID
  payload: any // TODO: change when we now the error type here
}

export type StoreFrontActionTypes =
  | PendingAction
  | DoneAction
  | ErrorAction
  | StoreIdAction
