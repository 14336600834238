/* eslint-disable react/react-in-jsx-scope */
import React, { ReactNode } from 'react'

export const getListItemChildren = (item: any) => {
  const children: ReactNode[] = []
  const addressHighlights = item.highlights?.addresses?.[0]?.label || []
  if (addressHighlights.length === 0) {
    children.push(<span key={children.length}>{item.addressLine1}</span>)
  } else {
    const sortedHighlights = addressHighlights.sort(
      (a: any, b: any) => a.start - b.start
    )
    sortedHighlights.forEach(
      (highlight: any, index: number, highlights: any[]) => {
        const previousEnd = index === 0 ? 0 : highlights[index - 1].end
        let str = item.addressLine1.substring(previousEnd, highlight.start)
        if (str.length > 0) {
          children.push(<span key={children.length}>{str}</span>)
        }
        str = item.addressLine1.substring(highlight.start, highlight.end)
        if (str.length > 0) {
          children.push(
            <strong key={children.length} className="u-text-brand">
              {str}
            </strong>
          )
        }
        if (index === sortedHighlights.length - 1) {
          str = item.addressLine1.substring(highlight.end)
          if (str.length > 0) {
            children.push(<span key={children.length}>{str}</span>)
          }
        }
      }
    )
  }

  return children
}