import { createStore, combineReducers } from 'redux'

import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import storefrontReducer from './features/storefront.redux'
import transactionReducer from './features/transaction.redux'
import sessionReducer from './features/session.redux'
import customReducer from './features/custom.redux'
import keycloakReducer from './features/keycloak.redux'

/* eslint-disable no-underscore-dangle */
const rootReducer = combineReducers({
  form: formReducer,
  dataModel: dataModelsReducer,
  storefront: storefrontReducer,
  transaction: transactionReducer,
  session: sessionReducer,
  custom: customReducer,
  keycloak: keycloakReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export const store = createStore(
  rootReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */
