import i18n from 'i18next'
import * as Yup from 'yup'

const regExEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,61}))$/
const regExTextStreet =
  /^[^\s][0-9\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß.'-ß|/()`´'-._]*$/
const regExTextStreetNumber = /^[^\s][0-9\sa-zA-ZäöüÄÖÜ'-ß|/()`´'-._]*$/
const regExTextZipCode = /^[^\s][0-9a-zA-Z-]*$/
const regExTextPlace =
  /^[^\s|(|)][\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß.'\-()]*$/

const getValidationSchema = () => {
  const addressObject = {
    hasCompanyName: Yup.boolean(),
    companyName: Yup.string().when('hasCompanyName', {
      is: true,
      then: Yup.string().required(i18n.t('validation:required')),
      otherwise: Yup.string(),
    }),
    street: Yup.string().when('isManual', {
      is: true,
      then: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextStreet, i18n.t('validation:invalidCharacter'))
        .min(2, i18n.t('validation:minLength', { value: 2 }))
        .max(60, i18n.t('validation:maxLength', { value: 60 })),
      otherwise: Yup.string().required(i18n.t('validation:required'))
    }),
    houseNumber: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextStreetNumber, i18n.t('validation:invalidCharacter'))
        .min(1, i18n.t('validation:minLength', { value: 1 }))
        .max(10, i18n.t('validation:maxLength', { value: 10 })),
    zipCode: Yup.string().when('isManual', {
      is: true,
      then: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextZipCode, i18n.t('validation:invalidCharacter'))
        .min(2, i18n.t('validation:minLength', { value: 3 }))
        .max(10, i18n.t('validation:maxLength', { value: 10 })),
      otherwise: Yup.string().required(i18n.t('validation:required'))
    }),
    city: Yup.string().when('isManual', {
      is: true,
      then: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextPlace, i18n.t('validation:invalidCharacter'))
        .min(2, i18n.t('validation:minLength', { value: 2 }))
        .max(35, i18n.t('validation:maxLength', { value: 35 })),
      otherwise: Yup.string().required(i18n.t('validation:required'))
    }),
    countryCode: Yup.string().required(i18n.t('validation:required')),
    state: Yup.string().when('countryCode', {
      is: (v) => v === 'CA' || v === 'US',
      then: Yup.string().required(i18n.t('validation:required')),
    }),
  }
  return Yup.object().shape({
    addresses: Yup.array().of(Yup.object().shape(addressObject)),
    contactData: Yup.object({
      mobilePhoneNumber: Yup.string()
        .required(i18n.t('validation:required'))
        .min(3, i18n.t('validation:minLength', { value: 3 }))
        .max(15, i18n.t('validation:maxLength', { value: 15 }))
        .test(
          'invalidPhoneNumber',
          i18n.t('validation:invalidPhoneNumber'),
          function (this, value) {
            if (!value) {
              return false
            }

            if (
              (this.parent.mobilePhoneCountryCode === '+49' &&
                value.startsWith('0')) ||
              value.startsWith('+49')
            ) {
              return false
            }
            return true
          }
        ),
      mobilePhoneCountryCode: Yup.string().required(
        i18n.t('validation:required')
      ),
      email: Yup.string()
        .matches(regExEmail, i18n.t('validation:invalidEmail'))
        .required(i18n.t('validation:required')),
    }),
  })
}

export default getValidationSchema
