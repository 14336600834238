import axios from 'axios'

export const userInfo = async (token: string) => {
  const domain = process.env.REACT_APP_IDP_URL
  const url = `${domain}/realms/vwfs-du-rentde/protocol/openid-connect/userinfo`
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
