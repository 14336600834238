import { TransactionState } from '../models/TransactionState'
import {
  FETCH_TRANSACTION_PENDING,
  FETCH_TRANSACTION_DONE,
  FETCH_TRANSACTION_ERROR,
  TransactionActions,
} from './actions/transaction.actions'

export function pending(): TransactionActions {
  return {
    type: FETCH_TRANSACTION_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: typeof FETCH_TRANSACTION_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_TRANSACTION_ERROR,
    payload: {
      err,
    },
  }
}

export function getTransactionData(store) {
  return store.transaction.data
}

const initialState: TransactionState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSACTION_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_TRANSACTION_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_TRANSACTION_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
