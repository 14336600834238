import * as Yup from 'yup'
import {
  format as fnsFormat,
  differenceInYears,
  isFuture,
  addDays,
  isPast,
} from 'date-fns'
import { TruthType } from '../../models/enums'

Yup.addMethod(Yup.string, 'formatOfDate', function formatOfDate(
  formats,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testFormat', message, function testFormat(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        value === fnsFormat(new Date(date[2], date[1] - 1, date[0]), formats)
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'fullAge', function fullAge(
  years,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) >= years
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return !isFuture(new Date(date[2], date[1] - 1, date[0]))
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInPast', function notInPast(
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testDateNotInPast', message, function testDateNotInPast(
    value
  ) {
    if (value) {
      const dateArr = value.split(dateSeperator)
      const date = addDays(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]), 1)
      return !isPast(date)
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInNearFuture', function notInNearFuture(
  addedDays,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test(
    'testDateNotInNearFuture',
    message,
    function testDateNotInNearFuture(value) {
      if (value) {
        const dateArr = value.split(dateSeperator)
        const dateform = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
        const minDate = addDays(new Date(), addedDays)
        return dateform > minDate
      }
      return true
    }
  )
})
Yup.addMethod(Yup.string, 'notInFarFuture', function notInFarFuture(
  addedDays,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test(
    'testDateNotInFarFuture',
    message,
    function testDateNotInFarFuture(value) {
      if (value) {
        const dateArr = value.split(dateSeperator)
        const dateform = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
        const maxDate = addDays(new Date(), addedDays)
        return dateform < maxDate
      }
      return true
    }
  )
})
Yup.addMethod(
  Yup.string,
  'notMoreThanHundredYearsAgo',
  function notMoreThanHundredYearsAgo(dateSeperator, message) {
    return this.test(
      'testDateNotMoreThanHundredYearsAgo',
      message,
      function testDateNotMoreThanHundredYearsAgo(value) {
        if (value) {
          const currentYear = value.split(dateSeperator)[2]
          const minAllowedYear = new Date().getFullYear() - 100
          return currentYear > minAllowedYear
        }
        return true
      }
    )
  }
)
Yup.addMethod(Yup.string, 'minValue', function minValue(
  message,
  minValueParam
) {
  return this.test('testMin', message, function testMin(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) >= minValueParam
  })
})
Yup.addMethod(Yup.string, 'maxValue', function maxValue(
  message,
  maxValueParam
) {
  return this.test('testMax', message, function testMax(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) <= maxValueParam
  })
})
Yup.addMethod(Yup.string, 'atLeastOneOf', function atLeastOneOf(list, message) {
  return this.test('testAtLeastOneOf', message, function testAtLeastOneOf(
    value
  ) {
    if (value === undefined) {
      return list.some(item => this.parent[item] !== undefined)
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'validateNif', function validateNif(message) {
  return this.test('testNif', message, function testNif(nif) {
    if (!nif) return true
    let sum = 0
    let control = 0
    if (nif.length === 9) {
      sum =
        nif[7] * 2 +
        nif[6] * 3 +
        nif[5] * 4 +
        nif[4] * 5 +
        nif[3] * 6 +
        nif[2] * 7 +
        nif[1] * 8 +
        nif[0] * 9

      const mod = sum % 11
      if (mod !== 0 && mod !== 1) {
        control = 11 - mod
      }
      if (parseInt(nif[8], 10) === control) {
        return true
      }
    }
    return false
  })
})
Yup.addMethod(Yup.string, 'validateTermsAgree', function validateTermsAgree(tlsValid, dataPrivacyConsentAcceptance, message) {
  return this.test('testTermsAgree', message, function testTermsAgree(value) {
    return !(tlsValid
      ? value === TruthType.FALSE
      : value === TruthType.FALSE ||
          !dataPrivacyConsentAcceptance)
  })
})

export default Yup
