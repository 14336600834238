import React, { FunctionComponent } from 'react'
import {
  Header as BrHeader,
  ContextBar,
  Logo,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Logout from '../IdentityBroker/Logout'
import ContactPageModal from '../../pages/ContactPageModal'
import { getActualPage } from '../../services/redux/features/custom.redux'
import './style.scss'
import { getBrandName, isAudi, isVWFS } from '../../config'

const Header: FunctionComponent = () => {
  const [showContact, setShowContact] = React.useState(false)
  const { t } = useTranslation()

  const getLogo = () => {
    return isAudi() ? '/images/logo_audiondemand.svg' : undefined
  }
  const landingPageUrl = process.env.REACT_APP_LANDING_PAGE_URL
  return (
    <>
      <BrHeader
        testId="appHeader"
        staticHeader
        logo={
          <a href={landingPageUrl}>
            <Logo
              src={getLogo()}
              testId={`appHeaderLogo-${getBrandName()}`}
              breakpoint="720px"
              disableLink
            ></Logo>
          </a>
        }
      >
        <ContextBar isContextBar>
          {isVWFS() && (
              <ContextBar.SiteNav>
                <ContextBar.SiteNav.Item>
                  <img
                    src="/images/logo-rentACar.svg"
                    alt="Rent a car logo"
                    className="rentACar-logo"
                  />
                </ContextBar.SiteNav.Item>
              </ContextBar.SiteNav>
          )}
          <ContextBar.SiteNav>
            <ul className="c-context-bar__nav">
              <Logout />
              <li>
                <Link
                  className="c-context-bar__link"
                  onClick={() => setShowContact(true)}
                  to="#"
                >
                  <span className="c-context-bar__nav-label">
                    {t('contact:labelTitle')}
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="c-context-bar__link"
                  onClick={() => setShowContact(true)}
                  to="#"
                >
                  <i className="c-icon  c-icon--[semantic-mail]  c-context-bar__icon u-mr-small" />
                </Link>
              </li>
            </ul>
          </ContextBar.SiteNav>
        </ContextBar>
      </BrHeader>

      <ContactPageModal
        testId="appContactPageModal"
        visible={showContact}
        onClose={() => setShowContact(false)}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    actualPage: getActualPage(state),
  }
}

export default connect(mapStateToProps, {})(Header)
