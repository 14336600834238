/* eslint-disable react/no-danger */
import React from 'react'
import {
  Card,
  Toggle,
  Fieldset,
  Layout,
  RadioButtonGroup,
  RadioButton,
} from '@vwfs-bronson/bronson-react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { FormField } from '../../../components/FormField'
import { TruthType } from '../../../models/enums'
import { getTlsValid } from '../../../services/redux/features/custom.redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'

const CheckboxItem = ({ label, name, onChange, children, notion, values }) => {
  return (
    <div className="o-layout__item u-1/1 u-1/1@xs">
      <div className="c-form-field">
        <div className="c-form-field__label">{label}</div>
        <div className="c-form-field__box">
          <FormField
            testId={name}
            type="select"
            name={name}
            render={() => (
              <Toggle
                name={name}
                id={name}
                inputType="checkbox"
                onOff
                onChange={onChange}
                checked={get(values, name, '')}
              >
                {children}
                {notion && '*'}
              </Toggle>
            )}
          />
        </div>
      </div>
    </div>
  )
}

const DataProtectionFieldset = ({ t, formProps, tlsValid, storefrontData }) => {
  const { values, setFieldValue } = formProps
  const changeSelectAll = (event) => {
    setFieldValue('allowPhoneAds', event.target.checked)
    setFieldValue('allowMailAds', event.target.checked)
  }

  const changeCheckbox = (event) => {
    if (!event.target.checked) {
      setFieldValue('selectall', event.target.checked)
    }
  }

  const CardItem = ({ title, content }) => (
    <Card title={title} contentLarge={content} expandable />
  )

  const showPrivacyInformation = () => (
    <>
      <legend className="c-form-heading">
        <strong className="c-form-heading__title">
          <span className="c-form-heading__title-text">
            {t('form:dataProtection:title')}
          </span>
        </strong>
      </legend>
      <div className="o-fieldset__row">
        <strong className="c-form-heading__text">
          <a
            href={t('form:dataProtection:datenschutzLink')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('form:dataProtection:Privacy:subTitle')}
          </a>
        </strong>
        <p style={{ paddingTop: '16px' }}>
          {t('form:dataProtection:Privacy:paragraph1')}
        </p>
      </div>
    </>
  )

  const ConsentCheckbox = ({ itemName = '', children }) => (
    <Fieldset.Row>
      <Layout>
        <CheckboxItem name={itemName} onChange={changeCheckbox} values={values}>
          {children}
        </CheckboxItem>
      </Layout>
    </Fieldset.Row>
  )

  const showPrivacyConsents = () => (
    <Fieldset.Row>
      <CardItem
        title={
          <>
            <Fieldset.Row>
              <legend className="c-form-heading">
                <strong className="c-form-heading__title">
                  <span className="c-form-heading__title-text">
                    {t('form:dataProtection:Advertising:title')}
                  </span>
                </strong>
              </legend>
              <Layout>
                <CheckboxItem
                  name="selectall"
                  onChange={changeSelectAll}
                  values={values}
                >
                  {t('form:dataProtection:Advertising:paragraph1')}
                </CheckboxItem>
              </Layout>
              <div style={{ display: 'none' }}>
                <ConsentCheckbox itemName="allowPhoneAds">
                  {t('form:dataProtection:mailAndTelAdsCheckTel')}
                </ConsentCheckbox>
                <ConsentCheckbox itemName="allowMailAds">
                  {t('form:dataProtection:mailAndTelAdsCheckMail')}
                </ConsentCheckbox>
              </div>
            </Fieldset.Row>
          </>
        }
        content={
          <>
            <Fieldset.Row>
              <p>{t('form:dataProtection:Advertising:paragraph2')}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('form:dataProtection:Advertising:paragraph3'),
                }}
              />
            </Fieldset.Row>
          </>
        }
      ></CardItem>
    </Fieldset.Row>
  )

  const showAllowCustomerProfiles = () => (
    <Fieldset.Row>
      <CardItem
        title={
          <>
            <Fieldset.Row>
              <legend className="c-form-heading">
                <strong className="c-form-heading__title">
                  <span className="c-form-heading__title-text">
                    {t('form:dataProtection:CustomerProfiles:title')}
                  </span>
                </strong>
              </legend>
              <Layout>
                <CheckboxItem
                  name="allowProfilesCreation"
                  values={values}
                  onChange={() => null}
                >
                  {t('form:dataProtection:CustomerProfiles:paragraph1')}
                </CheckboxItem>
              </Layout>
            </Fieldset.Row>
          </>
        }
        content={
          <Fieldset.Row>
            <p>{t('form:dataProtection:CustomerProfiles:paragraph2')}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('form:dataProtection:CustomerProfiles:paragraph3'),
              }}
            />
          </Fieldset.Row>
        }
      ></CardItem>
    </Fieldset.Row>
  )

  const showEmailCommunicationConsent = () => (
    <Fieldset.Row>
      <CardItem
        title={
          <>
            <Fieldset.Row>
              <legend className="c-form-heading">
                <strong className="c-form-heading__title">
                  <span className="c-form-heading__title-text">
                    {t('form:dataProtection:tls:title')}
                  </span>
                </strong>
              </legend>
              <Layout>
                <CheckboxItem
                  name="dataPrivacyConsentAcceptance"
                  values={values}
                  onChange={() => null}
                >
                  <strong>{t('form:dataProtection:tls:text')}</strong>
                  {!values.dataPrivacyConsentAcceptance && (
                    <>
                      <br />
                      <span style={{ color: 'red' }}>
                        {t('form:dataProtection:tls:validation')}
                      </span>
                    </>
                  )}
                </CheckboxItem>
              </Layout>
            </Fieldset.Row>
          </>
        }
        content={
          <Fieldset.Row>
            <p>{t('form:dataProtection:Communication:paragraph1')}</p>
          </Fieldset.Row>
        }
      ></CardItem>
    </Fieldset.Row>
  )

  const showGeneralConditions = (storefrontData) => {
    return (
      <>
        <Fieldset.Row>
          <legend className="c-form-heading">
            <strong className="c-form-heading__title">
              <span
                className="c-form-heading__title-text"
                id="GeneralConditionsTitle"
              >
                {t('form:dataProtection:termsTitle')}
              </span>
            </strong>
          </legend>
          <p>
            {t('form:dataProtection:termsAgree1')}
            <a
              href={t('form:dataProtection:AGBlink')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('form:dataProtection:termsAgree2')}
            </a>
            {t('form:dataProtection:termsAgree3')}
            {t('form:dataProtection:termsAgree4', {
              companyName: storefrontData?.companyData?.name,
            })}
          </p>
          <FormField
            testId="termsAgreeCheckAgree"
            name="termsAgreeCheckAgree"
            type="other"
            render={(fieldProps) => (
              <RadioButtonGroup>
                <RadioButton
                  {...fieldProps}
                  defaultChecked={
                    values.termsAgreeCheckAgree === TruthType.TRUE
                  }
                  value
                >
                  {t('form:dataProtection:termsAgreeCheckAgree')}
                </RadioButton>
                <RadioButton
                  {...fieldProps}
                  value={false}
                  defaultChecked={
                    values.termsAgreeCheckAgree === TruthType.FALSE
                  }
                >
                  {t('form:dataProtection:termsAgreeCheckDisagree')}
                </RadioButton>
              </RadioButtonGroup>
            )}
          />
        </Fieldset.Row>
      </>
    )
  }

  return (
    <Fieldset>
      {showPrivacyInformation()}
      {showPrivacyConsents()}
      {showAllowCustomerProfiles()}
      {!tlsValid && showEmailCommunicationConsent()}
      {showGeneralConditions(storefrontData)}
    </Fieldset>
  )
}

const mapStateToProps = (state) => {
  return {
    tlsValid: getTlsValid(state),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps, null, null)(DataProtectionFieldset)
