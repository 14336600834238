import {
  LastFormSectionCompleted,
  TransactionStatus,
} from '../../../models/enums'
import routes from '../../../routes'
import { hasAdditionalDrivers } from '../../common/utils'
import { store } from '../../redux'
import { saveFormData } from '../../redux/features/form.redux'
import getInitialValuesPersonal from '../../../pages/AppPage/PersonalDetails/initial-values'
import getInitialValuesContact from '../../../pages/AppPage/ContactDetails/initial-values'
import getInitialValuesAdditionalDrivers from '../../../pages/AppPage/AdditionalDrivers/initial-values'
import getInitialValuesPayment from '../../../pages/AppPage/PaymentDetails/initial-values'

export const continueBasedOnStatus = (storefrontData: any): string => {
  const status = storefrontData?.transaction?.status
  fillFormDataBasedonlastFormSectionCompleted(storefrontData)
  switch (status) {
    case TransactionStatus.INITIALIZED:
    case TransactionStatus.APPLICATIONFORM_STARTED:
      return routes.landingPage
    default:
      console.log(`continueBasedOnStatus could not handle status ${status}`)
      return routes.errorPage
  }
}
const fillPersonalData = (storefrontData: any) => {
  const keycloakData = store.getState().keycloak.data
  const personalInitialValues = getInitialValuesPersonal({
    formData: {},
    keycloakData,
    storefrontData,
  })
  const newFormData = {
    personalDetails: {
      ...personalInitialValues,
    },
  }
  store.dispatch(saveFormData(newFormData))
  return newFormData
}
const fillContactData = (storefrontData: any, formData: any) => {
  const keycloakData = store.getState().keycloak.data
  const countryCodeList = []
  const contactInitialValues = getInitialValuesContact({
    formData,
    keycloakData,
    storefrontData,
    countryCodeList,
  })
  const newFormData = {
    ...formData,
    contactDetails: {
      ...contactInitialValues,
    },
  }
  store.dispatch(saveFormData(newFormData))
  return newFormData
}

const fillAdditionalDriversData = (storefrontData: any, formData: any) => {
  const numOfAdditionalDrivers =
    storefrontData?.financialProduct?.additionalDrivers?.amount || 0
  const additionalDriversInitialValues = getInitialValuesAdditionalDrivers(
    formData,
    storefrontData,
    numOfAdditionalDrivers
  )
  const newFormData = {
    ...formData,
    additionalDrivers: additionalDriversInitialValues?.additionalDrivers,
  }
  store.dispatch(saveFormData(newFormData))
  return newFormData
}

const fillPaymentData = (storefrontData: any, formData: any) => {
  const paymentInitialValues = getInitialValuesPayment({
    formData,
    storefrontData,
  })
  const newFormData = {
    ...formData,
    ibanDetails: {
      ...paymentInitialValues,
    },
  }
  store.dispatch(saveFormData(newFormData))
  return newFormData
}

const fillFormDataBasedonlastFormSectionCompleted = (storefrontData: any) => {
  const lastFormSectionCompleted =
    storefrontData?.custom?.lastFormSectionCompleted
  let newFormData = undefined as any
  switch (lastFormSectionCompleted) {
    case LastFormSectionCompleted.PERSONAL:
      fillPersonalData(storefrontData)
      break
    case LastFormSectionCompleted.CONTACT:
      newFormData = fillPersonalData(storefrontData)
      fillContactData(storefrontData, newFormData)
      break
    case LastFormSectionCompleted.DRIVERS:
      newFormData = fillPersonalData(storefrontData)
      newFormData = fillContactData(storefrontData, newFormData)
      fillAdditionalDriversData(storefrontData, newFormData)
      break
    case LastFormSectionCompleted.PAYMENT:
      newFormData = fillPersonalData(storefrontData)
      newFormData = fillContactData(storefrontData, newFormData)
      if (hasAdditionalDrivers(storefrontData)) {
        newFormData = fillAdditionalDriversData(storefrontData, newFormData)
      }
      fillPaymentData(storefrontData, newFormData)
      break
    default:
      break
  }
}
