import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  DescriptionList,
  Paragraph,
  ProductTable,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import * as lodashGet from 'lodash/get'
import { formatNumber, formatPrice } from '../../services/common/format'
import { ShoppingCart } from './ShoppingCart'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { getFormData } from '../../services/redux/features/form.redux'

import { RootState } from '../../services/redux'
import { getDiscount } from '../../services/redux/features/custom.redux'
import './storefrontShoppingCart.css'
import { getFullDate } from '../../services/common/data'
import { isFalsy, isMobileDevice, isVWFS } from '../../services/common/utils'

const get: any = (object: any, path: string) => {
  return lodashGet(object, path, '-')
}
declare var require: any
const imgVehicleDefault = require('../../resources/images/vehicle-default.png')

export type StorefrontShoppingCartProps = {
  storefrontData: any
  foldout: any
  floatingBar: any
  formData: any
}

const StorefrontShoppingCart: FunctionComponent<StorefrontShoppingCartProps> = (
  props: StorefrontShoppingCartProps
) => {
  const { storefrontData, foldout, floatingBar } = props
  const { t } = useTranslation()
  if (!storefrontData || !storefrontData.vehicleData) return null
  const { vehicleData, financialProduct } = storefrontData
  const imgVehicleUrl = vehicleData.model?.vehicleImageUrl
  const storefrontRedirectURL = storefrontData.salesChannel?.storefrontRedirectUrl
  const selectedVehicleTitle = isVWFS()
    ? `${get(vehicleData, 'acrissCodeDescription')} (${get(
        vehicleData,
        'vehicleBrand'
      )} ${get(vehicleData, 'vehicleModel')} ${t(
        'form:shoppingCart:orSimilar'
      )})`
    : `${get(vehicleData, 'vehicleBrand')} ${get(vehicleData, 'vehicleModel')}`

  const imageSubtitle = isVWFS() ? null : get(vehicleData, 'vehicleDescription')

  return (
    <>
      <ShoppingCart
        id="shoppingCart"
        foldout={foldout}
        floatingBar={floatingBar}
        imgSrc={imgVehicleUrl || imgVehicleDefault}
        imgSizes="175px"
        title={selectedVehicleTitle}
        infoLabel={t('form:shoppingCart:totalAmount')}
        infoValue={formatPrice(
          financialProduct?.rentalPriceTotal.rentalTotalCostGrossAmount
        )}
        storefrontRedirectURL={storefrontRedirectURL}
        subtitleImg={imageSubtitle}
        subtitle={
          (
            <div style={{ flex: '1' }}>
              <strong>{t('form:shoppingCart:collAndReturn')}</strong>
              <div style={{ display: 'flex' }} className="u-mt-xxsmall">
                <div>
                  <strong>{t('form:shoppingCart:location')}</strong>
                  <br />
                  {storefrontData?.companyData.name}
                </div>
                <div className="u-ml">
                  <strong> {t('form:shoppingCart:contractStart')}</strong>
                  <br />
                  {getFullDate(storefrontData?.custom?.contract.startDate)}
                </div>
                <div className="u-ml">
                  <strong> {t('form:shoppingCart:contractEnd')}</strong>
                  <br />
                  {getFullDate(storefrontData?.custom?.contract.endDate)}
                </div>
              </div>
            </div>
          ) as unknown as string
        }
      >
        <ProductTable>
          <ProductTable.Section>
            <DescriptionList split>
              <DescriptionList.Group
                termText={<strong>{t('form:shoppingCart:basePrice')}</strong>}
              >
                <DescriptionList.Detail>
                  {formatPrice(
                    storefrontData?.financialProduct?.rentalPriceTotal
                      ?.basePriceGrossAmount
                  )}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>

          <ProductTable.Section>
            <DescriptionList split>
              <DescriptionList.Group
                termText={t('form:shoppingCart:contractStart')}
              >
                <DescriptionList.Detail>
                  {getFullDate(storefrontData?.custom?.contract.startDate)}
                </DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group
                termText={t('form:shoppingCart:contractEnd')}
              >
                <DescriptionList.Detail>
                  {getFullDate(storefrontData?.custom?.contract.endDate)}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('form:shoppingCart:location')}>
                <DescriptionList.Detail>
                  {storefrontData?.companyData.name} <br />
                  <ProductTable.Comment type="detail">
                    {`${storefrontData?.companyData.address.street} ${storefrontData?.companyData.address.houseNumber}`}
                  </ProductTable.Comment>
                  <ProductTable.Comment type="detail">
                    {`${storefrontData?.companyData.address.zipCode} ${storefrontData?.companyData.address.city}`}
                  </ProductTable.Comment>
                  <br />
                  <ProductTable.Comment type="detail">
                    {
                      (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`mailto:${storefrontData?.companyData.contactData.email}`}
                        >
                          {storefrontData?.companyData.contactData.email}
                        </a>
                      ) as unknown as string
                    }
                  </ProductTable.Comment>
                  <ProductTable.Comment type="detail">
                    {
                      (isMobileDevice() ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`tel:${storefrontData?.companyData.contactData.telephoneNumber}`}
                        >
                          {
                            storefrontData?.companyData.contactData
                              .telephoneNumber
                          }
                        </a>
                      ) : (
                        <>
                          {
                            storefrontData?.companyData.contactData
                              .telephoneNumber
                          }
                        </>
                      )) as unknown as string
                    }
                  </ProductTable.Comment>
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>
          <ProductTable.Section>
            <DescriptionList split>
              {!isFalsy(
                storefrontData.financialProduct?.additionalDrivers?.amount
              ) && (
                <DescriptionList.Group
                  termText={t('form:shoppingCart:additionalDrivers')}
                >
                  <DescriptionList.Detail>
                    {storefrontData.financialProduct?.additionalDrivers?.amount?.toString()}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
              )}

              <DescriptionList.Group
                termText={t('form:shoppingCart:includedMileage')}
              >
                <DescriptionList.Detail>
                  {storefrontData.financialProduct?.includedMileage}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group
                termText={t('form:shoppingCart:excessMileageText')}
              >
                <DescriptionList.Detail>
                  {`${formatNumber(
                    storefrontData?.financialProduct?.excessMileagePriceAmount,
                    2
                  )}
                  ${storefrontData?.financialProduct?.excessMileagePriceUnit}`}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group
                termText={t('form:shoppingCart:minimumAge')}
              >
                <DescriptionList.Detail>
                  {vehicleData?.minAge} {t('form:yearsAlt')}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group
                termText={t('form:shoppingCart:minimumLicense')}
              >
                <DescriptionList.Detail>
                  {vehicleData?.minDurationDriverLicense} {t('form:years')}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>

          {!isFalsy(financialProduct?.additionalProducts?.[0]) && (
            <ProductTable.Section>
              <DescriptionList split>
                {!isFalsy(
                  storefrontData?.financialProduct?.rentalPriceTotal
                    ?.addOnsGrossAmount
                ) && (
                  <DescriptionList.Group
                    termText={<strong>{t('form:shoppingCart:extras')}</strong>}
                  >
                    <DescriptionList.Detail>
                      {formatPrice(
                        storefrontData?.financialProduct?.rentalPriceTotal
                          ?.addOnsGrossAmount
                      )}
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                )}

                {financialProduct?.additionalProducts?.map((additional) => (
                  <DescriptionList.Group key={additional.name} termText={additional.name}>
                    <DescriptionList.Detail>
                      {formatPrice(
                        additional?.additionalProductCostAmount?.grossAmount
                      )}
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                ))}
              </DescriptionList>
            </ProductTable.Section>
          )}
          <ProductTable.Section>
            <DescriptionList split>
              <DescriptionList.Group
                termText={
                  <>
                    <h4>{t('form:shoppingCart:shoppingCartTotalPrice')}</h4>
                    <Paragraph>{t('form:shoppingCart:VATInc')}</Paragraph>
                  </>
                }
              >
                <DescriptionList.Detail>
                  <h4>
                    {formatPrice(
                      financialProduct?.rentalPriceTotal
                        .rentalTotalCostGrossAmount
                    )}
                  </h4>
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>
        </ProductTable>

        <div className="u-mt u-font-size-fs-2">
          {t('form:shoppingCart:afterShoppingCartText')}
        </div>
      </ShoppingCart>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    storefrontData: getStorefrontData(state),
    formData: getFormData(state),
    discount: getDiscount(state),
  }
}

export const ConnectedShoppingCart = connect(mapStateToProps)(
  StorefrontShoppingCart
)
