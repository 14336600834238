import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ButtonContainer,
  ContentSection,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import './style.scss'
import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next'
import { userInfo } from '../../services/api/identification/userinfo'
import { isDev } from '../../services/common/data'
import keycloakClientConfig from '../../keycloak'
import {
  setKeycloak,
  getKeycloakTokens,
} from '../../services/redux/features/keycloak.redux'
import {
  getActualPage,
  setIsLoggedIn as setIsLoggedInAction,
  getIsLoggedIn,
} from '../../services/redux/features/custom.redux'
import { isVWFS } from '../../services/common/utils'

const audiImg = `${process.env.PUBLIC_URL}/images/Audi_ID_Login_Button.png`
const fsIdImg = `${process.env.PUBLIC_URL}/images/FS_ID_Login_Button.png`

const IdentityBroker = (props) => {
  const { setIsLoggedIn, isLoggedIn, entryPointUrl } = props
  const { t } = useTranslation()
  const { keycloak, initialized } = useKeycloak()

  const onOauthConfirm = async () => {
    await setIsLoggedIn(true)
    if (keycloak.token) {
      const userinfo = await userInfo(keycloak?.token)
      if (isDev()) {
        console.log(keycloak.token)
        console.log('userinfo', userinfo)
      }
      const { data } = userinfo
      props.setKeycloak({ ...data })
    }
  }

  const login = useCallback(
    (hint) => {
      keycloak.login({
        idpHint: hint,
        redirectUri: entryPointUrl,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keycloak]
  )

  const oauthValidation = (config) => {
    if (!keycloak.authenticated) {
      login(config.idpHint)
    }
  }

  React.useEffect(() => {
    if (initialized && keycloak.authenticated) {
      setIsLoggedIn(true)
      onOauthConfirm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized])
  // eslint-disable-next-line no-negated-condition
  return !isLoggedIn ? (
    <ContentSection altBackground pageWrap className="u-pt u-pb">
      <Paragraph>
        <strong>{t('oauth:loginButton:title')}</strong>
      </Paragraph>
      <Paragraph>{t('oauth:loginButton:subtitle')}</Paragraph>
      <ButtonContainer left>
        {isVWFS() && (
          <LoginButton
            image={fsIdImg}
            brand="vwfs"
            tFunction={t}
            oauthValidation={oauthValidation}
          />
        )}
        <LoginButton
          image={audiImg}
          brand="audi"
          tFunction={t}
          oauthValidation={oauthValidation}
        />
      </ButtonContainer>
    </ContentSection>
  ) : (
    <></>
  )
}

const LoginButton = (props) => {
  const { brand, image, tFunction, oauthValidation } = props

  return (
    <Button
      secondary
      link
      onClick={() => oauthValidation(keycloakClientConfig[brand])}
    >
      <img
        src={image}
        alt={tFunction(`oauth:loginButton:${brand}`)}
        title={tFunction(`oauth:loginButton:${brand}`)}
      />
    </Button>
  )
}
const mapStateToProps = (state) => {
  return {
    actualPage: getActualPage(state),
    getTokens: getKeycloakTokens(state),
    isLoggedIn: getIsLoggedIn(state),
  }
}

export default connect(mapStateToProps, {
  setKeycloak,
  setIsLoggedIn: setIsLoggedInAction,
})(IdentityBroker)
