import { CustomState } from '../models/CustomState'
import {
  CustomActions,
  SET_TLS_VALID,
  SET_ACTUAL_PAGE,
  SET_ERROR_DATA,
  SET_IS_LOGGED,
  SET_DISCOUNT,
  SET_CONTINUE_JORUNEY,
} from './actions/custom.actions'

export function setTlsValid(data): CustomActions {
  return {
    type: SET_TLS_VALID,
    payload: data,
  }
}

export function getIsLoggedIn(store) {
  return store.custom.data.isLoggedIn
}

export function setIsLoggedIn(data): CustomActions {
  return {
    type: SET_IS_LOGGED,
    payload: data,
  }
}

export function getTlsValid(store) {
  return store.custom.data.tlsValid
}

export function setActualPage(data): CustomActions {
  return {
    type: SET_ACTUAL_PAGE,
    payload: data,
  }
}

export function getActualPage(store) {
  return store.custom.data.actualPage
}

export function setErrorData(data): CustomActions {
  return {
    type: SET_ERROR_DATA,
    payload: data,
  }
}

export function getErrorData(store) {
  return store.custom.data.errorData
}

export function setDiscount(data): CustomActions {
  return {
    type: SET_DISCOUNT,
    payload: data,
  }
}

export function getDiscount(store) {
  return store.custom.data.discount
}

export function setContinueJourney(data): CustomActions {
  return {
    type: SET_CONTINUE_JORUNEY,
    payload: data,
  }
}

export function getContinueJourney(store) {
  return store.custom.data.continueJourney
}

const initialState: CustomState = {
  data: {
    tlsValid: false,
    actualPage: '',
    errorData: {},
    discount: undefined,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TLS_VALID:
      return {
        ...state,
        data: { ...state.data, tlsValid: action.payload },
      }
    case SET_ACTUAL_PAGE:
      return {
        ...state,
        data: { ...state.data, actualPage: action.payload },
      }
    case SET_ERROR_DATA:
      return {
        ...state,
        data: { ...state.data, errorData: action.payload },
      }
    case SET_IS_LOGGED:
      return {
        ...state,
        data: { ...state.data, isLoggedIn: action.payload },
      }
    case SET_DISCOUNT:
      return {
        ...state,
        data: { ...state.data, discount: action.payload },
      }
    case SET_CONTINUE_JORUNEY:
      return {
        ...state,
        data: { ...state.data, continueJourney: action.payload },
      }
    default:
      return state
  }
}
