import { Analytics } from '../../services/analytics'

export const trackOpenContact = actualPage => {
  const defaultTrackingData = {
    [Analytics.keys.pageName()]: actualPage,
    [Analytics.keys.viewChange()]: actualPage,
  }
  Analytics.setTrackingData({
    ...defaultTrackingData,
    [Analytics.keys.linkInformation(0)]: 'Contact',
    [Analytics.keys.eventType(0)]: 'interAction',
    [Analytics.keys.eventAction(0)]: 'Success',
  })
  Analytics.trackInteraction()
}
