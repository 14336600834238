import { returnAvailableValues } from "../utils"

const getInitialValues = ({ formData, storefrontData }) => {
  return {
    mitConsent:
      formData.paymentDetails?.mitConsent ||
      !!formData?.paymentDetails?.paymentSeriesId ||
      !!storefrontData?.customerData?.paymentData?.paymentSeriesId ||
      false,
    paymentData: returnAvailableValues({
      paths: {
        formData: formData?.paymentDetails?.paymentData,
        storefrontData: storefrontData?.customerData?.paymentData,
      },
    }),
  }
}

export default getInitialValues
