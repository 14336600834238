import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { ContentSection, Spinner } from '@vwfs-bronson/bronson-react'
import { history } from '../../routing'
import routes from '../../../routes'
import { loadStorefrontDataToRedux } from './storefront.service'
import { continueBasedOnStatus } from './continueBasedOnStatus'

const loadData = id => loadStorefrontDataToRedux(id)

const SpinnerSection = () => {
  return (
    <ContentSection pageWrap className="u-m-xlarge">
      <Spinner section />
    </ContentSection>
  )
}

export type WithStorefrontProps = {
  storefrontId: string
}

const WithStorefront: FunctionComponent<WithStorefrontProps> = ({
  storefrontId,
  children,
}) => {
  const [isLoading, setLoading] = React.useState(true)

  useEffect(() => {
    if (storefrontId) {
      loadData(storefrontId)
        .then((response) => {
          history.push(continueBasedOnStatus(response?.payload?.data))
        })
        .catch(() => {
          history.pushPreservePath(routes.apiErrorPage)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [storefrontId])

  return (
    <>
      {isLoading && <SpinnerSection />}
      {!isLoading && children}
    </>
  )
}

export default connect(null)(WithStorefront)
