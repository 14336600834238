import React from 'react'

import {
  Fieldset,
  Input,
  Layout,
  RadioButton,
  RadioButtonGroup,
  Checkbox,
  InfoIcon,
} from '@vwfs-bronson/bronson-react'
import get from 'lodash/get'
import { Select } from '../../../components/Select'
import { FormField } from '../../../components/FormField'
import { InputDate } from '../../../components/InputDate/InputDate'

export const hasPartnerStatus = ['Casado(a)', 'União de Facto']

const Salutation = ({
  t,
  values,
  setFieldValue,
  dataModels: { salutationsList },
}) => (
  <FormField
    testId="salutation"
    name="salutation"
    type="other"
    labelText={t('personal-details:salutation')}
    render={fieldProps => (
      <RadioButtonGroup>
        {salutationsList.map(item => (
          <RadioButton
            {...fieldProps}
            key={item.value}
            checked={values.salutation === item.value}
            onChange={() => setFieldValue('salutation', item.value)}
          >
            {item.label}
          </RadioButton>
        ))}
      </RadioButtonGroup>
    )}
  />
)

const Title = ({ t, dataModels: { titles } }) => (
  <FormField
    testId="title"
    type="select"
    name="title"
    labelText={t('personal-details:titleLabel')}
    render={fieldProps => (
      <Select defaultOption values={titles} {...fieldProps}>
        {titles.map(({ value, label }) => (
          <Select.Item key={`${value}${label}`} value={value}>
            {label}
          </Select.Item>
        ))}
      </Select>
    )}
  />
)

const FirstName = ({ t }) => (
  <FormField
    testId="firstName"
    type="input"
    name="firstName"
    labelText={t('personal-details:firstName')}
    render={fieldProps => <Input {...fieldProps} />}
  />
)

const LastName = ({ t }) => (
  <FormField
    testId="lastName"
    type="input"
    name="lastName"
    labelText={t('personal-details:lastName')}
    render={fieldProps => <Input {...fieldProps} />}
  />
)

const DateOfBirth = ({ t }) => (
  <FormField
    testId="dateOfBirth"
    type="other"
    name="dateOfBirth"
    labelText={t('personal-details:dateOfBirth')}
    infoIcon={t('personal-details:dateOfBirth_tooltip')}
    render={fieldProps => <InputDate {...fieldProps} />}
  />
)

const LicenseCheckbox = ({ t, values, storefrontData }) => (
  <FormField
    testId="licenseCheck"
    type="checkbox"
    name="licenseCheck"
    render={fieldProps => (
      <Checkbox {...fieldProps} checked={get(values, 'licenseCheck', '')}>
        {storefrontData?.vehicleData?.minDurationDriverLicense > 0
          ? t('personal-details:licenseCheck:filled:label', {
              minDurationDriverLicense:
                storefrontData?.vehicleData?.minDurationDriverLicense,
            })
          : t('personal-details:licenseCheck:empty:label')}
        <InfoIcon icon="semantic-info">
          {storefrontData?.vehicleData?.minDurationDriverLicense > 0
            ? t('personal-details:licenseCheck:filled:tooltip')
            : t('personal-details:licenseCheck:empty:tooltip')}
        </InfoIcon>
      </Checkbox>
    )}
  />
)

const RenderFieldset = (
  t,
  dataModels,
  values,
  setFieldValue,
  storefrontData
) => {
  return (
    <Fieldset>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <Salutation
              t={t}
              values={values}
              setFieldValue={setFieldValue}
              dataModels={dataModels}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <Title t={t} dataModels={dataModels} />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FirstName t={t} />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <LastName t={t} />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <DateOfBirth t={t} />
          </Layout.Item>
          <Layout.Item default="1/1" s="1/1">
            <LicenseCheckbox
              t={t}
              values={values}
              storefrontData={storefrontData}
            />
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </Fieldset>
  )
}

export default RenderFieldset
