/* eslint-disable no-unused-vars */
import React from 'react'

export enum IconSize {
  small = 'small',
  large = 'large',
  medium = 'medium',
}

export interface IconProps {
  icon: string
  size: IconSize
  className?: string
}

const Icon = ({ icon, size, className }: IconProps) => {
  return (
    <i
      className={`c-icon  c-icon--[${icon}]  c-icon--${size} ${className ||
        ''}`}
    ></i>
  )
}

export default Icon
