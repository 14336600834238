import React, { useCallback, useMemo } from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  ErrorMessage,
  Fieldset,
  Form,
  Spinner,
  Toggle,
  Paragraph,
  Card,
  Layout,
} from '@vwfs-bronson/bronson-react'
import getInitialValues from './initial-values'
import ValidationSchema from './validation-schema'
import {
  scrollToElement,
  isValidationDisabled,
} from '../../../services/common/form'
import { getDataModels } from '../../../services/redux/features/data-models.redux'

import {
  saveFormData,
  getFormData,
} from '../../../services/redux/features/form.redux'

import { storePaymentData } from '../../../services/api/forms/payment/initialize'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { FormField } from '../../../components/FormField'
import PaymentWidget from './components/PaymentWidget'
import { resetOpenSectionState } from '../../../components/FormSection'
import Icon, { IconSize } from '../../../components/Icon/Icon'
import { trackPage } from './tracking'
import { confirmPayment } from '../../../services/api/forms/payment/confirm'

const PaymentDetails = (props, ref) => {
  const { updateValidSections, storefrontData } = props

  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    getData: () => {
      return formState.current.values
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      props.onValid(formState.current.isValid)
      return formState.current.isValid
    },
  }))
  const { dataModels, formData } = props
  const formState = React.useRef({} as any)
  const [initialValid, setInitialValid] = React.useState<boolean | null>(null)
  const [errors, setErrors] = React.useState(undefined as any)
  const [isLoading, setisLoading] = React.useState(false)

  const paymentData = useMemo(() =>
    formData?.paymentDetails?.paymentData || storefrontData?.customerData?.paymentData,
    [formData, storefrontData]
  )
  const paymentTransactionId = useMemo(() => paymentData?.paymentSeriesId, [paymentData?.paymentSeriesId])
  const [mitConsent, setMitConsent] = React.useState(
    formData?.paymentDetails?.mitConsent || !!paymentData?.paymentSeriesId || false
  )
  const [paymentError, setPaymentError] = React.useState('')

  const onSubmit = values => {}

  const onChange = e => {
    updateValidSections('paymenttDetails', false)
    if (!isValidationDisabled(e.target)) {
      props.onValid(false)
    }
  }

  const onChangeMitConsent = (e, setFieldValue) => {
    setPaymentError('')
    const value = e.target?.checked
    setMitConsent(value)
    setFieldValue('mitConsent', value)
    if (value && !paymentTransactionId) {
      setisLoading(true)
      storePaymentData({ mitAcceptance: value })
        .then((response: any) => {
          setisLoading(false)
          props.saveFormData({
            ...formData,
            paymentDetails: {
              ...formData?.paymentDetails,
              mitConsent: value,
              paymentData: {
                ...storefrontData?.customerData?.paymentData,
                ...formData?.paymentDetails?.paymentData,
                paymentSeriesId: response?.id,
              }
            }
          })
        })
        .catch(() => {
          setisLoading(false)
          props.onErrorFetch()
        })
    }
  }

  React.useEffect(() => {
    if (
      errors !== undefined &&
      Object.entries(errors).length !== 0 &&
      errors.constructor === Object
    ) {
      scrollToElement('.is-error', 'start')
    }
  }, [errors])

  const { t } = useTranslation('payment-details')

  const isInitialValid = formikProps => {
    if (initialValid !== null) {
      return initialValid
    }
    if (ValidationSchema) {
      const isValid = ValidationSchema.isValidSync(formikProps.initialValues)
      setInitialValid(isValid)
      return isValid
    }
    return false
  }

  const handleBeforeSubmit = () => {
    setisLoading(true)
    return true
  }

  const handleSuccess = async () => {
    setisLoading(true)
    const smartPayData = await confirmPayment()
    props.saveFormData({
      ...formData,
      paymentDetails: {
        ...formData?.paymentDetails,
        paymentData: {
          ...storefrontData?.customerData?.paymentData,
          ...formData?.paymentDetails?.paymentData,
          ...smartPayData?.customerData?.paymentData,
        }
      }
    })
    setPaymentError('')
    resetOpenSectionState()
    updateValidSections('paymentDetails', true)
  }

  const handleOnError = error => {
    setPaymentError(error || true)
  }

  const showPaymentWidget = useCallback(() => {
    return paymentTransactionId && mitConsent
  }, [paymentTransactionId, mitConsent])

  const showToggle = useCallback(() => {
    return !paymentTransactionId && !mitConsent
  }, [paymentTransactionId, mitConsent])

  return (
    <>
      {isLoading && <Spinner fullPage />}
      {Object.keys(dataModels).length ? (
        <Formik
          enableReinitialize
          isInitialValid={isInitialValid}
          initialValues={getInitialValues({
            formData,
            storefrontData,
          })}
          validationSchema={ValidationSchema}
          validateOnMount
          onSubmit={onSubmit}
          render={formProps => {
            const { values, handleSubmit, setFieldValue } = formProps
            setErrors(formProps.errors)
            formState.current = { values, isValid: formProps.isValid }
            submitFunction = formProps.submitForm
            const handleSubmitOwn = e => {
              trackPage()
              handleSubmit(e)
            }

            return (
              <Form onChange={onChange} onSubmit={handleSubmitOwn} floatingLabel>
                <Fieldset>
                  <Fieldset.Row className="u-mt-small">
                    <FormField
                      testId="title"
                      type="select"
                      name="mitConsent"
                      labelText=""
                      render={() => (
                        <Card>
                          {showToggle() ? (
                            <Layout middle>
                              <Layout.Item default="1/4" l="1/1">
                                <Toggle
                                  name="mitConsent"
                                  inputType="checkbox"
                                  onOff
                                  onChange={e => {
                                    onChangeMitConsent(e, setFieldValue)
                                  }}
                                >
                                  {t('payment-details:mitToggleText')}
                                </Toggle>
                              </Layout.Item>
                              <Layout.Item default="3/4" l="1/1">
                                <Paragraph>
                                  <b>{t('payment-details:heading')}</b>
                                </Paragraph>
                                <Paragraph>
                                  {t('payment-details:text1')}
                                </Paragraph>
                                <Paragraph className="u-m-none">
                                  {t('payment-details:text2')}
                                </Paragraph>
                                <Paragraph className="u-m-none">
                                  {t('payment-details:text3', {
                                    companyName:
                                      storefrontData?.companyData?.name,
                                  })}
                                </Paragraph>
                                <Paragraph className="u-m-none">
                                  {t('payment-details:text4', {
                                    address: `${storefrontData?.companyData?.address?.street} ${storefrontData?.companyData?.address?.houseNumber}, ${storefrontData?.companyData?.address?.zipCode} ${storefrontData?.companyData?.address?.city}`,
                                  })}
                                </Paragraph>
                                <Paragraph>
                                  {t('payment-details:text5')}
                                </Paragraph>
                                <Paragraph>
                                  {t('payment-details:text6')}
                                </Paragraph>
                                <Paragraph
                                  className="u-font-size-fs-2"
                                  dangerouslySetInnerHTML={{
                                    __html: t('payment-details:text7'),
                                  }}
                                />
                              </Layout.Item>
                            </Layout>
                          ) : (
                            <div style={{ display: 'flex' }}>
                              <Paragraph
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  margin: 0,
                                }}
                              >
                                <Icon
                                  icon="semantic-checkmark"
                                  size={IconSize.medium}
                                  className="u-mr-small"
                                />
                                {t('payment-details:confirmation')}
                              </Paragraph>
                            </div>
                          )}
                        </Card>
                      )}
                    />
                  </Fieldset.Row>
                </Fieldset>

                {showPaymentWidget() && (
                  <>
                    <PaymentWidget
                      paymentTransactionId={paymentTransactionId}
                      onBeforeSubmit={handleBeforeSubmit}
                      onError={handleOnError}
                      onSuccess={handleSuccess}
                    />
                    {paymentError && (
                      <div>
                        <ErrorMessage>
                          {t('payment-details:paymentError')}
                        </ErrorMessage>
                      </div>
                    )}
                  </>
                )}
              </Form>
            )
          }}
        />
      ) : (
        <div className="u-p-xlarge">
          <Spinner section />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    dataModels: getDataModels(state),
    formData: getFormData(state),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps, { saveFormData }, null, {
  forwardRef: true,
})(React.forwardRef(PaymentDetails))
