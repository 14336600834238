import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useKeycloak } from '@react-keycloak/web'
import { logOut } from './services'
import { store } from '../../services/redux'
import { setIsLoggedIn } from '../../services/redux/features/custom.redux'

export type HeaderProps = {}

const Logout: FunctionComponent<HeaderProps> = () => {
  const { t } = useTranslation()
  const { keycloak, initialized } = useKeycloak()

  const loggOut = async e => {
    e.preventDefault()
    try {
      await logOut({
        token: keycloak.token,
        clientId: keycloak.clientId,
        clientSecret: keycloak.clientSecret,
        refreshToken: keycloak.refreshToken,
      })
      keycloak.clearToken()
      store.dispatch(setIsLoggedIn(false))
    } catch {}
  }

  return (
    initialized &&
    keycloak.authenticated ? (
      <li>
        <a
          className="c-context-bar__link"
          href="#/"
          onClick={loggOut}
          rel="noopener noreferrer"
        >
          <span>{t('app:header:logout')}</span>
        </a>
      </li>
    )
    : <></>
  )
}
export default Logout
