import { Analytics } from '../../services/analytics'

export const pageName = 'Browser navigation unavailable'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = () => {
  setTimeout(() => {
    const pageData = {
      [Analytics.keys.eventType(0)]: 'pageView',
      [Analytics.keys.eventAction(0)]: 'Success',
    }
    Analytics.setTrackingData({ ...defaultTrackingData, ...pageData })
    Analytics.trackPage()
  }, 1000)
}
