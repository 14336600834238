import { Analytics } from '../../../services/analytics'
import { getErrorFields } from '../utils'

export const pageName = 'home'

export const trackPage = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.formType()]: 'Personal data',
    [Analytics.keys.formName()]: 'Contact data',
    [Analytics.keys.viewChange()]: 'Contact data',
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  })
  Analytics.trackPage()
}

export const trackDirty = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.formType()]: 'Personal data',
    [Analytics.keys.formName()]: 'Contact data',
    [Analytics.keys.viewChange()]: 'Contact data',
    [Analytics.keys.eventType(0)]: 'Form',
    [Analytics.keys.eventAction(0)]: 'Start typing',
    [Analytics.keys.linkInformation(0)]: 'Start typing',
  })
  Analytics.trackInteraction()
}

export const trackSuccess = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: 'Contact data',
    [Analytics.keys.linkInformation(0)]: 'Contact data completed',
    [Analytics.keys.eventType(0)]: 'ProductInformation:Contact Information',
    [Analytics.keys.eventAction(0)]: 'Provided',
  })
  Analytics.trackInteraction()
}

export const trackErrors = (errors: any, callback: Function) => {
  const errorFields = getErrorFields(errors)
  if (errorFields) {
    callback(false)
    Analytics.setTrackingData({
      [Analytics.keys.pageName()]: pageName,
      [Analytics.keys.formType()]: 'Personal data',
      [Analytics.keys.formName()]: 'Contact data',
      [Analytics.keys.viewChange()]: 'Contact',
      [Analytics.keys.eventType(0)]: 'Form',
      [Analytics.keys.eventAction(0)]: 'Field error',
      [Analytics.keys.linkInformation(0)]: 'Field error',
      [Analytics.keys.errorFields()]: errorFields,
    })
    Analytics.trackInteraction()
  }
}

export const trackAbort = (lastTouchedField: string) => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.formType()]: 'Personal data',
    [Analytics.keys.formName()]: 'Contact data',
    [Analytics.keys.viewChange()]: 'Contact',
    [Analytics.keys.eventType(0)]: 'Form',
    [Analytics.keys.eventAction(0)]: 'Aborted',
    [Analytics.keys.linkInformation(0)]: 'Form abort',
    [Analytics.keys.lastTouchedField()]: lastTouchedField,
  })
  Analytics.trackInteraction()
}
