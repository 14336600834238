import axios from 'axios'
import { store } from '../../../redux'

const { REACT_APP_API_GATEWAY, REACT_APP_BACKEND_BRAND } = process.env

export const confirmPayment = (): Promise<any> => {
  const country = 'de'
  const dataStorageId = store.getState().storefront.id
  const url = `${REACT_APP_API_GATEWAY}/payment/${country}/${REACT_APP_BACKEND_BRAND}/confirm/${dataStorageId}`
  return axios.post(url).then((response) => {
    return response.data
  })
}
