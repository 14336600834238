/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS environment variable we are able to
 * either export the real or mocked implementation, so that from the application
 * point of view nothing changes and it can continue calling the analytics logic
 * as per usuall.
 */
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import merge from 'lodash/merge'
import get from 'lodash/get'

const mock = {
  pageName: '',
  default: {},
  datalayer: {},
  pageBottom: function pageBottom() {},
  updateScreenSize: function updateScreenSize() {
    if (window.innerWidth > 1920) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xxl',
      })
    } else if (window.innerWidth >= 1600) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xl',
      })
    } else if (window.innerWidth >= 1280) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'l',
      })
    } else if (window.innerWidth >= 960) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'm',
      })
    } else if (window.innerWidth >= 720) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 's',
      })
    } else if (window.innerWidth >= 480) {
      this.addToDatalayer({
        'design.browserResolutionBreakpoint': 'xs',
      })
    }
  },
  setDefaults: function setDefaults(obj) {
    Object.assign(this.default, obj)
  },
  getFromDatalayer(key) {
    return get(this.datalayer, key)
  },
  addToDatalayer(additions) {
    Object.keys(additions).forEach(key => {
      set(this.datalayer, key, additions[key])
    })
  },

  setTrackingData(additions) {
    const clonedDefaultDatalayer = cloneDeep(this.default)
    const trackingData = {}
    Object.keys(additions).forEach(key => {
      set(trackingData, key, additions[key])
    })
    window.du_digitalData = merge(
      {},
      clonedDefaultDatalayer,
      this.datalayer,
      trackingData
    )
  },
  trackViewChange: function viewChange() {
    this.track('viewChange')
  },

  trackPage: function page() {
    this.track('page')
  },

  trackInteraction: function interaction() {
    this.track('interaction')
  },

  track: function track(action) {
    if (typeof action !== 'string') {
      // eslint-disable-next-line no-console
      console.warn('Legacy tracking code detected')
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    if (!implementation.isLive || window.__CJ_DEBUG_ANALYTICS) {
      // eslint-disable-next-line no-console
      console.log(
        `track ${action}\n pageName: ${get(
          window.du_digitalData,
          this.keys.pageName()
        )}\n viewChange: ${get(
          window.du_digitalData,
          this.keys.viewChange()
        )}\n event0.type: ${get(
          window.du_digitalData,
          this.keys.eventType(0)
        )}\n event0.action: ${get(
          window.du_digitalData,
          this.keys.eventAction(0)
        )}\n\n Datalayer:`,
        window.du_digitalData
      )
    }
    // eslint-disable-next-line
    window._satellite && window._satellite.track && window._satellite.track(action)
  },
  keys: {
    formType: () => 'form.type',
    formName: () => 'form.name',
    errorFields: () => 'form.errorFields',
    lastTouchedField: () => 'form.lastTouchedField',
    pageName: () => 'core.pageInfo.pageName',
    viewChange: () => 'core.attributes.viewChange',
    eventType: eventCount => `event[${eventCount}].eventInfo.eventType`,
    eventAction: eventCount => `event[${eventCount}].eventInfo.eventAction`,
    linkInformation: eventCount =>
      `event[${eventCount}].eventInfo.linkInformation`,
    errorCode: () => 'error.errorCode',
    errorMessage: () => 'error.errorMessage',
    errorCausingURL: () => 'error.errorCausingURL',
    dealerDataCompanyId: () => 'dealerData.companyId',
    dealerDataCompanyName: () => 'dealerData.companyName',
    dealerDataAddressStreet: () => 'dealerData.address.street',
    dealerDataZipCode: () => 'dealerData.address.zipCode',
    dealerDataCity: () => 'dealerData.address.city',
    dealerDataState: () => 'dealerData.address.state',
    addons: eventCount => `product[0].attributes.addOns[${eventCount}].name`,
  },
}

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom() {
    window._satellite && window._satellite.track && window._satellite.pageBottom() // eslint-disable-line
  },
}

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS) {
  implementation = real
}

// let implementation = real // eslint-disable-line

export { implementation as Analytics }
