import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { DefaultLayout } from '../Layout'
import AppForm from '../../pages/AppPage'
import SummaryPage from '../../pages/SummaryPage'
import ErrorPage from '../../pages/ErrorPage'
import ConfirmationPage from '../../pages/ConfirmationPage'
import { getBrandName } from '../../config'
import routes from '../../routes'
import { Page } from '../../models/page'
import ThirdPartyLicensePage from '../../pages/LicensesPage/ThirdPartyLicensePage'
import WithStorefront from '../../services/api/storefront/with-storefront'
import { NavigationBlockedModal } from '../../components/NavigationBlockedModal/index'

const Lazy = props => {
  const { page, brand, ...componentProps } = props
  const [component, setComponent] = React.useState(null as any)
  React.useLayoutEffect(() => {
    if (page === '' || !brand) {
      return
    }
    setComponent(null)
    setComponent(() =>
      React.lazy(() =>
        import(`../../resources/${brand}/pages/${page}.js`).catch(() => {
          return import(`../../resources/base/pages/${page}.js`)
        })
      )
    )
  }, [page, brand])
  if (page === '' || !brand) {
    return null
  }
  if (component) {
    return React.createElement(component, componentProps)
  }
  return null
}

export type RoutesProps = {
  pages: Page[]
  storefrontId: string
  identificationId: string
  getPageFile: any
}

const Routes = ({
  pages,
  storefrontId,
  entryUrl,
  getPageFile,
}) => {
  const [brand] = React.useState(getBrandName())
  return (
    <WithStorefront storefrontId={storefrontId}>
      <Route component={NavigationBlockedModal} />
      <Switch>
        <Route
          path="/page/:pageName"
          render={routeProps => (
            <DefaultLayout pages={pages}>
              <Lazy
                {...routeProps}
                page={getPageFile(routeProps.match.params.pageName)}
                brand={getBrandName()}
              />
            </DefaultLayout>
          )}
        />
        <Route
          exact
          path={routes.editPage}
          render={editProps => {
            return (
              <DefaultLayout pages={pages}>
                <AppForm {...editProps} storefrontId={storefrontId} editPage />
              </DefaultLayout>
            )
          }}
        />
        <Route
          path={routes.summaryPage}
          render={() => (
            <DefaultLayout pages={pages}>
              <SummaryPage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.errorPage}
          render={() => {
            return (
              <DefaultLayout pages={pages}>
                <ErrorPage entryUrl={entryUrl} />
              </DefaultLayout>
            )
          }}
        />
        <Route
          path={routes.apiErrorPage}
          render={() => {
            return (
              <DefaultLayout pages={pages}>
                <ErrorPage isApi entryUrl={entryUrl} />
              </DefaultLayout>
            )
          }}
        />
        <Route
          path={routes.confirmationPage}
          render={() => (
            <DefaultLayout pages={pages}>
              <ConfirmationPage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.landingPage}
          render={routeProps => (
            <DefaultLayout pages={pages}>
              <AppForm
                {...routeProps}
                storefrontId={storefrontId}
                brand={brand}
                entryUrl={entryUrl}
              />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.licenses}
          render={() => (
            <DefaultLayout pages={pages}>
              <ThirdPartyLicensePage />
            </DefaultLayout>
          )}
        />

        <Redirect from="*" to="/prefill" />
      </Switch>
    </WithStorefront>
  )
}

export default connect(null)(Routes)
