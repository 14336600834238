export const SELECT_CHOOSE_VALUE = {
  label: 'Wählen Sie...',
  value: 'NONE',
}

export const MIN_YEARS_OF_RESIDENCE = 3
export const MIN_MONTHS_OF_EMPLOYMENT = 6
const envs = ['.dev.', '.int.', '.cons.', 'localhost']
export const isDev = () => {
  if (new RegExp(envs.join('|')).test(window.location.host)) {
    return true
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true
  }
  return false
}

export const getFullDate = newDate => {
  // Formats datetime to dd.mm.yyyy, hh:mm (DE format), no timezone conversion
  const date = new Date(newDate).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const hh = String(new Date(newDate).getUTCHours()).padStart(2, '0')
  const mm = String(new Date(newDate).getUTCMinutes()).padStart(2, '0')
  return `${date}, ${hh}:${mm}`
}

export const getAnalyticsDate = newDate => {
  // Formats datetime to YYYY-MM-DD
  const date = new Date(newDate)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-')
  return `${date}`
}

export const calculateDurationDays = ({ dateFrom, dateTo }) => {
  const date1 = new Date(dateFrom)
  const date2 = new Date(dateTo)
  const timeDiff = date2.getTime() - date1.getTime()
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24))
  return daysDiff
}
