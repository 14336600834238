import { isAudi } from '../../config'
import { Analytics } from '../../services/analytics'

export const pageName = 'Confirmation'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.eventType(1)]: 'Sale',
    [Analytics.keys.eventAction(1)]: 'Success',
    [Analytics.keys.eventType(2)]: 'Product journey',
    [Analytics.keys.eventAction(2)]: 'End',
  }
  Analytics.setTrackingData({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
  Analytics.trackPage()
}

export const trackRedirectButton = () => {
  if (isAudi()) {
    const specificTrackingData = {
      [Analytics.keys.eventType(0)]: 'interAction',
      [Analytics.keys.eventAction(0)]: 'Success',
      [Analytics.keys.linkInformation(0)]: 'Goto Audi On Demand',
    }
    Analytics.setTrackingData({
      ...defaultTrackingData,
      ...specificTrackingData,
    })
    Analytics.trackInteraction()
  }
}
