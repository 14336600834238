import { formatDate, returnAvailableValues } from '../utils'

const getInitialValues = ({ formData, keycloakData, storefrontData }) => {
  return {
    salutation: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.salutation,
        keycloakData: keycloakData?.customerData?.salutation,
        storefrontData: storefrontData?.customerData?.salutation,
      },
    }),
    title: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.title,
        keycloakData: keycloakData?.customerData?.title,
        storefrontData: storefrontData?.customerData?.title,
      },
      defaultValue: 'NONE',
    }),
    firstName: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.firstName,
        keycloakData: keycloakData?.customerData?.firstName,
        storefrontData: storefrontData?.customerData?.firstName,
      },
    }),
    lastName: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.lastName,
        keycloakData: keycloakData?.customerData?.lastName,
        storefrontData: storefrontData?.customerData?.lastName,
      },
    }),
    dateOfBirth: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.dateOfBirth,
        keycloakData: keycloakData?.customerData?.dateOfBirth,
        storefrontData: storefrontData?.customerData?.dateOfBirth,
      },
      formater: field => formatDate(field),
    }),
    licenseCheck: returnAvailableValues({
      paths: {
        formData: formData?.personalDetails?.licenseCheck,
      },
      defaultValue: false,
    }),
  }
}

export default getInitialValues
