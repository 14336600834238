import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { ButtonContainer } from '@vwfs-bronson/bronson-react'

import { SmartPay } from '../../../../components/SmartPay'

export type PaymentWidgetProps = {
  paymentTransactionId: string | undefined
  onBeforeSubmit: any
  onError: any
  onSuccess: any
}

export const PaymentWidget: FunctionComponent<PaymentWidgetProps> = ({
  paymentTransactionId,
  onBeforeSubmit,
  onError,
  onSuccess,
}) => {
  return (
    <>
      <SmartPay
        transactionId={paymentTransactionId}
        onBeforeSubmit={onBeforeSubmit}
        onError={onError}
        onSuccess={onSuccess}
        sdkUrl={process.env.REACT_APP_SMARTPAY_SDK_URL}
        customSubmitButtonId="smp-purchase-button"
      />
      <ButtonContainer className="u-mb">
        <div id="smp-purchase-button"></div>
      </ButtonContainer>
    </>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(PaymentWidget)
