import escapeRegExp from 'lodash/escapeRegExp'

import {
  DATE_SEPARATOR,
  NUMBER_FORMAT,
  THOUSANDS_SEPARATOR,
  DECIMAL_SEPARATOR,
  CURRENCY_SYMBOL,
} from '../../config'

export function formatData(data) {
  return data || '-'
}

export const formatNumber = (
  value,
  fractionDigits = 0,
  unit = '',
  unitPrefix = ' '
) => {
  if (!(value || value === 0) || !/^[0-9.,]*$/.test(value)) return ''
  const float = typeof value === 'number' ? value : parseFloatFormat(value)
  let formattedNumber = float.toFixed(fractionDigits)
  formattedNumber = Intl.NumberFormat(NUMBER_FORMAT, {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(formattedNumber)
  return `${formattedNumber}${unit ? `${unitPrefix}${unit}` : ''}`
}

export const parseFloatFormat = value => {
  return value
    ? parseFloat(
        value
          .toString()
          .replace(new RegExp(escapeRegExp(THOUSANDS_SEPARATOR), 'g'), '')
          .replace(new RegExp(escapeRegExp(DECIMAL_SEPARATOR), 'g'), '.')
      )
    : null
}

export const parseIntFormat = value => {
  return value ? parseInt(value, 10) : null
}

export const formatPrice = (value, thousandsSeparator = null) => {
  const formattedPrice = formatNumber(value, 2, CURRENCY_SYMBOL)
  if (!formattedPrice.length) return ''
  return thousandsSeparator
    ? formattedPrice.replace(
        new RegExp(escapeRegExp(THOUSANDS_SEPARATOR), 'g'),
        thousandsSeparator
      )
    : formattedPrice
}

export const formatPercentage = value => {
  return formatNumber(
    typeof value === 'number' ? value * 100 : null,
    2,
    '%',
    ''
  )
}

export function getYearsAndMonthsSum(years, months) {
  return parseInt(years || 0, 10) * 12 + parseInt(months || 0, 10)
}

export function date(str) {
  try {
    const d = new Date(str)
    return `${d.getDate() < 10 ? '0' : ''}${d.getDate()}${DATE_SEPARATOR}${
      d.getMonth() < 9 ? '0' : ''
    }${d.getMonth() + 1}${DATE_SEPARATOR}${d.getFullYear()}`
  } catch (e) {
    console.error('not valid date', e)
    return ''
  }
}

export function calcDate(baseDate, years, month) {
  const d = new Date(baseDate)
  d.setMonth(d.getMonth() - month)
  d.setYear(d.getFullYear() - years)
  return `${d.getDate()}${DATE_SEPARATOR}${parseInt(d.getMonth(), 10) +
    1}${DATE_SEPARATOR}${d.getFullYear()}`
}

export function convertDateString(dateStr) {
  const dateSplited = dateStr.split(DATE_SEPARATOR)
  return new Date(
    parseInt(dateSplited[2], 10),
    parseInt(dateSplited[1], 10) - 1,
    dateSplited[0],
    12,
    0
  )
}

function zeroPad(num, places) {
  const zero = places - num.toString().length + 1
  return Array(+(zero > 0 && zero)).join('0') + num
}

export function convertDate(
  dateStr,
  options = { from: 'DD.MM.YYYY', to: 'YYYY-MM-DD' }
) {
  // TODO: implement other options if required, add tests
  if (options.from !== 'DD.MM.YYYY' && options.to !== 'YYYY-MM-DD') {
    return dateStr
  }
  const parts = dateStr.split('.')
  const DD = Number.parseInt(parts[0], 10)
  const MM = Number.parseInt(parts[1], 10)
  const YYYY = Number.parseInt(parts[2], 10)
  return `${YYYY}-${zeroPad(MM, 2)}-${zeroPad(DD, 2)}`
}

export function formatISODate(date_) {
  return convertDateString(date_).toISOString()
}

export function checkPPSN(number) {
  const validationRegex = /^(\d{7})([A-Z]{1,2})$/i

  if (!validationRegex.test(number)) return false

  const numericPart = RegExp.$1
  const checksumCharacter = RegExp.$2
  let multiplyingFactor = 8
  let sum = 0

  for (let i = 0; i < numericPart.length; i += 1) {
    sum += numericPart[i] * multiplyingFactor
    multiplyingFactor -= 1
  }

  if (RegExp.$2[1]) {
    sum += (RegExp.$2[1].toUpperCase().charCodeAt(0) - 64) * 9
  }

  const checksum = sum % 23

  return checksum + 64 === checksumCharacter.charCodeAt(0)
}

export function formatDistance(distance, unit) {
  const unitsMap = {
    KILOMETERS: 'km',
    MILES: 'mi',
  }

  return `${formatNumber(distance)}${unitsMap[unit]}`
}

export function formatMileageLabel(label) {
  switch (label) {
    case 'KILOMETERS':
      return 'km'
    case 'MILES':
      return 'Meilen'
    default:
      return ''
  }
}

export function formatDurationUnit(label) {
  switch (label) {
    case 'MONTHS':
      return 'Monate'
    default:
      return ''
  }
}

function formatFileSize(size) {
  if (size <= 0) {
    return '0 B'
  }
  const units = ['B', 'kB', 'MB', 'GB', 'TB']
  const digitGroups = Math.min(
    Math.floor(Math.log10(size) / Math.log10(1024)),
    units.length - 1
  )

  const value = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 1,
  }).format(size / 1024 ** digitGroups)

  return `${value} ${units[digitGroups]}`
}

export { formatFileSize }

export function formatCurrencyToEuro(currency) {
  return Number(currency)
    .toFixed(2)
    .replace('.', ',')
}

export const removeCountryCode = (phoneNumber, codeList) => {
  if (!phoneNumber) return undefined
  const found = codeList.find(item => phoneNumber.includes(item.value))
  return phoneNumber.replace(found.value, '')
}

export const extractCountryCode = (phoneNumber, codeList) => {
  if (!phoneNumber) return undefined
  const found = codeList.find(item => phoneNumber.includes(item.value))
  return found.value
}
