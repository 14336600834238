import React from 'react'
import { DescriptionList, Layout } from '@vwfs-bronson/bronson-react'

function Component({ ...props }) {
  const { children: items, itemClass } = props
  return (
    <DescriptionList grid>
      {items
        .filter(item => {
          return (
            item.value && item.value !== ' ' && item.value !== '' && !item.hide
          )
        })
        .map(item => (
          <DescriptionList.Group
            termText={item.label}
            key={item.label}
            className={itemClass}
          >
            {Array.isArray(item.value) ? (
              <DescriptionList.Detail>
                <Layout>
                  {item.value.map((i, index) => (
                    <Layout.Item
                      key={`${i.label}-${index}`}
                      default="1/1"
                      className={`${index === 0 ? '' : 'u-mt-none'}`}
                    >
                      <DescriptionList split>
                        <DescriptionList.Group
                          termText={<strong className="u-font-size-fs1">{i.label}</strong>}
                          key={i.label}
                        >
                          <DescriptionList.Detail>
                            {i.value}
                          </DescriptionList.Detail>
                        </DescriptionList.Group>
                      </DescriptionList>
                    </Layout.Item>
                  ))}
                </Layout>
              </DescriptionList.Detail>
            ) : (
              <DescriptionList.Detail>
                <span dangerouslySetInnerHTML={{ __html: item.value }} />
              </DescriptionList.Detail>
            )}
          </DescriptionList.Group>
        ))}
    </DescriptionList>
  )
}

export { Component as ArrayDefinitionList }
