import merge from 'lodash/merge'
import { Analytics } from '../../../services/analytics'
import { getErrorFields } from '../utils'
import { convertDateString } from '../../../services/common/format'

export const pageName = 'home'

export const fillDatalayer = values => {
  Analytics.addToDatalayer({
    'customerData.yearOfBirth': convertDateString(
      values.dateOfBirth
    ).getFullYear(),
    'customerData.gender': values.salutation === 'MR' ? 'MALE' : 'FEMALE',
  })
}

export const trackPage = (editPage: boolean) => {
  setTimeout(() => {
    let trackData = {
      [Analytics.keys.pageName()]: pageName,
      [Analytics.keys.viewChange()]: 'Personal data',
      [Analytics.keys.formType()]: 'Personal data',
      [Analytics.keys.formName()]: 'Personal data',
      [Analytics.keys.eventType(0)]: 'pageView',
      [Analytics.keys.eventAction(0)]: 'Success',
    }
    if (!editPage) {
      const firstTimeData = {
        [Analytics.keys.eventType(1)]: 'Product journey',
        [Analytics.keys.eventAction(1)]: 'Start',
        [Analytics.keys.eventType(2)]: 'Product configuration',
        [Analytics.keys.eventAction(2)]: 'Completed',
        [Analytics.keys.eventType(3)]: 'Product price',
        [Analytics.keys.eventAction(3)]: 'Calculated',
      }
      trackData = merge(trackData, firstTimeData)
    }
    Analytics.setTrackingData(trackData)
    Analytics.trackPage()
  }, 1000)
}

export const trackDirty = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: 'Personal data',
    [Analytics.keys.formType()]: 'Personal data',
    [Analytics.keys.formName()]: 'Personal data',
    [Analytics.keys.eventType(0)]: 'Form',
    [Analytics.keys.eventAction(0)]: 'Start typing',
    [Analytics.keys.linkInformation(0)]: 'Start typing',
  })
  Analytics.trackInteraction()
}

export const trackSuccess = () => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: 'Personal data',
    [Analytics.keys.linkInformation(0)]: 'Personal data completed',
    [Analytics.keys.eventType(0)]: 'ProductInformation:Personal Information',
    [Analytics.keys.eventAction(0)]: 'Provided',
  })
  Analytics.trackInteraction()
}

export const trackErrors = (errors: any, callback: Function) => {
  const errorFields = getErrorFields(errors)
  if (errorFields) {
    callback(false)
    Analytics.setTrackingData({
      [Analytics.keys.pageName()]: pageName,
      [Analytics.keys.viewChange()]: 'Personal data',
      [Analytics.keys.formType()]: 'Personal data',
      [Analytics.keys.formName()]: 'Personal data',
      [Analytics.keys.eventType(0)]: 'Form',
      [Analytics.keys.eventAction(0)]: 'Field error',
      [Analytics.keys.linkInformation(0)]: 'Field error',
      [Analytics.keys.errorFields()]: errorFields,
    })
    Analytics.trackInteraction()
  }
}

export const trackAbort = (lastTouchedField: string) => {
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: 'Personal data',
    [Analytics.keys.formType()]: 'Personal data',
    [Analytics.keys.formName()]: 'Personal data',
    [Analytics.keys.eventType(0)]: 'Form',
    [Analytics.keys.eventAction(0)]: 'Aborted',
    [Analytics.keys.linkInformation(0)]: 'Form abort',
    [Analytics.keys.lastTouchedField()]: lastTouchedField,
  })
  Analytics.trackInteraction()
}
