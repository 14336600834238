import app from './app.json'
import confirmation from './confirmation.json'
import error from './error.json'
import form from './form.json'
import oauth from './oauth.json'
import personalDetails from './personal-details.json'
import contactDetails from './contact-details.json'
import summary from './summary.json'
import additionalDrivers from './additional-drivers-detail.json'

export const i18n = {
  app,
  confirmation,
  error,
  form,
  'personal-details': personalDetails,
  'contact-details': contactDetails,
  oauth,
  summary,
  'additional-drivers': additionalDrivers,
}
