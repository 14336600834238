import React from 'react'

import { Select as SelectBR } from '@vwfs-bronson/bronson-react'

import { SELECT_CHOOSE_VALUE } from '../../services/common/data'

export const Select = ({
  children,
  defaultOption = false,
  ...props
}) => {
  return (
    <SelectBR {...props}>
      {defaultOption && (
        <SelectBR.Item
          key={`${SELECT_CHOOSE_VALUE.value}${SELECT_CHOOSE_VALUE.label}`}
          value={SELECT_CHOOSE_VALUE.value}
        >
          {SELECT_CHOOSE_VALUE.label}
        </SelectBR.Item>
      )}
      {children}
    </SelectBR>
  )
}

Select.Item = SelectBR.Item
