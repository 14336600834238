import { Analytics } from '../../services/analytics'

export const trackFAQ = (currentPage: string) => {
  const pageName = currentPage
  Analytics.setTrackingData({
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.eventType(0)]: 'interAction',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.linkInformation(0)]: 'FAQ',
  })
  Analytics.trackInteraction()
}
